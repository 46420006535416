import React, { useEffect, useState, useRef } from "react";
import SearchIcon from "../../../../assets/img/company/SearchIcon.svg";
import BootstrapTable from "react-bootstrap-table-next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as usersActions from "../../../../_redux/users/usersActions";
import DeleteIcon from "../../../../assets/img/company/Vector.svg";
import SubscriptionDialog from "../company-edit-Dialog/SubscriptionDialog";
import toast from "react-hot-toast";
import { UserEditForm } from "../company-edit-Dialog/users/UsersEditForm";
import { SetupOwnerPosPin } from "../company-edit-Dialog/users/SetupOwnerPosPin";
import { getUserByToken } from "../../../modules/Auth/_redux/authCrud";
import { useLocation, useHistory } from "react-router-dom";
import { expiredTrialModalShowToggler, getSubscription } from "../../../../_redux/subscription/subscriptionService";
import { i18n } from "../../private/languageSelector";
import PageNavigator from "../../../components/pagination/PageNavigator";
import { shouldShowAddPaymentModal, shouldShowPricingPlanModalAfterBackToFreePlan } from "../../../../helpers/constants";
import PricingPlanDialog from "../company-edit-Dialog/PricingPlanDialog";
import CustomSearch from "../../../components/searchBar/CustomSearch";
import { sortCaret } from "../../../../_metronic/_helpers";

const Users = () => {
  const location = useLocation();
  const history = useHistory();
  const { language } = useSelector((state) => state.auth);
  const { paymentCard } = useSelector((state) => state.paymentCard);
  const dispatch = useDispatch();
  const { users } = useSelector(
    (state) => ({
      users: state.users.entities,
    }),
    shallowEqual
  );

  const { subscriptionData } = useSelector((state) => state.subscription);

  const isTrialExpired =
    subscriptionData?.subscription?.currentPlan?.plan === "Trial" &&
    subscriptionData?.subscription?.currentPlan?.status === "Expired" &&
    subscriptionData?.subscription?.userTrial?.isTrialExpired === true;

  const isSubscriptionPaused = subscriptionData?.subscription?.currentPlan?.status === "Paused";

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [edit, setEdit] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [showUserEditModal, setShowUserEditModal] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [openUsersManageDialog, setOpenUsersManageDialog] = useState(false);
  const [userPosPin, setUserPosPin] = useState("");
  const [openUsersAddDialog, setOpenUsersAddDialog] = useState(false);
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
  const [addUserPaymentModal, setAddUserPaymentModal] = useState(false);
  const [showTrialExpiredModal, setShowTrialExpiredModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showContinueSubscriptionModal, setContinueSubscriptionModal] = useState(false);
  const [showPricingPlanModal, setShowPricingPlanModal] = useState(false);
  const [sortBy, setSortBy] = useState("desc");
  const [sortAt, setSortAt] = useState("created_at");
  const searchInputRef = useRef(null);

  useEffect(() => {
    getUserByToken().then((res) => {
      setUserPosPin(res?.data?.posPin);
    });
  }, [openUsersManageDialog, openUsersAddDialog]);

  useEffect(() => {
    if (location.pathname === "/users/add") {
      setShowUserEditModal(true);
    }
    if (!subscriptionData?.subscription?.userTrial?.isTrialStarted) {
      if (location.pathname === "/user/setup-owner-pos-pin") {
        setOpenUsersManageDialog(true);
      }
    }
  }, [location.pathname, subscriptionData]);

  useEffect(() => {
    dispatch(usersActions.fetchUsers(limit, page, sortBy, sortAt));
  }, [dispatch, page, limit, sortBy, sortAt]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchInputRef.current && !searchInputRef.current.contains(event.target) && searchValue === "") {
        setShowSearchInput(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchValue]);

  const transformUsersData = (users) => {
    return users.map((user) => ({
      _id: user._id,
      name: user?.name,
      mobile: user?.mobile,
      email: user?.email,
      role: user?.rolePayload?.name,
      rolePayload: user?.rolePayload,
      posPin: user?.posPin,
      profileImage: user?.profileImage,
      storesAccess: user?.storesAccess,
    }));
  };

  const transformedUsers = users ? transformUsersData(users) : [];

  const columns = [
    {
      dataField: "name",
      text: i18n("global.Name"),
      headerAlign: "start",
      headerStyle: {
        textAlign: "left",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      align: language === "arabic" ? "start" : "left",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
      formatter: (cell, row) => highlightMatch(`${row?.name?.first + " " + row?.name?.last}`, searchValue),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "mobile",
      text: i18n("global.Mobile"),
      align: language === "arabic" ? "start" : "left",
      headerStyle: {
        textAlign: "start",
        paddingLeft: "50px",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
        paddingLeft: "45px",
      },
      formatter: (cell) => highlightMatch(cell, searchValue),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "email",
      text: i18n("global.Email"),
      align: language === "arabic" ? "start" : "left",
      headerStyle: {
        textAlign: "start",
        paddingLeft: "50px",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
        paddingLeft: "50px",
      },
      formatter: (cell) => highlightMatch(cell, searchValue),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "role",
      headerAlign: "end",
      text: i18n("global.Role"),
      headerStyle: {
        textAlign: "right",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      align: "end",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
      sort: true,
      sortCaret: sortCaret,
    },
  ];

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    onSelect: (row, isSelected) => {
      if (row.role === "owner") {
        return false;
      }
      const newSelectedRows = isSelected ? [...selectedRows, row._id] : selectedRows.filter((id) => id !== row._id);

      setSelectedRows(newSelectedRows);
      return true;
    },
    onSelectAll: (isSelected, rows) => {
      const newSelectedRows = isSelected ? rows.filter((row) => row.role !== "owner").map((row) => row._id) : [];

      setSelectedRows(newSelectedRows);
    },
    nonSelectable: transformedUsers.filter((user) => user.role === "owner").map((user) => user._id),
  };

  const handleDeleteUser = () => {
    setShowDeleteUserModal(true);
  };

  console.log(location.pathname === "/users" && location.pathname !== "/users/edit/delete", "condition");

  useEffect(() => {
    const toggleExpiredTrialModal = async () => {
      if (
        users?.length === 1 &&
        subscriptionData?.subscription?.subscriptionInfo.isSubscribed === false &&
        subscriptionData?.subscription?.subscriptionInfo.isUnsubscribed === false &&
        subscriptionData?.subscription?.userTrial?.isTrialStarted === true &&
        subscriptionData?.subscription?.userTrial?.isTrialExpired === true
      ) {
        if (subscriptionData?.subscription?.trialExpireModalShow === false) {
          await dispatch(expiredTrialModalShowToggler()).then((response) => {
            if (response.payload.status === true && location.pathname === "/users" && location.pathname !== "/users/edit/delete") {
              setShowTrialExpiredModal(true);
            }
          });
        }
      }
    };
    toggleExpiredTrialModal();
    // eslint-disable-next-line
  }, [users?.length]);

  const confirmDelete = async () => {
    try {
      toast.loading("Deleting users...");
      if (subscriptionData && subscriptionData?.subscription?.subscriptionInfo?.isSubscribed) {
        await dispatch(usersActions.deleteSubscriptionUsers(selectedRows));
      } else if (subscriptionData?.subscription?.currentPlan?.plan === "Trial" && subscriptionData?.subscription?.currentPlan?.status === "Expired") {
        await dispatch(usersActions.deleteSubscriptionUsers(selectedRows));
      } else {
        await dispatch(usersActions.deleteUser(selectedRows));
      }
      await dispatch(usersActions.fetchUsers(limit, page));
      await dispatch(getSubscription());
      toast.dismiss();
      toast.success(i18n("SubscriptionToast.UsersDeleted"));
      setSelectedRows([]);
      setShowDeleteUserModal(false);
    } catch (error) {
      toast.dismiss();
      console.log("Error", error);
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const filteredUsers = transformedUsers.filter((user) => {
    const searchText = searchValue.toLowerCase();
    return (
      user?.name?.first?.toLowerCase().includes(searchText) ||
      user?.name?.last?.toLowerCase().includes(searchText) ||
      user?.email?.toLowerCase().includes(searchText) ||
      user?.mobile?.toLowerCase().includes(searchText)
    );
  });

  const defaultPosPin = Math.floor(1000 + Math.random() * 9000).toString();

  const handleEditUserClick = (row) => {
    setEdit(true);
    setSelectedUser(row);
    setShowUserEditModal(true);
    history.push("/users/edit/delete");
  };

  const userCreateHandler = async () => {
    await dispatch(getSubscription());
    setAddUserPaymentModal(true);

    if (shouldShowAddPaymentModal(subscriptionData, paymentCard)) {
      localStorage.setItem("subscribedClicked", "true");
      return setShowAddPaymentModal(true);
    }

    if (shouldShowPricingPlanModalAfterBackToFreePlan(subscriptionData) && !paymentCard) {
      return setShowAddPaymentModal(true);
    }

    setSelectedUser(null);
    setEdit(false);
    if (userPosPin) {
      setEdit(false);
      setOpenUsersAddDialog(true);
      setShowUserEditModal(true);
    } else {
      toast.error(i18n("SubscriptionModals.PleaseSetupYourOwnPosPinFirst"));
      setOpenUsersManageDialog(true);
    }
  };

  const highlightMatch = (text, query) => {
    if (!query) return text;
    const parts = text.split(new RegExp(`(${query})`, "gi"));
    return parts.map((part, index) => (part.toLowerCase() === query.toLowerCase() ? <b key={index}>{part}</b> : part));
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };

  const handleSecondaryAction = () => {
    if (!paymentCard) {
      return setShowAddPaymentModal(true);
    }
    if (users && users?.length < 2) {
      setShowAddUserModal(true);
      setContinueSubscriptionModal(false);
      setShowTrialExpiredModal(false);
    } else if (users && users?.length >= 2) {
      setContinueSubscriptionModal(false);
      setShowPricingPlanModal(true);
      localStorage.removeItem("subscribedClicked");
      setShowTrialExpiredModal(false);
    }
  };

  const handleCancel = async () => {
    setShowTrialExpiredModal(false);
  };

  const handleSubscribe = async () => {
    localStorage.setItem("subscribedClicked", "true");
    handleSecondaryAction();
    setShowTrialExpiredModal(false);
  };

  const navigateUser = () => {
    history.push("/users/add");
    setShowAddUserModal(false);
  };

  const handleSorting = (type, sortOrder, sortField, data, page, sizePerPage) => {
    if (type === "sort") {
      setSortBy(sortOrder);
      if (sortField === "role") {
        setSortAt("rolePayload");
      } else {
        setSortAt(sortField);
      }
    }
  };

  return (
    <div
      style={{
        direction: language === "arabic" ? "rtl" : "ltr",
      }}
    >
      <div
        style={{
          // width: "745px",
          backgroundColor: "#FFFFFF",
          borderRadius: "0.42rem",
        }}
        className="table-width"
      >
        <div className="d-flex justify-content-between mb-3 pl-4 pr-4 py-4 align-items-center">
          {/* <div style={{ cursor: "pointer" }} className="d-flex justify-content-between align-items-center">
            <img onClick={() => setShowSearchInput(!showSearchInput)} src={SearchIcon} alt="SearchImage" />
            {showSearchInput && (
              <div ref={searchInputRef} className="position-relative">
                <input
                  style={{
                    width: "400px",
                    border: "none",
                    borderBottom: "2px solid #2D9CDB",
                    borderRadius: "0",
                  }}
                  className="form-control mr-sm-2 ml-3"
                  type="search"
                  placeholder={i18n("Company.SearchByNameEmailMobile")}
                  aria-label="Search"
                  value={searchValue}
                  onChange={handleSearchInputChange}
                />
              </div>
            )}
          </div> */}
          <CustomSearch
            handleSearchInputChange={handleSearchInputChange}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setShowSearchInput={setShowSearchInput}
            showSearchInput={showSearchInput}
          />
          <div className="add-company-user">
            {selectedRows.length > 0 && <img onClick={handleDeleteUser} src={DeleteIcon} alt="DeleteIcon" />}

            {isTrialExpired || isSubscriptionPaused ? (
              <span className="add-company-back-to-subscription" onClick={() => history.push("/subscription")}>
                {i18n("SubscriptionModals.BACKTOSUBSCRIPTION")}
              </span>
            ) : (
              <span className="add-company-user-add-button" onClick={userCreateHandler}>
                {i18n("Company.ADDUSER")}
              </span>
            )}
          </div>
        </div>
        <>
          <BootstrapTable
            wrapperClasses="table-responsive"
            classes="table table-head-custom table-vertical-center overflow-hidden"
            remote
            bordered={false}
            bootstrap4
            keyField="_id"
            columns={columns}
            data={filteredUsers}
            selectRow={selectRow}
            rowClasses="cursor-pointer"
            rowEvents={{
              onClick: (e, row) => handleEditUserClick(row),
            }}
            onTableChange={(type, { page, sizePerPage, sortField, sortOrder, data }) =>
              handleSorting(type, sortOrder, sortField, data, page, sizePerPage)
            }
          />
          <div className="pb-3 d-flex justify-content-end mr-3 ml-3">
            <PageNavigator page={page} limit={limit} handlePageChange={handlePageChange} handleLimitChange={handleLimitChange} data={filteredUsers} />
          </div>
        </>

        {/* delete user modal  */}
        <SubscriptionDialog
          show={showDeleteUserModal}
          onHide={() => setShowDeleteUserModal(false)}
          modalWidth="350px"
          modalHeight="185px"
          title={i18n("SubscriptionModals.UsersRemoval")}
          titleWidth="115px"
          titleHeight="20px"
          titleColor="#333333"
          titleFontWeight="700"
          titleFontSize="16px"
          titleLineHeight="20px"
          message={
            selectedRows?.length === 1
              ? i18n("SubscriptionModals.AreYouSureYouWantToDeleteSelectedUser")
              : i18n("SubscriptionModals.AreYouSureYouWantToDeleteTheseUsers")
          }
          messageWidth="320px"
          messageHeight="44px"
          messageColor="#333333"
          messageFontWeight="400"
          messageFontSize="16px"
          messageLineHeight="21.79px"
          primaryAction={() => setShowDeleteUserModal(false)}
          primaryLabel={i18n("SubscriptionModals.CANCEL")}
          primaryLabelWidth="55px"
          primaryLabelHeight="20px"
          primaryLabelFontWeight="700"
          primaryLabelFontSize="14px"
          primaryLabelLineHeight="20px"
          primaryLabelColor="#828282"
          secondaryAction={confirmDelete}
          secondaryLabel={i18n("SubscriptionModals.CONFIRM")}
          secondaryLabelWidth="67px"
          secondaryLabelHeight="20px"
          secondaryLabelFontWeight="700"
          secondaryLabelFontSize="14px"
          secondaryLabelLineHeight="20px"
          secondaryLabelColor="#EB5757"
        />

        <UserEditForm
          edit={edit}
          userForEdit={selectedUser}
          defaultPosPin={defaultPosPin}
          openUsersAddDialog={showUserEditModal}
          setOpenUsersAddDialog={setShowUserEditModal}
          setShowDeleteUserModal={setShowDeleteUserModal}
        />

        {openUsersManageDialog && (
          <SetupOwnerPosPin
            userPosPin={userPosPin}
            userPosPinModal={openUsersManageDialog}
            setUserPosPinModal={setOpenUsersManageDialog}
            setOpenUsersAddDialog={setShowUserEditModal}
            setOpenUsersManageDialog={setOpenUsersManageDialog}
          />
        )}

        {/* add payment modal */}
        <SubscriptionDialog
          modalWidth={"300px"}
          modalHeight={"150px"}
          modalBackgroundColor={"#FFFFFF"}
          show={showAddPaymentModal}
          onHide={() => setShowAddPaymentModal(false)}
          message={
            shouldShowPricingPlanModalAfterBackToFreePlan(subscriptionData)
              ? i18n("SubscriptionModals.YouNeedToSubscribeBeforeYouCanAddUser")
              : i18n("SubscriptionModals.YouNeedToAddPaymentCardBeforeYouCanSubscribe")
          }
          messageWidth={"260px"}
          messageHeight={"48px"}
          messageFontSize={"16px"}
          messageFontWeight={"500"}
          messageLineHeight={"21.79px"}
          messageColor={"#333333"}
          primaryLabel={i18n("SubscriptionModals.CANCEL")}
          primaryAction={() => setShowAddPaymentModal(false)}
          primaryLabelWidth={"55px"}
          primaryLabelHeight={"30px"}
          primaryLabelFontWeight={"700"}
          primaryLabelFontSize={"14px"}
          primaryLabelLineHeight={"29.57px"}
          primaryLabelColor={"#828282"}
          secondaryAction={() => {
            shouldShowPricingPlanModalAfterBackToFreePlan(subscriptionData) && localStorage.setItem("subscribedClicked", "true");
            history.push("/subscription/add-payment-card", {
              addUserPaymentModal,
            });
            setOpenUsersAddDialog(true);
            setShowAddPaymentModal(false);
          }}
          secondaryLabel={i18n("Company.ADDCARD")}
          secondaryLabelHeight={"30px"}
          secondaryLabelFontWeight={"700"}
          secondaryLabelFontSize={"14px"}
          secondaryLabelLineHeight={"29.57px"}
          secondaryLabelColor={"#2D9CDB"}
        />

        {/* trial expired modal */}
        <SubscriptionDialog
          modalWidth={"350px"}
          title={i18n("SubscriptionModals.TrialExpired")}
          titleWidth={"100px"}
          titleHeight={"20px"}
          titleColor={"#333333"}
          titleFontWeight={700}
          titleFontSize={"14px"}
          titleLineHeight={"20px"}
          modalHeight={"150px"}
          modalBackgroundColor={"#FFFFFF"}
          show={showTrialExpiredModal}
          onHide={handleCancel}
          message={i18n("SubscriptionModals.YourUserManagementTrialHasExpiredSubscribeNowToContinueUsingIt")}
          messageHeight={"44px"}
          messageFontSize={"16px"}
          messageFontWeight={"500"}
          messageLineHeight={"21.79px"}
          messageColor={"#333333"}
          primaryLabel={i18n("SubscriptionModals.CANCEL")}
          primaryAction={handleCancel}
          primaryLabelWidth={"55px"}
          primaryLabelHeight={"30px"}
          primaryLabelFontWeight={"700"}
          primaryLabelFontSize={"14px"}
          primaryLabelLineHeight={"29.57px"}
          primaryLabelColor={"#828282"}
          secondaryAction={handleSubscribe}
          secondaryLabel={i18n("global.SUBSCRIBE")}
          secondaryLabelWidth={"70px"}
          secondaryLabelHeight={"30px"}
          secondaryLabelFontWeight={"700"}
          secondaryLabelFontSize={"14px"}
          secondaryLabelLineHeight={"29.57px"}
          secondaryLabelColor={"#2D9CDB"}
          isTrialExpired={true}
        />

        <PricingPlanDialog showModal={showPricingPlanModal} onHide={() => setShowPricingPlanModal(false)} />

        {/* add user modal */}
        <SubscriptionDialog
          modalWidth={"300px"}
          modalHeight={"150px"}
          modalBackgroundColor={"#FFFFFF"}
          show={showAddUserModal}
          onHide={() => setShowAddUserModal(false)}
          message={
            subscriptionData?.subscription?.userTrial.isTrialStarted && subscriptionData?.subscription?.userTrial.isTrialExpired
              ? i18n("SubscriptionModals.YouNeedToCreateNewUserBeforeStartingSubscription")
              : i18n("SubscriptionModals.YouNeedToCreateNewUserBeforeStartingYourFreeTrial")
          }
          messageWidth={"262px"}
          messageHeight={"44px"}
          messageFontSize={"16px"}
          messageFontWeight={"500"}
          messageLineHeight={"21.79px"}
          messageColor={"#333333"}
          primaryLabel={i18n("SubscriptionModals.CANCEL")}
          primaryAction={() => setShowAddUserModal(false)}
          primaryLabelWidth={"55px"}
          primaryLabelHeight={"30px"}
          primaryLabelFontWeight={"700"}
          primaryLabelFontSize={"14px"}
          primaryLabelLineHeight={"29.57px"}
          primaryLabelColor={"#828282"}
          secondaryAction={navigateUser}
          secondaryLabel={i18n("SubscriptionModals.ADDUSER")}
          secondaryLabelWidth={"70px"}
          secondaryLabelHeight={"30px"}
          secondaryLabelFontWeight={"700"}
          secondaryLabelFontSize={"14px"}
          secondaryLabelLineHeight={"29.57px"}
          secondaryLabelColor={"#2D9CDB"}
        />

        {/* continue subscription modal */}
        <SubscriptionDialog
          show={showContinueSubscriptionModal}
          onHide={() => setContinueSubscriptionModal(false)}
          modalWidth="300px"
          modalHeight="225px"
          title={i18n("SubscriptionModals.ContinueWithSubscription")}
          titleWidth="244px"
          titleHeight="29px"
          titleColor="#333333"
          titleFontWeight="700"
          titleFontSize="16px"
          titleLineHeight="20px"
          message={i18n("SubscriptionModals.UserWasCreatedSuccessfullyYouCanNowContinueWithYourSubscription")}
          messageWidth="260px"
          messageHeight="66px"
          messageColor="#333333"
          messageFontWeight="500"
          messageFontSize="16px"
          messageLineHeight="22px"
          primaryAction={() => setContinueSubscriptionModal(false)}
          primaryLabel={i18n("SubscriptionModals.CANCEL")}
          primaryLabelWidth="63px"
          primaryLabelHeight="16px"
          primaryLabelFontWeight="600"
          primaryLabelFontSize="16px"
          primaryLabelLineHeight="15.92px"
          primaryLabelColor="#828282"
          secondaryAction={() => history.push("/subscription/new")}
          secondaryLabel={i18n("SubscriptionModals.CONTINUE")}
          secondaryLabelWidth="86px"
          secondaryLabelHeight="16px"
          secondaryLabelFontWeight="600"
          secondaryLabelFontSize="16px"
          secondaryLabelLineHeight="20px"
          secondaryLabelColor="#2D9CDB"
        />
      </div>
    </div>
  );
};

export default Users;
