import React, { useState, useEffect } from "react";
import "./analytics.css";
import SideCard from "../../../components/Card/SideCard";
import Table from "../../../components/Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { getTableData } from "../../../../_redux/analytics/analyticsService";
import moment from "moment";
import PageNavigator from "../../../components/pagination/PageNavigator";
import { formatValue } from "../../../../helpers/helpers";
import { exportTableToPDF } from "../../../../utils/pdfExportUtil";
import { i18n } from "../../private/languageSelector";
import FiltersDialog from "../filters-dialog/FiltersDialog";

const SalesByPaymentChannel = () => {
  const dispatch = useDispatch();
  const [allStores, setAllStores] = useState("all");
  const [allUsers, setAllUsers] = useState("all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tableType, setTableType] = useState("salesByPaymentChannel");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [timeZone, setTimeZone] = useState("Asia/Riyadh");

  const { analyticsTableData, openFiltersDialog } = useSelector((state) => state.analytics);

  useEffect(() => {
    setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const formattedStartDate = startDate ? moment(new Date(startDate)).format("M-DD-yy") : "";
      const formattedEndDate = endDate ? moment(new Date(endDate)).format("M-DD-yy") : "";
      await dispatch(
        getTableData({
          tableType,
          page,
          limit,
          allStores,
          allUsers,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          sortBy,
          sortOrder,
          timeZone,
        })
      );
    };
    if (startDate && endDate) {
      fetchData();
    }
  }, [page, limit, allStores, allUsers, startDate, endDate, tableType, sortBy, sortOrder]);

  const handleSort = (column) => {
    const newSortOrder = sortBy === column && sortOrder === "asc" ? "desc" : "asc";
    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };

  const handleSelectStores = (storeIds) => {
    setAllStores(storeIds);
  };

  const handleSelectUsers = (userIds) => {
    setAllUsers(userIds);
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  // Calculate grand totals
  const calculateGrandTotals = (data) => {
    return data.reduce(
      (totals, row) => {
        totals.paymentTransactions += row.paymentTransactions || 0;
        totals.paymentAmount += row.paymentAmount || 0;
        totals.refundTransactions += row.refundTransactions || 0;
        totals.refundedAmount += row.refundedAmount || 0;
        totals.netAmount += row.netAmount || 0;
        return totals;
      },
      {
        paymentTransactions: 0,
        paymentAmount: 0,
        refundTransactions: 0,
        refundedAmount: 0,
        netAmount: 0,
      }
    );
  };

  const grandTotals = calculateGrandTotals(analyticsTableData);
  const grandTotal = {
    ...grandTotals,
    paymentAmount: formatValue(grandTotals.paymentAmount),
    refundedAmount: formatValue(grandTotals.refundedAmount),
    netAmount: formatValue(grandTotals.netAmount),
  };

  const formattedData = [
    ...analyticsTableData.map((row) => ({
      ...row,
      paymentAmount: formatValue(row.paymentAmount),
      refundedAmount: formatValue(row.refundedAmount),
      netAmount: formatValue(row.netAmount),
    })),
  ];
  const columns = ["paymentChannel", "paymentTransactions", "paymentAmount", "refundTransactions", "refundedAmount", "netAmount"];
  const { language } = useSelector((state) => state.auth);

  return (
    <div className={`d-flex payment-channel-card ${language === "arabic" ? "rtl" : "ltr"}`}>
      <SideCard
        handleSelectStores={handleSelectStores}
        handleSelectUsers={handleSelectUsers}
        allUser={allUsers}
        allStores={allStores}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        page={page}
        limit={limit}
        tableType={tableType}
      />
      <div className={`main-content w-100 ${language === "arabic" ? "pr-3" : "pl-3"}`}>
        <div className="card mb-3">
          <h5 role="button" className="card-title text-primary" onClick={() => exportTableToPDF("tableToExport", "sales_by_payment.pdf")}>
            {i18n("LandingPage.EXPORT")}
          </h5>
          <div className="card-body">
            <div id="tableToExport">
              <Table
                columns={columns}
                data={formattedData}
                onSort={handleSort}
                sortBy={sortBy}
                sortOrder={sortOrder}
                grandTotal={grandTotal}
                showGrandTotal={true}
              />
            </div>
            <nav className="mt-5">
              <PageNavigator
                page={page}
                limit={limit}
                handlePageChange={handlePageChange}
                handleLimitChange={handleLimitChange}
                data={formattedData}
              />
            </nav>

            {openFiltersDialog && (
              <FiltersDialog
                handleSelectStores={handleSelectStores}
                handleSelectUsers={handleSelectUsers}
                allUsers={allUsers}
                allStores={allStores}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                handleStartDateChange={handleStartDateChange}
                handleEndDateChange={handleEndDateChange}
                page={page}
                limit={limit}
                tableType={tableType}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesByPaymentChannel;
