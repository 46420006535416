export const InvoiceStatusCssClasses = ["danger", "success", "info", ""];
export const InvoiceStatusTitles = ["Suspended", "Active", "Pending", ""];
export const InvoiceTypeCssClasses = ["success", "primary", ""];
export const InvoiceTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "_id", order: "desc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "25", value: 25 },
  { text: "50", value: 50 },
];
export const initialFilter = {
  filter: { customerName: "", invoice: "", email: "", mobile: "" },
  sortOrder: "desc", // asc||desc
  sortField: "_id",
  pageNumber: 1,
  pageSize: 10,
};
