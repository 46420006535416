import React, { useEffect, useRef, useState, useMemo } from "react";
import DeleteIcon from "../../../../assets/img/company/Vector.svg";
import { useDispatch } from "react-redux";
import SubscriptionDialog from "../../company/company-edit-Dialog/SubscriptionDialog";
import { i18n } from "../../private/languageSelector";
import toast from "react-hot-toast";
import * as actions from "../../../../_redux/customers/customersActions";
import { useCustomersUIContext } from "../CustomersUIContext";
import CustomSearch from "../../../components/searchBar/CustomSearch";

function CustomerHeader({ searchValue, setSearchValue }) {
  const [showDeleteCustomerModal, setShowDeleteCustomerModal] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const dispatch = useDispatch();
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
      openEditCustomerDialog: customersUIContext.openEditCustomerDialog,
      openDeleteCustomerDialog: customersUIContext.openDeleteCustomerDialog,
      openCustomerAddDialog: customersUIContext.newCustomerButtonClick,
    };
  }, [customersUIContext]);

  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const confirmDelete = () => {
    if (customersUIProps?.ids?.length) {
      dispatch(actions.deleteCustomers(customersUIProps?.ids)).then(() => {
        toast.success("Customers Deleted Successfully");
        customersUIProps.setIds([]);
      });
    } else {
      toast.error("No Customer Selected");
    }
    setShowDeleteCustomerModal(false);
  };

  return (
    <div className="pl-4 pr-4 py-4 mb-3 d-flex justify-content-between align-items-center">
      <CustomSearch
        handleSearchInputChange={handleSearchInputChange}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        showSearchInput={showSearchInput}
        setShowSearchInput={setShowSearchInput}
      />
      <div>
        {customersUIProps.ids?.length > 0 && (
          <span className={` cursor-pointer mr-5`} onClick={() => setShowDeleteCustomerModal(true)}>
            <img src={DeleteIcon} alt="Delete" />
          </span>
        )}
        <span className="add-text-btn" onClick={customersUIProps.openCustomerAddDialog}>
          ADD CUSTOMER
        </span>
      </div>
      <SubscriptionDialog
        show={showDeleteCustomerModal}
        onHide={() => setShowDeleteCustomerModal(false)}
        modalWidth="350px"
        modalHeight="185px"
        message={
          customersUIProps?.ids?.length === 1
            ? "Are you sure you want to delete the selected Customer?"
            : "Are you sure you want to delete the selected Customers?"
        }
        messageWidth="320px"
        messageHeight="44px"
        messageColor="#333333"
        messageFontWeight="400"
        messageFontSize="16px"
        messageLineHeight="21.79px"
        primaryAction={() => setShowDeleteCustomerModal(false)}
        primaryLabel={i18n("SubscriptionModals.CANCEL")}
        primaryLabelWidth="55px"
        primaryLabelHeight="20px"
        primaryLabelFontWeight="700"
        primaryLabelFontSize="14px"
        primaryLabelLineHeight="20px"
        primaryLabelColor="#828282"
        secondaryAction={confirmDelete}
        secondaryLabel={"DELETE"}
        secondaryLabelWidth="67px"
        secondaryLabelHeight="20px"
        secondaryLabelFontWeight="700"
        secondaryLabelFontSize="14px"
        secondaryLabelLineHeight="20px"
        secondaryLabelColor="#EB5757"
        isCenteredOnMobile
      />
    </div>
  );
}

export default CustomerHeader;
