import React from "react";
import { Card } from "../../../../../_metronic/_partials/controls";
import IconQr from "../../../../../assets/img/icon-qr.svg";
import { refactorValues } from "../../../../../helpers/helpers";

export default function SideInvoicePreview({ store, values, image }) {
  return (
    <div className="invoice-side-wrap">
      <Card style={{ padding: "1.25rem 0rem" }}>
        <div className="invoice-items row">
          <div className="col-12">
            <div className="pos-container">
              <div className="pos-invoice" style={{ width: "100%", marginTop: "0rem" }}>
                {image && image[0]?.image_url ? (
                  <>
                    <img
                      style={{ maxWidth: "328px", height: "100px", width: "100px", objectFit: "contain" }}
                      src={image[0]?.image_url}
                      alt="loading"
                    />
                  </>
                ) : (
                  ""
                )}
                <div className="bold-color">
                  {" "}
                  <h4 className="mt-3">
                    <b style={{ fontWeight: 600, fontSize: "18px" }}>{values?.name ? values?.name : ""}</b>
                  </h4>
                  <div className="mt-0 mb-0 bold-color">
                    {values?.taxNumber?.length > 0 ? `${refactorValues(values).taxNo} ${values?.taxNumber}` : ""}
                  </div>
                  <div className="mt-0 mb-0 bold-color">{values?.address ? values?.address : ""}</div>
                  <div className="mt-0 mb-0 bold-color">
                    {values?.storeDetails?.split("\n")?.length > 1 ? (
                      <>
                        <div>{values?.storeDetails?.split("\n")[0]}</div>
                        <div>{values?.storeDetails?.split("\n")[1]}</div>
                      </>
                    ) : (
                      <>{values?.storeDetails?.split("\n")[0]}</>
                    )}
                  </div>
                  {values?.taxNumber ? (
                    <>
                      <div className="mt-3  bold-color" style={{ fontSize: "1rem", fontWeight: 400 }}>
                        <div className=" mw-100 "></div>
                        {refactorValues(values).simplifiedInvoice}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <h1 className="mt-3">{refactorValues(values).total}</h1>
                  <h5 style={{ fontWeight: 400, fontSize: "1rem" }}>{refactorValues(values).totalText}</h5>
                  <div className="doted-line mb-2 mt-2"></div>
                  <div className="row">
                    <div className={`col-12 ${values?.invoiceLanguage?.value === "en" ? "text-left" : "text-right"}`}>
                      <div className="bold-color">{refactorValues(values).invoiceNo}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className={`col-12 ${values?.invoiceLanguage?.value === "en" ? "text-left" : "text-right"}`}>
                      <div className="bold-color">{refactorValues(values).date}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className={`col-12 ${values?.invoiceLanguage?.value === "en" ? "text-left" : "text-right"}`}>
                      <div className="bold-color">{refactorValues(values).cashier} </div>
                    </div>
                    <div className="col-5 text-right">
                    </div>
                  </div>
                  <div className="row">
                    <div className={`col-12 ${values?.invoiceLanguage?.value === "en" ? "text-left" : "text-right"}`}>
                      <div className="bold-color">{refactorValues(values).pos} </div>
                      <div className="col-5 text-right"></div>
                    </div>
                  </div>
                  <div className="doted-line mb-2 mt-2"></div>
                  <div className="doted-line mb-2" style={{ marginTop: values?.taxNumber?.length <= 0 && !image ? "10rem" : "5rem" }}></div>
                  <div className="row">
                    <div className={`col-12 ${values?.invoiceLanguage?.value === "en" ? "text-left" : "text-right"}`}>
                      <div className="bold-color" style={{ fontWeight: 600 }}>
                        {refactorValues(values).totalTextSec}
                      </div>
                      <div className="col-5 text-right"></div>
                    </div>
                  </div>
                  <div className="row">
                    <div className={`col-12 ${values?.invoiceLanguage?.value === "en" ? "text-left" : "text-right"}`}>
                      <div className="bold-color">{refactorValues(values).cash}</div>
                      <div className="col-5 text-right"></div>
                    </div>
                  </div>
                  <div className="row">
                    <div className={`col-12 ${values?.invoiceLanguage?.value === "en" ? "text-left" : "text-right"}`}>
                      <div className="bold-color">{refactorValues(values).change}</div>
                      <div className="col-5 text-right"></div>
                    </div>
                  </div>
                  <div className="doted-line mb-2" style={{ marginTop: values?.taxNumber?.length <= 0 && !image ? "6rem" : "1rem" }}></div>
                  {values?.taxNumber ? (
                    <>
                      <div className="pos-qrcode">
                        <img src={IconQr} alt="loading" style={{ width: "100px", height: "100px" }} />
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {values?.description && (
                    <>
                      {" "}
                      <div className="my-4  bold-color" style={{ fontSize: "1rem", marginBottom: "1.5rem" }}>
                        {values?.description?.split("\n")?.length > 1 ? (
                          <>
                            <div>{values?.description?.split("\n")[0]}</div>
                            <div>{values?.description?.split("\n")[1]}</div>
                          </>
                        ) : (
                          <div>{values?.description?.split("\n")[0]}</div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
