/* Pagination Helprs */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "./AssetsHelpers";
import sortIcon from "../../assets/img/sort-icon.svg";

import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
export const sortCaret = (order, column) => {
  if (!order) {
    // console.log(order, "order");
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1 svg-icon-sort">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Sort1.svg")} />
      </span>
    );
  } else if (order === "asc")
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
        {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Up-2.svg")} /> */}
        <img src={sortIcon} alt="sortIcon" />
      </span>
    );
  else if (order === "desc")
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
        {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Down-2.svg")} /> */}
        <img src={sortIcon} alt="sortIcon" className="sort-icon-rotate" />
      </span>
    );
  return null;
};

export const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) =>
  sortOrder === "asc" || sortOrder === "desc" ? "sortable-active" : "";

export const sortCustomHandler = (order, column) => {
  if (order === "asc")
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
        <ArrowUpward style={{ marginBottom: "3px" }} />
      </span>
    );
  else if (order === "desc")
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
        <ArrowDownward style={{ marginBottom: "3px" }} />
      </span>
    );
};
