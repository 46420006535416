import React from "react";
import { Modal } from "react-bootstrap";
import { i18n } from "../../../private/languageSelector";
import { useSelector } from "react-redux";
import IconClose from "../../../../../assets/img/close-invoice.svg";
import IconQr from "../../../../../assets/img/icon-qr.svg";
import { refactorValues } from "../../../../../helpers/helpers";

export default function InvoiceDetailsPreview({ onHide, openInvoiceDialog, values, image }) {
  const { language } = useSelector((state) => state.auth);

  return (
    <div>
      <Modal
        className={`${language === "arabic" && "ar"} invoice-preview-modal  modal-drawer my-profile invoice-drawer`}
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={openInvoiceDialog}
        onHide={() => onHide()}
      >
        <Modal.Header>
          <div className="d-flex  justify-content-around align-items-center">
            <span style={{ marginRight: "auto" }} onClick={() => onHide()} className="cursor-pointer">
              <img src={IconClose} alt="loading" />
            </span>
            <span style={{ marginRight: "auto" }}>
              <span className="invoice-preview-text">{i18n("SubscriptionModals.InvoicePreview")}</span>
            </span>
          </div>
        </Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default" style={{ background: "#F2F2F2", padding: "1rem" }}>
          {/* <Card style={{ padding: "1.25rem 0rem" }}> */}
          <div className="invoice-items row invoice-bordered-wrap ">
            <div className="col-12">
              <div className="pos-container">
                <div className="pos-invoice" style={{ width: "100%", marginTop: "0rem" }}>
                  {image && image[0]?.image_url ? (
                    <>
                      <img
                        style={{ maxWidth: "328px", height: "100px", width: "100px", objectFit: "contain", marginTop: "1rem" }}
                        src={image[0]?.image_url}
                        alt="loading"
                      />
                    </>
                  ) : (
                    ""
                  )}
                  <div className="bold-color">
                    {" "}
                    <h4 className="mt-4">
                      <b style={{ fontWeight: 600, fontSize: "18px" }}>{values?.name ? values?.name : ""}</b>
                    </h4>
                    <div className="mt-0 mb-0 bold-color">
                      {values?.taxNumber?.length > 0 ? `${refactorValues(values).taxNo} ${values?.taxNumber}` : ""}
                    </div>
                    <div className="mt-0 mb-0 bold-color">{values?.address ? values?.address : ""}</div>
                    <div className="mt-0 mb-0 bold-color">
                      {values?.storeDetails?.split("\n")?.length > 1 ? (
                        <>
                          <div>{values?.storeDetails?.split("\n")[0]}</div>
                          <div>{values?.storeDetails?.split("\n")[1]}</div>
                        </>
                      ) : (
                        <>{values?.storeDetails?.split("\n")[0]}</>
                      )}
                    </div>
                    {values?.taxNumber ? (
                      <>
                        <div className="mt-3  bold-color" style={{ fontSize: "1rem", fontWeight: 400 }}>
                          <div className=" mw-100 "></div>
                          {refactorValues(values).simplifiedInvoice}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <h1 className="mt-3">{refactorValues(values).total}</h1>
                    <h5 style={{ fontWeight: 400, fontSize: "1rem" }}>{refactorValues(values).totalText}</h5>
                    <div className="doted-line mb-2 mt-2"></div>
                    <div className="row">
                      <div className={`col-12 ${values?.invoiceLanguage?.value === "en" ? "text-left" : "text-right"}`}>
                        <div className="bold-color">{refactorValues(values).invoiceNo}</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className={`col-12 ${values?.invoiceLanguage?.value === "en" ? "text-left" : "text-right"}`}>
                        <div className="bold-color">{refactorValues(values).date}</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className={`col-12 ${values?.invoiceLanguage?.value === "en" ? "text-left" : "text-right"}`}>
                        <div className="bold-color">{refactorValues(values).cashier} </div>
                      </div>
                      <div className="col-5 text-right">
                        {/* <div className="light-color">{invoice?.invoiceData?.invoiceNumber ? invoice?.invoiceData?.invoiceNumber : ""}</div> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className={`col-12 ${values?.invoiceLanguage?.value === "en" ? "text-left" : "text-right"}`}>
                        <div className="bold-color">{refactorValues(values).pos}</div>
                        <div className="col-5 text-right"></div>
                      </div>
                    </div>
                    <div className="doted-line mb-2 mt-2"></div>
                    <div className="doted-line mb-5" style={{ marginTop: "10rem" }}></div>
                    <div className="row">
                      <div className={`col-12 ${values?.invoiceLanguage?.value === "en" ? "text-left" : "text-right"}`}>
                        <div className="bold-color" style={{ fontWeight: 600 }}>
                          {refactorValues(values).totalTextSec}
                        </div>
                        <div className="col-5 text-right"></div>
                      </div>
                    </div>
                    <div className="row">
                      <div className={`col-12 ${values?.invoiceLanguage?.value === "en" ? "text-left" : "text-right"}`}>
                        <div className="bold-color">{refactorValues(values).cash}</div>
                        <div className="col-5 text-right"></div>
                      </div>
                    </div>
                    <div className="row">
                      <div className={`col-12 ${values?.invoiceLanguage?.value === "en" ? "text-left" : "text-right"}`}>
                        <div className="bold-color">{refactorValues(values).change}</div>
                        <div className="col-5 text-right"></div>
                      </div>
                    </div>
                    {/* <div className="doted-line mb-2 mt-2"></div> */}
                    <div className="doted-line mb-5" style={{ marginTop: "4.5rem" }}></div>
                    {values?.taxNumber ? (
                      <>
                        <div className="pos-qrcode mb-4">
                          <img src={IconQr} alt="loading" style={{ width: "100px", height: "100px" }} />
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {values?.description && (
                      <>
                        {" "}
                        <div className="my-4  bold-color" style={{ fontSize: "1rem", marginBottom: "1.5rem" }}>
                          {values?.description?.split("\n")?.length > 1 ? (
                            <>
                              <div>{values?.description?.split("\n")[0]}</div>
                              <div>{values?.description?.split("\n")[1]}</div>
                            </>
                          ) : (
                            <div>{values?.description?.split("\n")[0]}</div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </Card> */}
        </Modal.Body>
      </Modal>
    </div>
  );
}
