import React, { useEffect, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import PageNavigator from "../../../components/pagination/PageNavigator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import NoTaxIcon from "../../../../assets/img/company/NoTaxIcon.svg";
import { TaxesEditForm } from "../company-edit-Dialog/taxes/TaxesEditForm";
import { deleteTax, fetchTaxes } from "../../../../_redux/taxes/taxesActions";
import DeleteIcon from "../../../../assets/img/company/Vector.svg";
import { i18n } from "../../private/languageSelector";
import SubscriptionDialog from "../company-edit-Dialog/SubscriptionDialog";
import CustomSearch from "../../../components/searchBar/CustomSearch";
import { sortCaret } from "../../../../_metronic/_helpers";

const Taxes = () => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.auth);
  const { taxes } = useSelector(
    (state) => ({
      taxes: state.taxes?.entities,
    }),
    shallowEqual
  );

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openTaxesAddDialog, setOpenTaxesAddDialog] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedTax, setSelectedTax] = useState(null);
  const [showDeleteTaxModal, setShowDeleteTaxModal] = useState(false);
  const [sortBy, setSortBy] = useState("desc");
  const [sortAt, setSortAt] = useState("created_at");

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };

  const handleEditTaxClick = (row) => {
    setEdit(true);
    setSelectedTax(row);
    setOpenTaxesAddDialog(true);
  };

  let filteredTaxes = taxes?.filter((tax) => {
    let searchText = searchValue?.toLowerCase();
    return tax?.name?.toLowerCase().includes(searchText);
  });

  const highlightMatch = (text, query) => {
    if (!query) return text;
    const parts = text.split(new RegExp(`(${query})`, "gi"));
    return parts.map((part, index) => (part.toLowerCase() === query.toLowerCase() ? <b key={index}>{part}</b> : part));
  };

  useEffect(() => {
    dispatch(fetchTaxes(limit, page, sortBy, sortAt));
  }, [dispatch, limit, page, sortBy, sortAt]);

  const columns = [
    {
      dataField: "name",
      text: "name",
      headerAlign: "start",
      headerStyle: {
        textAlign: "left",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      align: language === "arabic" ? "start" : "left",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
      formatter: (cell) => highlightMatch(cell, searchValue),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "type",
      text: "Scheme",
      align: language === "arabic" ? "start" : "left",
      headerStyle: {
        textAlign: "start",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
      formatter: (cell) => highlightMatch(cell, searchValue),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "taxRate",
      text: "Rate",
      align: language === "arabic" ? "left" : "right",
      headerStyle: {
        textAlign: "right",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
      formatter: (cell) => {
        const formattedCell = `${cell}%`;
        return highlightMatch(formattedCell, searchValue);
      },
      sort: true,
      sortCaret: sortCaret,
    },
  ];

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    onSelect: (row, isSelected) => {
      const newSelectedRows = isSelected ? [...selectedRows, row._id] : selectedRows.filter((id) => id !== row._id);
      setSelectedRows(newSelectedRows);
      return true;
    },
    onSelectAll: (isSelected, rows) => {
      const newSelectedRows = isSelected ? rows.map((row) => row._id) : [];
      setSelectedRows(newSelectedRows);
    },
  };

  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  if (!taxes) {
    return null;
  }

  const confirmDelete = () => {
    if (selectedRows.length > 0 && !openTaxesAddDialog) {
      for (let row of selectedRows) {
        dispatch(deleteTax(row));
      }
      setSelectedRows([]);
      setShowDeleteTaxModal(false);
    } else if (selectedTax?._id) {
      dispatch(deleteTax(selectedTax._id));
      setSelectedRows([]);
      setShowDeleteTaxModal(false);
      setOpenTaxesAddDialog(false);
    }
  };

  const handleSorting = (type, sortOrder, sortField, data, page, sizePerPage) => {
    if (type === "sort") {
      if (sortOrder === "asc") {
        setSortBy("asc");
      } else {
        setSortBy("desc");
      }
      setSortAt(sortField);
    }
  };
  return (
    <>
      <div className="table-width" style={{ backgroundColor: "#FFFFFF", borderRadius: "0.42rem" }}>
        <div className="pl-4 pr-4 py-4 mb-3 d-flex justify-content-between align-items-center">
          <CustomSearch
            handleSearchInputChange={handleSearchInputChange}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setShowSearchInput={setShowSearchInput}
            showSearchInput={showSearchInput}
          />
          <div>
            {selectedRows.length > 0 && (
              <span className="mr-5 cursor-pointer" onClick={() => setShowDeleteTaxModal(true)}>
                <img src={DeleteIcon} alt="Delete" />
              </span>
            )}
            <span
              className="add-text-btn"
              onClick={() => {
                setEdit(false);
                setSelectedTax(null);
                setOpenTaxesAddDialog(true);
              }}
            >
              ADD TAX
            </span>
          </div>
        </div>
        <BootstrapTable
          wrapperClasses="table-responsive"
          classes="table table-head-custom table-vertical-center overflow-hidden"
          remote
          bordered={false}
          bootstrap4
          keyField="_id"
          columns={columns}
          data={filteredTaxes}
          selectRow={selectRow}
          rowClasses="cursor-pointer"
          onTableChange={(type, { page, sizePerPage, sortField, sortOrder, data }) =>
            handleSorting(type, sortOrder, sortField, data, page, sizePerPage)
          }
          rowEvents={{
            onClick: (e, row, rowIndex) => {
              if (e.target.type !== "checkbox") {
                handleEditTaxClick(row);
              }
            },
          }}
        />

        {taxes && taxes?.length < 1 && (
          <div className="d-flex flex-column justify-content-center align-items-center mt-5 mb-5">
            <img src={NoTaxIcon} alt="NoTaxIcon" />
            <p className="py-3 you-have-no-taxes">You have no taxes yet</p>
          </div>
        )}

        <div className="pb-3 d-flex justify-content-end mr-3 ml-3">
          <PageNavigator page={page} limit={limit} handlePageChange={handlePageChange} handleLimitChange={handleLimitChange} data={filteredTaxes} />
        </div>
      </div>
      <TaxesEditForm
        openTaxesAddDialog={openTaxesAddDialog}
        setOpenTaxesAddDialog={setOpenTaxesAddDialog}
        edit={edit}
        tax={selectedTax}
        setShowDeleteTaxModal={setShowDeleteTaxModal}
      />

      {/* delete tax modal  */}
      <SubscriptionDialog
        show={showDeleteTaxModal}
        onHide={() => setShowDeleteTaxModal(false)}
        modalWidth="350px"
        modalHeight="185px"
        message={
          selectedRows.length === 1 || edit ? "Are you sure you want to delete the selected Tax?" : "Are you sure you want to delete these Taxes?"
        }
        messageWidth="320px"
        messageHeight="44px"
        messageColor="#333333"
        messageFontWeight="400"
        messageFontSize="16px"
        messageLineHeight="21.79px"
        primaryAction={() => setShowDeleteTaxModal(false)}
        primaryLabel={i18n("SubscriptionModals.CANCEL")}
        primaryLabelWidth="55px"
        primaryLabelHeight="20px"
        primaryLabelFontWeight="700"
        primaryLabelFontSize="14px"
        primaryLabelLineHeight="20px"
        primaryLabelColor="#828282"
        secondaryAction={confirmDelete}
        secondaryLabel={"DELETE"}
        secondaryLabelWidth="67px"
        secondaryLabelHeight="20px"
        secondaryLabelFontWeight="700"
        secondaryLabelFontSize="14px"
        secondaryLabelLineHeight="20px"
        secondaryLabelColor="#EB5757"
        isCenteredOnMobile
      />
    </>
  );
};

export default Taxes;
