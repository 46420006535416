import React, { useMemo } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { renderCellValue, transformColumnName } from "../../../helpers/helpers";
import TableIcon from "../../../assets/img/no-data-icon.svg";
import { i18n } from "../../pages/private/languageSelector";
import { useSelector } from "react-redux";
const Table = ({ columns, data, onSort, sortBy, sortOrder, grandTotal, showGrandTotal }) => {
  const { language } = useSelector((state) => state.auth);

  return (
    <div className={`table-responsive ${language === "arabic" ? "rtl" : "ltr"}`}>
      <table className="table">
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th
                key={index}
                onClick={() => onSort(col)}
                style={{ cursor: "pointer", paddingRight: window.location?.pathname?.includes("sales-by-time") ? "11rem" : "" }}
              >
                {transformColumnName(col)}
                {sortBy === col && (sortOrder === "asc" ? <ArrowUpward /> : <ArrowDownward />)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={`${data.length > 0 ? "" : "table-margin"}`}>
          {data.length > 0 ? (
            <>
              {data.map((row, rowIndex) => (
                <tr className="tr-content" key={rowIndex}>
                  {columns.map((col, colIndex) => (
                    <td className="td-col" key={colIndex}>
                      {renderCellValue(row[col])}
                    </td>
                  ))}
                </tr>
              ))}

              {showGrandTotal && (
                <tr className="tr-content">
                  <td className="td-col">
                    <strong>{i18n("DASHBOARD.Total")}</strong>
                  </td>
                  {columns.slice(1).map((col, colIndex) => (
                    <td className="td-col font-weight-bold" key={colIndex}>
                      <strong>{grandTotal[col] ? renderCellValue(grandTotal[col]) : ""}</strong>
                    </td>
                  ))}
                </tr>
              )}
            </>
          ) : (
            <tr>
              <td colSpan={columns.length}>
                <div className="no-data-container">
                  <span className="table-icons">
                    <img className="elipse-icon" src={TableIcon} alt="" />
                  </span>
                  <span>{i18n("LandingPage.NoSalesInSelectedTimePeriod")}</span>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
