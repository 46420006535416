import axios from "axios";
import moment from "moment";

export const PRODUCTS_URL = "api/product";

// CREATE =>  POST: add a new product to the server
export function createProduct(product) {
  const products = {
    active: product.serviceIsActive,
    taxable: product.serviceIsTaxable,
    soldBy: product.soldBy,
    fillColor: product.fillColor,
    outlineColor: product.outlineColor,
    shape: product.shape,
    image: product.image,
    allStoreOption: product.allStoreOption,
    sku: Number(product.sku),
    category: product.categoryInProduct.value ? product.categoryInProduct.value : "",
    price: product.price ? product.price.toString() : "",
    barcode: product.barcode,
    arabicName: product.arabicName,
    englishName: product.englishName,
    englishDetail: product.englishDetail,
    arabicDetail: product.arabicDetail,
    createdVia: "Web",
    taxes: product.taxes ? product.taxes : [],
    taxType: product?.taxType,
    store: product?.storesAccess ? product?.storesAccess : [],
  };
  return axios.post(`${PRODUCTS_URL}`, products);
}

// UPDATE => PUT: update the product on the server
export function updateProduct(product) {
  const products = {
    active: product.serviceIsActive,
    taxable: product.serviceIsTaxable,
    category: product.categoryInProduct.value ? product.categoryInProduct.value : "",
    price: product.price ? product.price.toString() : "",
    barcode: product.barcode,
    arabicName: product.arabicName,
    englishName: product.englishName,
    englishDetail: product.englishDetail,
    arabicDetail: product.arabicDetail,
    soldBy: product.soldBy,
    fillColor: product.fillColor,
    outlineColor: product.outlineColor,
    shape: product.shape,
    image: product.image,
    allStoreOption: product.allStoreOption,
    sku: Number(product.sku),
    createdVia: "Web",
    taxes: product.taxes ? product.taxes : [],
    taxType: product?.taxType,
    store: product?.storesAccess ? product?.storesAccess : [],
  };
  return axios.patch(`${PRODUCTS_URL}/${product._id}`, products);
}

export function duplicateProduct(product) {
  const products = {
    active: product.serviceIsActive,
    taxable: product.serviceIsTaxable,
    category: product?.categoryInProduct?.value ? product?.categoryInProduct?.value : "",
    price: product.price ? product.price.toString() : "",
    barcode: product.barcode,
    arabicName: product.arabicName,
    englishName: product.englishName + " " + moment().format("L hh:mm a"),
    englishDetail: product.englishDetail,
    arabicDetail: product.arabicDetail,
    soldBy: product.soldBy,
    fillColor: product.fillColor,
    outlineColor: product.outlineColor,
    shape: product.shape,
    image: product.image,
    sku: Number(product.sku),
    createdVia: "Web",
    taxes: product.taxes ? product.taxes : [],
    taxType: product?.taxType,
    store: product?.storesAccess ? product?.storesAccess : [],
  };
  return axios.post(`${PRODUCTS_URL}`, products);
}

// READ
export function getAllProducts() {
  return axios.get(`${PRODUCTS_URL}`);
}

export function getProductById(productId) {
  return axios.get(`${PRODUCTS_URL}/${productId}`);
}

export function getLatestSkuNumber() {
  return axios.get(`${PRODUCTS_URL}/counts`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findProducts(queryParams) {
  return axios.get(`${PRODUCTS_URL}`);
}

// UPDATE Status
export function updateStatusForProducts(ids, status) {
  return axios.post(`${PRODUCTS_URL}/updateStatusForProducts`, {
    ids,
    status,
  });
}

// DELETE => delete the product from the server
export function deleteProduct(productId) {
  return axios.delete(`${PRODUCTS_URL}/${productId}`);
}

export function getFilteredProducts(data) {
  let store = data.store ? data.store : "";
  let category = data.category ? data.category : "";
  let status = data.status === "true" ? true : data.status === "false" ? false : "";
  return axios.get(
    `${PRODUCTS_URL}/filter?${category ? `category=${category}&` : ""}${store ? `store=${store}&` : ""}${status !== "" ? `status=${status}` : ""}`
  );
}

// DELETE Products by ids
export function deleteProducts(ids) {
  let toDelete = "?ids=";
  ids.forEach((item) => {
    toDelete = toDelete + item + ",";
  });
  var tmp = toDelete.split(""); // convert to an array
  tmp.splice(toDelete.length - 1, 1); // remove 1 element from the array (adjusting for non-zero-indexed counts)
  tmp = tmp.join("");
  return axios.delete(`${PRODUCTS_URL}${tmp}`);
}

// Assign to category Products by ids
export function productsAssignToCategory(ids, categoryId) {
  let toAssign = "?ids=";
  ids.forEach((item) => {
    toAssign = toAssign + item + ",";
  });
  var tmp = toAssign.split(""); // convert to an array
  tmp.splice(toAssign.length - 1, 1); // remove 1 element from the array (adjusting for non-zero-indexed counts)
  tmp = tmp.join("");
  return axios.patch(`${PRODUCTS_URL}${tmp}`, categoryId);
}
