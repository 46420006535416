import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import PageNavigator from "../../../components/pagination/PageNavigator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import DeleteIcon from "../../../../assets/img/company/Vector.svg";
import noCategoryIcon from "../../../../assets/img/noCategory.svg";
import { i18n } from "../../private/languageSelector";
import SubscriptionDialog from "../../company/company-edit-Dialog/SubscriptionDialog";
import { AddCategoryForm } from "./addCategory";
import * as actions from "../../../../_redux/category/categoryActions";
import CustomSearch from "../../../components/searchBar/CustomSearch";
import { sortCaret } from "../../../../_metronic/_helpers";
// import { sortCaret } from "../../../../_metronic/_helpers";

const CategoriesPage = () => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.auth);
  const [openCategoryAddDialog, setOpenCategoryAddDialog] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("desc");
  const [sortAt, setSortAt] = useState("created_at");

  const { categories, products } = useSelector(
    (state) => ({
      products: state?.products?.entities,
      categories: state?.category?.categoryForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchCategories(limit, page, sortBy, sortAt));
  }, [dispatch, limit, page, sortBy, sortAt]);

  let itemsAssignedToCategory = categories?.map((item) => {
    return products?.filter((product) => product?.categoryData?._id === item?._id).length;
  });

  const [searchValue, setSearchValue] = useState("");
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [edit, setEdit] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };

  const handleEditTaxClick = (row) => {
    setEdit(true);
    setSelectedCategory(row);
    setOpenCategoryAddDialog(true);
  };
  const highlightMatch = (text, query) => {
    if (!query) return text;
    const parts = text.split(new RegExp(`(${query})`, "gi"));
    return parts.map((part, index) => (part.toLowerCase() === query.toLowerCase() ? <b key={index}>{part}</b> : part));
  };

  const columns = [
    {
      dataField: "businessId",
      text: i18n("Customer.ID"),
      headerAlign: "start",
      headerStyle: {
        textAlign: "start",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      align: language === "arabic" ? "start" : "left",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
      sort: true,
      sortCaret: sortCaret,
      // formatter: (cell) => highlightMatch(cell, searchValue),
    },
    {
      dataField: "name",
      text: i18n("Customer.NAME"),
      align: language === "arabic" ? "start" : "left",
      headerStyle: {
        textAlign: "start",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
      formatter: (cell) => highlightMatch(cell, searchValue),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "totalProducts",
      text: "products qty.",
      align: language === "arabic" ? "start" : "left",
      headerStyle: {
        textAlign: "start",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
      formatter: (cell) => {
        const formattedCell = `${cell}`;
        return highlightMatch(formattedCell, searchValue);
      },
      sort: true,
      sortCaret: sortCaret,
    },
    // {
    //   dataField: "color",
    //   text: "",
    //   headerAlign: "start",
    //   headerStyle: {
    //     textAlign: "left",
    //     fontWeight: "400",
    //     fontSize: "14px",
    //     color: "#828282",
    //   },
    //   align: language === "arabic" ? "start" : "left",
    //   style: {
    //     paddingTop: "15px",
    //     paddingBottom: "15px",
    //   },
    //   // formatter: (cell) => highlightMatch(cell, searchValue),
    // },
  ];

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    onSelect: (row, isSelected) => {
      const newSelectedRows = isSelected ? [...selectedRows, row._id] : selectedRows.filter((id) => id !== row._id);
      setSelectedRows(newSelectedRows);
      return true;
    },
    onSelectAll: (isSelected, rows) => {
      const newSelectedRows = isSelected ? rows.map((row) => row._id) : [];
      setSelectedRows(newSelectedRows);
    },
  };

  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  if (!categories) {
    return null;
  }

  const confirmDelete = () => {
    if (selectedRows?.length > 0 && !openCategoryAddDialog) {
      for (let id of selectedRows) {
        dispatch(actions.deleteCategory(id))
          .then((res) => {
            dispatch(actions.fetchCategories(limit, page, sortBy, sortAt));
          })
          .catch((error) => {
            error.clientMessage = "Can't delete category";
            setShowDeleteCategoryModal(true);
          });
        setShowDeleteCategoryModal(false);
        setSelectedRows([]);
      }
    } else if (selectedCategory?._id) {
      dispatch(actions.deleteCategory(selectedCategory?._id))
        .then((res) => {
          dispatch(actions.fetchCategories(limit, page, sortBy, sortAt));
        })
        .catch((error) => {
          error.clientMessage = "Can't delete category";
          setShowDeleteCategoryModal(true);
        });
      setOpenCategoryAddDialog(false);
    }
    setShowDeleteCategoryModal(false);
    setSelectedRows([]);
  };

  let refactoredCategories = categories.map((item) => {
    return {
      ...item,
      totalProducts: item?.products?.length,
      color: <div style={{ backgroundColor: item.color, width: "20px", height: "20px" }}></div>,
      businessId: item?.businessId,
    };
  });

  let filteredCategories = refactoredCategories?.filter((item) => {
    let searchText = searchValue?.toLowerCase();
    return item?.name?.toLowerCase()?.includes(searchText) || item?.businessId?.toLowerCase()?.includes(searchText);
  });

  const handleSorting = (type, sortOrder, sortField, data, page, sizePerPage) => {
    if (type === "sort") {
      setSortBy(sortOrder);
      if (sortField === "totalProducts") {
        setSortAt("products");
      } else setSortAt(sortField);
    }
  };

  return (
    <>
      <div className={`${language === "arabic" ? "rtl" : "ltr"}`}>
        <div style={{ width: "100%", backgroundColor: "#FFFFFF", borderRadius: "0.42rem" }}>
          <div className="pl-4 pr-4 py-4 mb-3 d-flex justify-content-between align-items-center">
            <CustomSearch
              handleSearchInputChange={handleSearchInputChange}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              setShowSearchInput={setShowSearchInput}
              showSearchInput={showSearchInput}
            />
            <div>
              {selectedRows.length > 0 && (
                <span className={`cursor-pointer ${language === "arabic" ? "ml-5" : "mr-5"}`} onClick={() => setShowDeleteCategoryModal(true)}>
                  <img src={DeleteIcon} alt="Delete" />
                </span>
              )}
              <span
                className="add-text-btn"
                onClick={() => {
                  setEdit(false);
                  setSelectedCategory(null);
                  setOpenCategoryAddDialog(true);
                }}
              >
                {i18n("Category.AddCategory")}
              </span>
            </div>
          </div>

          <BootstrapTable
            wrapperClasses="table-responsive"
            classes="table table-head-custom table-vertical-center overflow-hidden"
            remote
            bordered={false}
            bootstrap4
            keyField="_id"
            columns={columns}
            data={[...filteredCategories]}
            selectRow={selectRow}
            rowClasses="cursor-pointer"
            onTableChange={(type, { page, sizePerPage, sortField, sortOrder, data }) =>
              handleSorting(type, sortOrder, sortField, data, page, sizePerPage)
            }
            rowEvents={{
              onClick: (e, row, rowIndex) => {
                if (e.target.type !== "checkbox") {
                  handleEditTaxClick(row);
                }
              },
            }}
          />

          {categories && categories?.length < 1 && (
            <div className="d-flex flex-column justify-content-center align-items-center mt-5 mb-5">
              <div className="category-icon-bg d-flex justify-content-center align-items-center ">
                <img src={noCategoryIcon} alt="noCategoryIcon" />
              </div>
              <p className="py-3 you-have-no-category">You have no Categories yet</p>
            </div>
          )}

          <div className="pb-3 d-flex justify-content-end mr-3 ml-3">
            <PageNavigator
              page={page}
              limit={limit}
              handlePageChange={handlePageChange}
              handleLimitChange={handleLimitChange}
              data={filteredCategories}
            />
          </div>
        </div>

        <AddCategoryForm
          openCategoryAddDialog={openCategoryAddDialog}
          setOpenCategoryAddDialog={setOpenCategoryAddDialog}
          edit={edit}
          category={selectedCategory}
          setShowDeleteModal={setShowDeleteCategoryModal}
        />

        {/* delete category modal  */}
        <SubscriptionDialog
          show={showDeleteCategoryModal}
          onHide={() => setShowDeleteCategoryModal(false)}
          modalWidth="350px"
          modalHeight="185px"
          message={i18n("Category.AreYouSureYouWantToDeleteThisCategory")}
          messageWidth="320px"
          messageHeight="44px"
          messageColor="#333333"
          messageFontWeight="400"
          messageFontSize="16px"
          messageLineHeight="21.79px"
          primaryAction={() => setShowDeleteCategoryModal(false)}
          primaryLabel={i18n("SubscriptionModals.CANCEL")}
          primaryLabelWidth="55px"
          primaryLabelHeight="20px"
          primaryLabelFontWeight="700"
          primaryLabelFontSize="14px"
          primaryLabelLineHeight="20px"
          primaryLabelColor="#828282"
          secondaryAction={confirmDelete}
          secondaryLabel={i18n("Discount.DELETE")}
          secondaryLabelWidth="67px"
          secondaryLabelHeight="20px"
          secondaryLabelFontWeight="700"
          secondaryLabelFontSize="14px"
          secondaryLabelLineHeight="20px"
          secondaryLabelColor="#EB5757"
          isCenteredOnMobile
        />
      </div>
    </>
  );
};

export default CategoriesPage;
