import React, { useEffect, useState, useMemo } from "react";
import { styles } from "../../../../helpers/pdfStyles";
import { useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import IconQr from "../../../../assets/img/icon-qr.png";
import IconFatoratyFooter from "../../../../assets/img/fat-footer.png";
import * as invoiceActions from "../../../../_redux/invoices/invoicesActions";
import { Page, Text, View, Document, PDFViewer, Image } from "@react-pdf/renderer";

const PdfInvoicePage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [productsDiscounts, setProductsDiscounts] = useState([]);
  const [productsTax, setProductsTax] = useState([]);
  const [invoiceData, setInvoiceData] = useState();
  const [companyData, setCompanyData] = useState();
  const [products, setProducts] = useState([]);
  const [filterTaxess, setFilterTaxess] = useState([]);
  const [invoiceSummary, setInvoiceSummary] = useState({
    subtotal: 0,
    discount: 0,
    taxableAmount: 0,
    taxAmount: 0,
    allTaxAmount: 0,
    total: 0,
  });

  const { invoice } = useSelector(
    (state) => ({
      invoice: state?.invoices?.invoiceForEdit,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (id) {
      dispatch(invoiceActions.fetchInvoice(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (invoice) {
      setInvoiceData({ ...invoice });
      setCompanyData({ ...invoice?.company });
      setProducts([...invoice.invoiceData.products]);
    }
  }, [dispatch, invoice]);

  const filterTaxes = (products) => {
    if (products) {
      const allProductTaxes = [];
      for (let i = 0; i < products?.length; i++) {
        if (products[i] && products[i].taxes) {
          products[i].taxes.forEach((tax) => {
            allProductTaxes.push(tax);
          });
        }
      }
      let taxesUnique = [];
      const uniqueTaxesByProduct = [];
      const uniqueArray = allProductTaxes.map((tax) => {
        const arr = allProductTaxes.filter((item) => item._id === tax._id);
        if (arr.length) {
          let includedValue = arr.reduce((a, b) => {
            return a + b.includedValue;
          }, 0);
          let excludedValue = arr.reduce((a, b) => {
            return a + b.exclusiveVaue;
          }, 0);
          taxesUnique = [...taxesUnique, arr];
          uniqueTaxesByProduct.push({ ...tax, includedValue, excludedValue });
        }
      });
      const newuniqueArray = uniqueTaxesByProduct.filter(function(value, index, self) {
        return index === self.findIndex((t) => t._id === value._id);
      });

      return newuniqueArray?.length ? newuniqueArray : allProductTaxes;
    }
  };

  ///// products discount /////
  useEffect(() => {
    let totalDiscount = [];
    if (invoice?.invoiceData?.products) {
      invoice.invoiceData.products.forEach((product) => {
        return product.discounts?.forEach((discount) => {
          return discount?.type
            ? discount.type === "%"
              ? totalDiscount.push({ name: product?.name, value: (+discount.value * +product.totalPrice) / 100 })
              : totalDiscount.push({ name: product?.name, value: discount?.value })
            : totalDiscount.push({ name: product?.name, value: 0 });
        });
      });
    }

    setProductsDiscounts([...totalDiscount]);
  }, [invoice?.invoiceData?.products]);

  ///// products tax /////
  // useEffect(() => {
  //   let totalTax = [];

  //   if (products && productsDiscounts && productsDiscounts?.length) {
  //     products.forEach((product, index) => {
  //       return product?.vat && product?.rate === "basic"
  //         ? totalTax.push(((+product?.price * +product?.quantity - productsDiscounts[index]) * +companyData?.taxRate) / 100)
  //         : totalTax.push(0);
  //     });
  //   }
  //   setProductsTax([...totalTax]);
  //   // eslint-disable-next-line
  // }, [products, productsDiscounts]);

  useEffect(() => {
    let subtotalP = 0;
    let totalD = 0;
    let taxAmount = 0;
    let allTaxAmount = 0;

    if (products && products?.length) {
      ///// Subtotal ////
      subtotalP = products?.reduce((a, b) => {
        return (subtotalP = a + +b.price * +b?.quantity);
      }, 0);

      ///// Discount ////
      totalD = products?.reduce((a, b) => {
        if (b.discount) {
          return (totalD = a + +b.discount);
        } else {
          return totalD;
        }
      }, 0);

      //  Added Tax
      taxAmount = products?.reduce((a, b) => {
        if (b?.addedTax) {
          return (taxAmount = a + b?.addedTax);
        } else {
          return taxAmount;
        }
      }, 0);
      // Total Tax
      allTaxAmount = products?.reduce((a, b) => {
        if (b?.tax) {
          return (allTaxAmount = a + +b?.tax);
        } else {
          return allTaxAmount;
        }
      }, 0);
    }
    setInvoiceSummary({
      subtotal: subtotalP,
      discount: totalD,
      taxableAmount: subtotalP - totalD,
      taxAmount: taxAmount,
      allTaxAmount: allTaxAmount,
      total: subtotalP - totalD + taxAmount,
    });
  }, [invoiceData, products, companyData]);

  function calculateDiscounts(products) {
    const discountsMap = {};
    if (products) {
      products.forEach((product) => {
        const quantity = product.quantity || 1; // Default to 1 if quantity is not provided
        let remainingPrice = product.price * quantity; // Start with the total price

        product.discounts.forEach((discount) => {
          // if (discount.isSelected) {
          const key = discount.name;
          if (!discountsMap[key]) {
            discountsMap[key] = 0;
          }

          let discountValue = 0;
          if (discount.type === "%") {
            discountValue = parseFloat((discount.value * remainingPrice) / 100); // Apply percentage discount
          } else {
            discountValue = parseFloat(discount.value); // Apply fixed discount
          }

          discountsMap[key] += discountValue; // Add to the map
          remainingPrice -= discountValue; // Deduct this discount from the remaining price
          // }
        });
      });
    }
    return discountsMap;
  }
  const aggregatedDiscounts = calculateDiscounts(invoice?.invoiceData?.products || []);

  const filteredTaxes = filterTaxes(invoice?.invoiceData?.products || []);
  useEffect(() => {
    if (products) {
      let includedTaxes = filterTaxes(products?.flat());
      setFilterTaxess([...includedTaxes]);
    }
  }, [products]);

  return useMemo(
    () => (
      <>
        {invoiceData?.invoiceData && companyData && productsDiscounts && filteredTaxes && filterTaxess && (
          <PDFViewer style={{ height: "100%" }}>
            <Document>
              <Page style={styles.page} size="A4" wrap={true}>
                <View style={styles.row}>
                  <View style={[styles.col_4]}>
                    <Text style={[styles.textBold]}>{companyData?.companyName ? companyData?.companyName : ""}</Text>
                    <Text style={[styles.infoText]}>{companyData?.taxName ? companyData?.taxName + " No. " + companyData?.taxNumber : ""}</Text>
                    <Text style={[styles.infoText]}>{companyData?.crNumber ? "C.R. " + companyData?.crNumber : ""}</Text>
                    <Text style={[styles.infoText]}>{companyData?.mobile ? companyData?.mobile : ""}</Text>
                    <Text style={[styles.infoText]}>{companyData?.email ? companyData?.email : ""}</Text>
                    <Text style={[styles.infoText]}>{companyData?.city ? companyData?.city : ""}</Text>
                  </View>
                  <View style={[styles.col_2]}>
                    {invoice?.company?.taxNumber ? (
                      <>
                        <Image style={[styles.qrCode]} src={IconQr} />
                      </>
                    ) : (
                      <Text></Text>
                    )}
                  </View>
                  <View style={[styles.col_4, { marginLeft: "6" }]}>
                    {invoice?.invoiceData?.senderInformation?.storeData?.image?.length > 0 ||
                    invoice?.company?.image ||
                    invoice?.invoiceData?.senderInformation?.companyId?.image ? (
                      <>
                        <Image
                          style={[styles.companyLogo]}
                          src={
                            invoice?.invoiceData?.senderInformation?.storeData?.image
                              ? invoice?.invoiceData?.senderInformation?.storeData?.image
                              : invoice?.company?.image
                              ? invoice?.company?.image
                              : invoice?.invoiceData?.senderInformation?.companyId?.image
                          }
                        />
                      </>
                    ) : (
                      <Text></Text>
                    )}
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={[styles.col_4]}>
                    <Text style={[styles.blockTitle]}>Customer Information</Text>
                    <View style={styles.blockInfoRow}>
                      <Text style={styles.blockInfoLabel}>Customer Name:</Text>
                      <Text style={styles.blockInfoText}>
                        {/* {invoiceData?.invoiceData?.customerName
                          ? invoiceData?.invoiceData?.customerName?.first + " " + invoiceData?.invoiceData?.customerName?.last
                          : invoiceData?.invoiceData?.receiverInformation?.name &&
                            invoiceData?.receiverInformation?.name?.first?.length > 0 &&
                            invoiceData?.receiverInformation?.name?.last?.length > 0
                          ? invoiceData?.invoiceData?.receiverInformation?.name?.first + " " + invoiceData?.receiverInformation?.name?.last
                          : ""} */}
                        {invoice?.invoiceData?.customerName?.length > 0 && invoice?.invoiceData?.customerName?.first
                          ? invoice?.invoiceData?.customerName?.first + " " + invoice?.invoiceData?.customerName?.last
                          : invoice?.invoiceData?.receiverInformation?.name
                          ? invoice?.invoiceData?.receiverInformation?.name?.first + " " + invoice?.invoiceData?.receiverInformation?.name?.last
                          : ""}
                      </Text>
                    </View>
                    <View style={styles.blockInfoRow}>
                      <Text style={[styles.blockInfoLabel]}>Business Name :</Text>
                      <Text style={[styles.blockInfoText]}>{invoice?.invoiceData?.receiverInformation?.businessName}</Text>
                    </View>
                    <View style={styles.blockInfoRow}>
                      <Text style={[styles.blockInfoLabel]}>Email:</Text>
                      <Text style={[styles.blockInfoText]}>
                        {invoiceData?.invoiceData?.customerEmail?.length > 0
                          ? invoiceData?.invoiceData?.customerEmail
                          : invoiceData?.invoiceData?.receiverInformation?.email?.length > 0
                          ? invoiceData?.invoiceData?.receiverInformation?.email
                          : ""}
                      </Text>
                    </View>
                    <View style={styles.blockInfoRow}>
                      <Text style={[styles.blockInfoLabel]}>Mobile:</Text>
                      <Text style={[styles.blockInfoText]}>{invoice?.invoiceData?.receiverInformation?.mobile}</Text>
                    </View>
                    <View style={styles.blockInfoRow}>
                      <Text style={[styles.blockInfoLabel]}>Tax No.:</Text>
                      <Text style={[styles.blockInfoText]}>{invoice?.invoiceData?.receiverInformation?.taxNumber}</Text>
                    </View>
                  </View>
                  <View style={[styles.col_2]}>
                    <Text style={{ textAlign: "center", fontSize: "14" }}>
                      {invoiceData?.invoiceData?.invoiceType === "refund" && invoice?.company?.taxNumber?.length === 0
                        ? "Refund"
                        : invoice?.invoiceData?.invoiceType === "refund" && invoice?.company?.taxNumber?.length > 0
                        ? "Credit Note"
                        : invoice?.company?.taxNumber?.length > 0
                        ? "Tax Invoice"
                        : "Invoice"}
                    </Text>
                  </View>
                  <View style={[styles.col_4]}>
                    <Text style={[styles.blockTitle]}>Invoice Information</Text>
                    <View style={styles.blockInfoRow}>
                      <Text style={[styles.blockInfoLabel]}>Invoice Number:</Text>
                      <Text style={[styles.blockInfoText]}>{invoice?.invoiceData?.invoiceNumber}</Text>
                    </View>
                    {invoice?.invoiceData?.invoiceType === "refund" ? (
                      <View style={styles.blockInfoRow}>
                        <Text style={[styles.blockInfoLabel]}>Parent Invoice Number: </Text>
                        <Text style={[styles.blockInfoText]}>{invoice?.invoiceData?.parentinvoiceNumber}</Text>
                      </View>
                    ) : (
                      <Text></Text>
                    )}

                    <View style={styles.blockInfoRow}>
                      <Text style={[styles.blockInfoLabel]}>Date:</Text>
                      <Text style={[styles.blockInfoText]}>{moment(invoiceData?.invoiceData?.invoiceDate).format("MM/DD/YYYY")}</Text>
                    </View>
                    <View style={styles.blockInfoRow}>
                      <Text style={[styles.blockInfoLabel]}>Status:</Text>
                      <Text style={[styles.blockInfoText]}>{invoiceData?.status?.charAt(0)?.toUpperCase() + invoiceData?.status?.slice(1)}</Text>
                    </View>
                    <View style={styles.blockInfoRow}>
                      <Text style={[styles.blockInfoLabel]}>Type:</Text>
                      <Text style={[styles.blockInfoText]}>
                        {invoiceData?.invoiceData?.invoiceType === "sale"
                          ? "Sales"
                          : invoiceData?.invoiceData?.invoiceType === "refund"
                          ? "Refund"
                          : ""}
                      </Text>
                    </View>
                  </View>
                </View>

                <View style={styles.table}>
                  <View style={[styles.tableRow, styles.tableBorderTop]}>
                    {/* <View style={styles.tableColHeaderNumber}>
                      <Text style={styles.tableCellHeader}></Text>
                    </View> */}
                    <View
                      style={
                        Number(invoice?.invoiceData?.taxTotal) > 0 || filterTaxess?.length > 0
                          ? styles.tableColHeaderLarge
                          : styles.tableColSlightLarge
                      }
                    >
                      <Text style={styles.tableCellHeader}>Item Name</Text>
                      <Text style={[styles.tableCellHeader, styles.shortText]}>Description</Text>
                    </View>
                    {/* <View style={styles.tableColHeaderLarge}>
                      <Text style={styles.tableCellHeader}>Item Desc.</Text>
                    </View> */}
                    <View
                      style={
                        Number(invoice?.invoiceData?.taxTotal) > 0 || filterTaxess?.length > 0
                          ? styles.tableColHeaderSmall
                          : styles.tableColHeaderCustom
                      }
                    >
                      <Text style={styles.tableCellHeader}>Unit Price</Text>
                    </View>
                    <View
                      style={
                        Number(invoice?.invoiceData?.taxTotal) > 0 || filterTaxess?.length > 0
                          ? styles.tableColHeaderSmall
                          : styles.tableColHeaderCustom
                      }
                    >
                      <Text style={styles.tableCellHeader}>Units.</Text>
                    </View>
                    <View
                      style={
                        Number(invoice?.invoiceData?.taxTotal) > 0 || filterTaxess?.length > 0
                          ? styles.tableColHeaderSmall
                          : styles.tableColHeaderCustom
                      }
                    >
                      <Text style={styles.tableCellHeader}>Disc.</Text>
                    </View>
                    {Number(invoice?.invoiceData?.taxTotal) > 0 || filterTaxess?.length > 0 ? (
                      <View style={styles.tableColHeaderMedium}>
                        <Text style={[styles.tableCellHeader, { fontSize: 8 }]}>Total before Tax</Text>
                      </View>
                    ) : (
                      <View style={[styles.tableColHeaderCustom]}>
                        <Text style={[styles.tableCellHeader, { fontSize: 8, textAlign: "right" }]}>Total</Text>
                      </View>
                    )}

                    {Number(invoice?.invoiceData?.taxTotal) > 0 || filterTaxess?.length > 0 ? (
                      <View style={{}}>
                        {/* {invoiceData?.invoiceData?.products?.map((product, index) => { */}
                        {/* return ( */}
                        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                          {filteredTaxes?.map((tax, index) => {
                            return (
                              <View style={(styles.tableColHeaderSlightSmall, styles.tableFlex)}>
                                <Text style={[styles.tableCellHeaderWithFixWidth, { fontSize: 8 }]}>{`${tax?.name} ${
                                  tax?.type !== "Added to the price" ? `(${tax?.taxRate}% Inclusive)` : `${tax?.taxRate}%`
                                }`}</Text>
                              </View>
                            );
                          })}
                        </View>
                        {/* ); */}
                        {/* })} */}
                      </View>
                    ) : (
                      <Text></Text>
                    )}
                    {/* <View style={styles.tableColHeader}>
                      <Text style={styles.tableCellHeader}>Taxable Amount</Text>
                    </View> */}
                    {/* <View style={styles.tableColHeader}>
                      <Text style={styles.tableCellHeader}>Tax Rates</Text>
                    </View>
                    <View style={styles.tableColHeaderMedium}>
                      <Text style={styles.tableCellHeader}>Tax Amount</Text>
                    </View> */}
                    {Number(invoice?.invoiceData?.taxTotal) > 0 || filterTaxess?.length > 0 ? (
                      <View style={styles.tableColHeaderLarge}>
                        <Text style={[styles.tableCellHeader, { fontSize: 8, textAlign: "right" }]}>Total with Tax</Text>
                      </View>
                    ) : (
                      <Text></Text>
                    )}
                  </View>
                  <View style={[styles.tableBorderTop]}>
                    {invoice?.invoiceData?.products?.map((item, index) => {
                      let totalPrice = 0;
                      let taxes = item?.taxes && item?.taxes?.filter((tax) => tax?.type === "Added to the price");
                      if (taxes?.length > 0) {
                        taxes.forEach((tax) => {
                          totalPrice += tax?.exclusiveVaue;
                        });
                      }

                      // let discount = item?.totalDiscountOnProduct ? Number(item?.totalDiscountOnProduct)?.toFixed(2) : "0.0";
                      let discount = productsDiscounts && productsDiscounts?.find((discount) => discount?.name === item?.name)?.value;
                      return (
                        <View style={[styles.tableRow]}>
                          {/* <View style={styles.tableColNumber}>
                            <Text style={styles.tableCell}></Text>
                          </View> */}
                          <View
                            style={
                              Number(invoice?.invoiceData?.taxTotal) > 0 || filterTaxess?.length > 0
                                ? styles.tableColLarge
                                : styles.tableColHeaderSlightLarge
                            }
                          >
                            <Text style={(styles.tableCell, styles.tableCellHeader)}>
                              {item?.name ? item.name : item?.englishName ? item?.englishName : ""}
                            </Text>
                            <Text style={[styles.tableCell, styles.shortText]}>{item?.englishDetail ? item.englishDetail : ""}</Text>
                          </View>

                          <View
                            style={
                              Number(invoice?.invoiceData?.taxTotal) > 0 || filterTaxess?.length > 0 ? styles.tableColSmall : styles.tableColCustom
                            }
                          >
                            <Text style={styles.tableCell}>{Number(item?.price)?.toFixed(2)}</Text>
                          </View>
                          <View
                            style={
                              Number(invoice?.invoiceData?.taxTotal) > 0 || filterTaxess?.length > 0 ? styles.tableColSmall : styles.tableColCustom
                            }
                          >
                            <Text style={styles.tableCell}>{item?.quantity}</Text>
                          </View>
                          <View
                            style={
                              Number(invoice?.invoiceData?.taxTotal) > 0 || filterTaxess?.length > 0 ? styles.tableColSmall : styles.tableColCustom
                            }
                          >
                            <Text style={styles.tableCell}>{discount ? Number(discount)?.toFixed(2) : "0.00"}</Text>
                          </View>

                          {Number(invoice?.invoiceData?.taxTotal) > 0 || filterTaxess?.length > 0 ? (
                            <View style={[styles.tableColMedium]}>
                              {/* <Text style={[styles.tableCell, { marginLeft: "8px", textAlign: "right" }]}> */}
                              <Text style={styles.tableCell}>
                                {/* {productsDiscounts && Number(productsDiscounts?.find((discount) => discount?.name === item?.name)?.value) > 0
                                  ? Number(+item.totalPrice - Number(productsDiscounts?.find((discount) => discount?.name === item?.name)?.value)).toFixed(2)
                                  : Number(item.totalPrice)?.toFixed(2)} */}
                                {Number(+item?.totalPrice)?.toFixed(2)}
                              </Text>
                            </View>
                          ) : (
                            <View style={[styles.tableColCustom, { textAlign: "right" }]}>
                              <Text style={styles.tableCell}>
                                {Number(+item?.totalPrice)?.toFixed(2)}{" "}
                                {/* {productsDiscounts &&
                                  Number(+item?.totalPrice) - Number(productsDiscounts?.find((discount) => discount?.name === item?.name)?.value)} */}
                              </Text>
                            </View>
                          )}
                          {Number(invoice?.invoiceData?.taxTotal) > 0 || filterTaxess?.length > 0 ? (
                            <View style={{}}>
                              {/* {invoiceData?.invoiceData?.products?.map((product, index) => { */}
                              {/* return ( */}
                              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                                {filteredTaxes?.map((tax, index) => {
                                  return (
                                    <View style={(styles.tableColSlightSmall, styles.tableFlex)}>
                                      <Text style={[styles.tableFixedWidthCell, { fontSize: 8 }]}>
                                        {Number(tax?.includedValue) > 0 ? tax?.taxRate + "%" : Number(tax?.exclusiveVaue)?.toFixed(2)}
                                      </Text>
                                    </View>
                                  );
                                })}
                              </View>
                              {/* ); */}
                              {/* })} */}
                            </View>
                          ) : (
                            <Text></Text>
                          )}

                          {Number(invoice?.invoiceData?.taxTotal) > 0 || filterTaxess?.length > 0 ? (
                            <View style={[styles.tableColLarge, { textAlign: "right" }]}>
                              <Text style={[styles.tableCell]}>{Number(+item?.totalPrice + +totalPrice)?.toFixed(2)}</Text>
                            </View>
                          ) : (
                            <Text></Text>
                          )}
                        </View>
                      );
                    })}
                  </View>
                  <View style={[styles.tableBorderBot]}></View>
                </View>

                <View style={[styles.row, { marginTop: "20" }]} wrap={false}>
                  <View style={[styles.col_4]}>
                    <Text style={[styles.blockTitle]}>Payments (SAR)</Text>
                    <View style={[styles.border]}>
                      <View style={[styles.blockBorderRow, styles.blockPaymentRow]}>
                        <Text style={styles.blockPaymentTitleText}>
                          {invoiceData?.status?.charAt(0)?.toUpperCase() + invoiceData?.status?.slice(1)}
                        </Text>
                        <Text style={styles.blockPaymentTitleText}>
                          {invoiceData?.invoiceData?.paidTotal ? Number(invoiceData?.invoiceData?.paidTotal)?.toFixed(2) : 0}
                        </Text>
                      </View>
                      {invoiceData?.invoiceData?.payments?.map((payments) => {
                        return (
                          <View style={(styles.blockBorderRow, styles.blockPaymentRow)}>
                            <Text style={styles.blockPaymentText}>{moment(payments.createdAt).format("MM/DD/YYYY")}</Text>
                            <Text style={styles.blockPaymentText}>
                              {payments?.method === "visa/master_card"
                                ? "Master/Visa Card"
                                : payments.method === "mada_card"
                                ? "Mada Card"
                                : payments.method === "bank_transfer"
                                ? "Bank "
                                : payments.method === "cash" || payments.method === "Cash"
                                ? "Cash"
                                : payments.method === "Card" || payments.method === "card"
                                ? "Card"
                                : payments?.method}
                            </Text>
                            <Text style={styles.blockPaymentText}>{Number(payments?.amount)?.toFixed(2)}</Text>
                          </View>
                        );
                      })}
                    </View>
                    <View style={[styles.border]}>
                      <View style={(styles.blockBorderRow, styles.blockPaymentRow)}>
                        <Text style={styles.blockPaymentTitleText}>Balance Due</Text>
                        <Text style={styles.blockPaymentTitleText}>
                          {invoiceData?.invoiceData?.balance ? Number(invoiceData?.invoiceData?.balance)?.toFixed(2) : 0}
                        </Text>
                      </View>
                      <View style={(styles.blockBorderRow, styles.blockPaymentRow)}>
                        <Text style={styles.blockPaymentText}>Due Date</Text>
                        <Text style={styles.blockPaymentText}>
                          {invoiceData?.invoiceData?.dueDate && invoiceData?.invoiceData?.dueDate !== "Invalid date"
                            ? moment(invoiceData?.invoiceData?.dueDate).format("MM/DD/YYYY")
                            : ""}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.col_2}></View>
                  <View style={[styles.col_4]}>
                    <Text style={[styles.blockTitle]}>Total (SAR)</Text>
                    {Number(invoice?.invoiceData?.discountTotal) > 0 ? (
                      <View style={(styles.blockBorderRow, styles.blockPaymentRow)}>
                        <Text style={(styles.blockPaymentTitleText, styles.boldText)}>Sub Total</Text>
                        <Text style={(styles.blockPaymentTitleText, styles.boldText)}>
                          {invoice?.invoiceData?.productsTotal ? Number(invoice?.invoiceData?.productsTotal)?.toFixed(2) : 0.0}
                        </Text>
                      </View>
                    ) : (
                      <Text></Text>
                    )}

                    {Number(invoice?.invoiceData?.discountTotal) > 0 ? (
                      <View style={{}}>
                        {Object.keys(aggregatedDiscounts).map((discountName, index) => {
                          const totalDiscount = aggregatedDiscounts[discountName];
                          return (
                            <View
                              style={[
                                styles.blockBorderRow,
                                styles.blockPaymentRow,
                                { display: "flex", flexDirection: "row", justifyContent: "space-between" },
                              ]}
                            >
                              <Text style={styles.blockPaymentTitleText}>{discountName}</Text>
                              <Text style={styles.blockPaymentTitleText}>-{parseFloat(totalDiscount).toFixed(2)}</Text>
                            </View>
                          );
                        })}
                      </View>
                    ) : (
                      <Text></Text>
                    )}
                    {Number(invoice?.invoiceData?.taxTotal) > 0 || filterTaxess?.length > 0 ? (
                      <View style={(styles.blockBorderRow, styles.blockPaymentRow)}>
                        <Text style={(styles.blockPaymentTitleText, styles.boldText)}>Total before Tax</Text>
                        <Text style={(styles.blockPaymentTitleText, styles.boldText)}>
                          {invoice?.invoiceData?.totalbeforetax ? Number(invoice?.invoiceData?.totalbeforetax)?.toFixed(2) : 0.0}
                        </Text>
                      </View>
                    ) : (
                      <Text></Text>
                    )}

                    {Number(invoice?.invoiceData?.taxTotal) > 0 || filterTaxess?.length > 0 ? (
                      <View style={{}}>
                        {/* {invoiceData?.invoiceData?.products?.map((product, index) => {
                          return ( */}
                        <View>
                          {filteredTaxes?.map((tax, index) => {
                            return (
                              <View
                                style={[
                                  styles.blockBorderRow,
                                  styles.blockPaymentRow,
                                  { display: "flex", flexDirection: "row", justifyContent: "space-between" },
                                ]}
                              >
                                <Text style={styles.blockPaymentTitleText}>{`${tax?.name} ${tax?.taxRate}% ${
                                  tax?.type !== "Added to the price" ? "(Inclusive)" : ""
                                }`}</Text>

                                <Text style={styles.blockPaymentTitleText}>
                                  {" "}
                                  {Number(tax?.includedValue) > 0 ? Number(tax?.includedValue)?.toFixed(2) : Number(tax?.excludedValue)?.toFixed(2)}
                                </Text>
                              </View>
                            );
                          })}
                        </View>
                        {/* );
                        })} */}
                      </View>
                    ) : (
                      <Text></Text>
                    )}
                    <View style={(styles.blockBorderRow, styles.blockPaymentRow)}>
                      <Text style={(styles.blockPaymentTitleText, { fontWeight: "bold" })}>
                        {Number(invoice?.invoiceData?.taxTotal) > 0 || filterTaxess?.length > 0 ? " Total With Tax" : "Total"}
                      </Text>
                      <Text style={(styles.blockPaymentTitleText, { fontWeight: "bold" })}>
                        {" "}
                        {invoice?.invoiceData?.invoiceType !== "refund"
                          ? invoice?.invoiceData?.totalaftertax && Number(invoice?.invoiceData?.totalaftertax)?.toFixed(2)
                          : Number(invoice?.invoiceData?.paidTotal).toFixed(2)}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={[styles.termsAndCond]}>
                  <View style={[styles.termsRow]} wrap={false}>
                    <View style={[styles.col_3]}>
                      <View style={[styles.blockTitle]}>
                        <Text>Replacement Policy:</Text>
                      </View>
                    </View>
                    <View style={[styles.col_3_5, { marginLeft: "15" }]}>
                      <View style={[styles.blockTitle]}>
                        <Text>Note:</Text>
                      </View>
                    </View>
                    <View style={[styles.col_3, { marginLeft: "15" }]}>
                      <View style={[styles.blockTitle]}>
                        <Text>Terms & Conditions:</Text>
                      </View>
                    </View>
                  </View>
                  <View style={[styles.row]} wrap={false}>
                    <View style={[styles.col_3, styles.border]}>
                      <View style={[styles.blockInfoRow]}>
                        <Text style={[styles.footerInfo]}>
                          To view the replacement policy, please visit the following link{" "}
                          <Text style={[{ color: "#2D9CDB" }]}> www.premier.com/ReplacementPolicy </Text>
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.col_3_5, styles.border, { marginLeft: "15" }]}>
                      <View style={[styles.blockInfoRow]}>
                        <Text style={[styles.footerInfo]}>
                          {invoiceData?.invoiceData?.generalNotes ? invoiceData?.invoiceData?.generalNotes : ""}
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.col_3, styles.border, { marginLeft: "15" }]}>
                      <View style={[styles.blockInfoRow]}>
                        <Text style={[styles.footerInfo]}>
                          To view the Terms and Conditions, please visit the following link{" "}
                          <Text style={[{ color: "#2D9CDB" }]}> www.premier.com/TermsAndConditions</Text>
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={[styles.bottomRow, styles.footer]} fixed>
                  <Text
                    render={({ pageNumber, totalPages }) =>
                      `${invoiceData?.invoiceData?.invoiceType === "refund" ? "Refund Invoice No." : "Invoice No."}   ${
                        invoiceData?.invoiceNumber
                      }, Page  ${pageNumber} / ${totalPages} \n ${
                        invoiceData?.invoiceData?.invoiceType === "refund" ? "Reference Invoice No." : ""
                      } ${invoiceData?.invoiceData?.invoiceType === "refund" ? invoiceData?.parentInvoiceNumber : ""} `
                    }
                    fixed
                  />
                  <Image style={[{ width: "23%" }]} src={IconFatoratyFooter} />
                  <Text render={({ pageNumber, totalPages }) => `${moment().format("ddd, DD MMM YYYY")} ${moment().format("HH:mm:ss")}`} />
                </View>
              </Page>
            </Document>
          </PDFViewer>
        )}
      </>
    ),
    // eslint-disable-next-line
    [companyData, invoiceData?.invoiceData]
  );
};

export default PdfInvoicePage;
