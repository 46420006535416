import React, { useEffect, useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/products/productsActions";
import { ProductEditDialogHeader } from "./ProductEditDialogHeader";
import { ProductEditForm } from "./ProductEditForm";
import { useProductsUIContext } from "../ProductsUIContext";
import toast from "react-hot-toast";
import SubscriptionDialog from "../../company/company-edit-Dialog/SubscriptionDialog";
import { i18n } from "../../private/languageSelector";

const initProduct = {
  id: undefined,
  englishName: "",
  arabicName: "",
  price: "",
  categoryInProduct: "",
  serviceIsActive: true,
  serviceIsTaxable: false,
  englishDetail: "",
  arabicDetail: "",
  sku: 0,
  detail: "",
  barcode: "",
  soldBy: "each",
  pos: "colorAndShape",
};
export function ProductEditDialog({ id, view, show, onHide, forInvoice, productName }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showProductDeleteModal, setShowProductDeleteModal] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [confirmDialogForClose, setConfirmDialogForClose] = useState(false);

  // Products UI Context
  const productsUIContext = useProductsUIContext();
  const productsUIProps = useMemo(() => {
    return {
      queryParams: productsUIContext?.queryParams,
    };
  }, [productsUIContext]);

  // Products Redux state
  const { actionsLoading, productForEdit, navigate, productFilterState, skuCount, products } = useSelector(
    (state) => ({
      navigate: state.products.navigate,
      actionsLoading: state.products.actionsLoading,
      productForEdit: state.products.productForEdit,
      productFilterState: state?.products?.productFilterState,
      skuCount: state?.products?.skuCount,
      products: state?.products?.entities,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (navigate) {
      onHide();
    }
    // eslint-disable-next-line
  }, [navigate]);

  useEffect(() => {
    dispatch(actions.fetchProduct(id));
  }, [id, dispatch]);
  const saveProduct = (product) => {
    let skuProduct = products.filter((distintProduct) => distintProduct?.sku === Number(product?.sku));
    if (!id) {
      if (skuProduct && skuProduct.length) {
        toast.error("SKU already exist");
      } else {
        dispatch(actions.createProduct(productFilterState, product, forInvoice ? {} : productsUIProps.queryParams)).then(() => setLoading(false));
      }
    } else {
      if (skuProduct && skuProduct.length && skuProduct[0]._id !== product?._id) {
        toast.error("SKU already exist");
      } else {
        setLoading(true);
        dispatch(actions.updateProduct(productFilterState, product, productsUIProps.queryParams)).then(() => setLoading(false));
      }
    }
  };

  const confirmDelete = () => {
    if (id) {
      dispatch(actions.deleteProduct(id)).then(() => {
        dispatch(actions.fetchProducts(productFilterState, productsUIProps.queryParams));
      });
      setShowProductDeleteModal(false);
      onHide();
    }
  };
  const handleClose = () => {
    if (isFormDirty) {
      setConfirmDialogForClose(true);
    } else {
      onHide();
    }
  };
  return (
    <Modal className="modal-drawer invoice-drawer" size="lg" show={show} onHide={handleClose} aria-labelledby="example-modal-sizes-title-lg">
      {/* <ProductEditDialogHeader onHide={onHide} id={id} view={view} /> */}
      <ProductEditForm
        view={view}
        onHide={onHide}
        loading={loading}
        saveProduct={saveProduct}
        setIsFormDirty={setIsFormDirty}
        actionsLoading={actionsLoading}
        confirmDialogForClose={confirmDialogForClose}
        setConfirmDialogForClose={setConfirmDialogForClose}
        setShowProductDeleteModal={setShowProductDeleteModal}
        product={
          productForEdit || {
            ...initProduct,
            sku: skuCount,
            englishName: productName ? productName : "",
          }
        }
      />
      <SubscriptionDialog
        show={showProductDeleteModal}
        onHide={() => setShowProductDeleteModal(false)}
        modalWidth="350px"
        modalHeight="185px"
        message={"Are you sure you want to delete the selected Product?"}
        messageWidth="320px"
        messageHeight="44px"
        messageColor="#333333"
        messageFontWeight="400"
        messageFontSize="16px"
        messageLineHeight="21.79px"
        primaryAction={() => setShowProductDeleteModal(false)}
        primaryLabel={i18n("SubscriptionModals.CANCEL")}
        primaryLabelWidth="55px"
        primaryLabelHeight="20px"
        primaryLabelFontWeight="700"
        primaryLabelFontSize="14px"
        primaryLabelLineHeight="20px"
        primaryLabelColor="#828282"
        secondaryAction={confirmDelete}
        secondaryLabel={"DELETE"}
        secondaryLabelWidth="67px"
        secondaryLabelHeight="20px"
        secondaryLabelFontWeight="700"
        secondaryLabelFontSize="14px"
        secondaryLabelLineHeight="20px"
        secondaryLabelColor="#EB5757"
      />
    </Modal>
  );
}
