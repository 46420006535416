import React, { useRef, useEffect } from "react";
import closeIcon from "../../../assets/img/crossIcon.svg";
import searchIcon from "../../../assets/img/company/SearchIcon.svg";

function CustomSearch({ setSearchValue, searchValue, handleSearchInputChange, showSearchInput, setShowSearchInput, rightView = false, handleEmpty }) {
  const searchInputRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchInputRef.current && !searchInputRef.current.contains(event.target) && searchValue === "") {
        setShowSearchInput(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchValue]);

  return (
    <div style={{ cursor: "pointer" }} className="d-flex justify-content-between align-items-center position-relative">
      <img style={{ opacity: showSearchInput ? 0 : 1 }} onClick={() => setShowSearchInput(!showSearchInput)} src={searchIcon} alt="SearchImage" />
      {showSearchInput && (
        <div ref={searchInputRef} className={`${rightView ? "position-right" : "position-left"} search-wrap `}>
          <img src={searchIcon} alt="SearchImage" className="ml-3" />
          <input
            className="form-control mr-sm-2 ml-3"
            type="text"
            placeholder={"Search"}
            aria-label="Search"
            value={searchValue}
            onChange={handleSearchInputChange}
          />
          {searchValue?.length > 0 && (
            <img src={closeIcon} alt="closeIcon" className="mr-3 close-icon" onClick={() => (handleEmpty ? handleEmpty() : setSearchValue(""))} />
          )}
        </div>
      )}
    </div>
  );
}

export default CustomSearch;
