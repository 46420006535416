// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/invoices/invoicesActions";
import {
  getSelectRow,
  PleaseWaitMessage,
  headerSortingClasses,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  sortCustomHandler,
  sortCaret,
} from "../../../../_metronic/_helpers";
import * as uiHelpers from "../InvoicesUIHelpers";
import * as columnFormatters from "./column-formatters";
import { useInvoicesUIContext } from "../InvoicesUIContext";
import { Pagination } from "../../../../_metronic/_partials/controls";
import PageNavigator from "../../../components/pagination/PageNavigator";

export function InvoicesTable({ tableFields, page, size, getInvoiceByFilters, setShowInvoiceMobileDialog, setSelectedId }) {
  const dispatch = useDispatch();

  const invoicesUIContext = useInvoicesUIContext();
  const invoicesUIProps = useMemo(() => {
    return {
      ids: invoicesUIContext.ids,
      setIds: invoicesUIContext.setIds,
      queryParams: invoicesUIContext.queryParams,
      setQueryParams: invoicesUIContext.setQueryParams,
      openEditInvoiceDialog: invoicesUIContext.openEditInvoiceDialog,
      openDeleteInvoiceDialog: invoicesUIContext.openDeleteInvoiceDialog,
      openViewInvoicesDialog: invoicesUIContext.openViewInvoicesDialog,
      openPrintInvoicePage: invoicesUIContext.openPrintInvoicePage,
      openViewInvoiceActionDialog: invoicesUIContext.openViewInvoiceActionDialog,
    };
  }, [invoicesUIContext]);

  // Getting curret state of invoices list from store (Redux)
  const { currentState, invoiceFilterState } = useSelector(
    (state) => ({
      currentState: state.invoices,
      invoiceFilterState: state?.invoices?.invoiceFilterState,
    }),
    shallowEqual
  );

  const { totalCount, entities, listLoading } = currentState;
  useEffect(() => {
    invoicesUIProps.setIds([]);
    getInvoiceByFilters(); // eslint-disable-next-line
  }, [invoicesUIProps.queryParams, dispatch]);

  // Table columns
  const columns = [
    {
      dataField: "_id",
      text: "ID",
      sort: true,
      sortCaret: sortCustomHandler,
      formatter: (value, row) => {
        return (
          <div
            onClick={() => {
              setShowInvoiceMobileDialog(true);
              setSelectedId(row._id);
            }}
          >
            {value}
          </div>
        );
      },
      // headerSortingClasses,
    },
    {
      dataField: "invoice",
      text: "INVOICE NO",
      sort: true,
      sortCaret: sortCustomHandler,
      formatter: (value, row) => {
        return (
          <div
            onClick={() => {
              setShowInvoiceMobileDialog(true);
              setSelectedId(row._id);
            }}
          >
            {value}
          </div>
        );
      },
      // headerSortingClasses,
    },
    {
      dataField: "date",
      text: "DATE",
      sort: true,
      sortCaret: sortCustomHandler,
      formatter: (value, row) => {
        return (
          <div
            onClick={() => {
              setShowInvoiceMobileDialog(true);
              setSelectedId(row._id);
            }}
          >
            {value}
          </div>
        );
      },
      // headerSortingClasses,
    },
    {
      dataField: "invoiceType",
      text: "TYPE",
      sort: true,
      sortCaret: sortCustomHandler,
      // headerSortingClasses,
      formatter: (cell, row) => (
        <div
          onClick={() => {
            setShowInvoiceMobileDialog(true);
            setSelectedId(row._id);
          }}
        >
          {cell ? cell.charAt(0).toUpperCase() + cell.slice(1) : cell === ""}
        </div>
      ),
    },

    {
      dataField: "customerName",
      text: "CUSTOMER",
      // text: "CUSTOMER name",
      sort: true,
      sortCaret: sortCustomHandler,
      // headerSortingClasses,
      formatter: (cell, row) => (
        <>
          <div
            onClick={() => {
              setShowInvoiceMobileDialog(true);
              setSelectedId(row._id);
            }}
          >
            {cell && cell !== "" && cell.length > 20 ? cell.substring(0, 15) + "..." : cell}
          </div>
        </>
      ),
    },
    {
      dataField: "issuedBy",
      text: "USER",
      // text: "ISSUED BY",
      sort: true,
      sortCaret: sortCustomHandler,
      formatter: (value, row) => {
        return (
          <div
            onClick={() => {
              setShowInvoiceMobileDialog(true);
              setSelectedId(row._id);
            }}
          >
            {value}
          </div>
        );
      },
      // headerSortingClasses,
    },

    {
      dataField: "store",
      text: "STORE",
      sort: true,
      sortCaret: sortCustomHandler,
      formatter: (value, row) => {
        return (
          <div
            onClick={() => {
              setShowInvoiceMobileDialog(true);
              setSelectedId(row._id);
            }}
          >
            {value}
          </div>
        );
      },
      // headerSortingClasses,
    },

    {
      dataField: "totalSales",
      text: "AMOUNT",
      // text: "BALANCE",
      sort: true,
      sortCaret: sortCustomHandler,
      classes: "text-right pr-3",
      headerClasses: "text-right pr-3 text-width",
      // headerSortingClasses,
      formatter: (value, row) => (
        <div
          onClick={() => {
            setShowInvoiceMobileDialog(true);
            setSelectedId(row._id);
          }}
        >
          {parseFloat(value)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
        </div>
      ),
    },

    // {
    //   dataField: "action",
    //   text: "ACTIONS",
    //   formatter: columnFormatters.ActionsColumnFormatter,
    //   formatExtraData: {
    //     openEditInvoiceDialog: invoicesUIProps.openEditInvoiceDialog,
    //     openDeleteInvoiceDialog: invoicesUIProps.openDeleteInvoiceDialog,
    //     openViewInvoicesDialog: invoicesUIProps.openViewInvoicesDialog,
    //     openPrintInvoicePage: invoicesUIProps.openPrintInvoicePage,
    //     openViewInvoiceActionDialog: invoicesUIProps.openViewInvoiceActionDialog,
    //   },

    //   classes: "text-right pr-0",
    //   headerClasses: "text-right pr-3 text-width",
    //   style: {
    //     minWidth: "100px",
    //   },
    // },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: size,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: invoicesUIProps.queryParams.pageSize,
    page: page,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <>
              {/* pagination to hide later */}
              {/* <Pagination isLoading={listLoading} paginationProps={paginationProps}> */}
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="_id"
                data={entities === null ? [] : entities}
                rowEvents={{
                  onClick: (e, row, rowIndex) => {
                    setShowInvoiceMobileDialog(true);
                    setSelectedId(row._id);
                  },
                }}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: invoicesUIProps.ids,
                //   setIds: invoicesUIProps.setIds,
                // })}
                columns={columns.filter((item) => {
                  if (item.text !== "INVOICE NO" && item.text !== "ACTIONS") {
                    let el = tableFields?.find((subItem) => subItem.name.toLowerCase() === item.text.toLowerCase());
                    if (el?.active) {
                      return item;
                    } else {
                      return null;
                    }
                  } else {
                    return item;
                  }
                })}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(invoicesUIProps.setQueryParams)}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
              {/* </Pagination> */}
            </>
          );
        }}
      </PaginationProvider>
    </>
  );
}
