import axios from "axios";

export const CATEGORY_URL = "api/category";

// GET single category by id
export function getCategoryById(categoryId) {
  return axios.get(`${CATEGORY_URL}/${categoryId}`);
}

// GET all categories
export function getAllCategories(limit = 10, page = 1, sortOrder = "desc", sortField = "created_at") {
  return axios.get(`${CATEGORY_URL}?limit=${limit}&page=${page}&sortOrder=${sortOrder}&sortField=${sortField}`);
}

// POST Category
export function createCategory(category) {
  const newCategory = category;
  return axios.post(`${CATEGORY_URL}`, newCategory);
}

// UPDATE category
export function updateCategory(category) {
  const newCategory = {
    isActive: category.isActive,
    name: category.name,
    description: category.description,
    color: category.color,
    products: category?.products,
  };
  return axios.patch(`${CATEGORY_URL}/${category._id}`, newCategory);
}

// DELETE  category
export function deleteCategory(categoryId) {
  return axios.delete(`${CATEGORY_URL}/${categoryId}`);
}
