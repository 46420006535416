import React from "react";
import SideCard from "../../../components/Card/SideCard";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as analyticsActions from "../../../../_redux/analytics/analyticsSlice";

import iconClose from "../../../../assets/img/filterClose.svg";

function FiltersDialog({
  handleSelectStores,
  handleSelectUsers,
  allUsers,
  allStores,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  handleStartDateChange,
  handleEndDateChange,
  stores,
  users,
  page,
  limit,
  tableType,
  grossSalesChartData,
  showTimeRange,
  shouldSetDailyTimeRange,
  selectedTimeRange,
  setSelectedTimeRange,
  timeZone,
  timeRange,
  onTimeRange,
  onTimeRangeChange,
  resetFiltersHandler,
}) {
  const { openFiltersDialog } = useSelector((state) => state.analytics);
  const dispatch = useDispatch();

  const handleCloseFiltersDialog = () => {
    dispatch(analyticsActions.setOpenFiltersDialog(!openFiltersDialog));
  };

  return (
    <div>
      <Modal
        className="modal-drawer invoice-drawer full-height-dialog "
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={openFiltersDialog}
        onHide={handleCloseFiltersDialog}
      >
        <Modal.Header style={{ background: "transparent", textAlign: "start", border: "none" }}>
          <span onClick={handleCloseFiltersDialog} className="cursor-pointer">
            <img src={iconClose} alt="iconClose" />
          </span>
        </Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default" style={{ marginTop: "-1rem" }}>
          <SideCard
            handleSelectStores={handleSelectStores}
            handleSelectUsers={handleSelectUsers}
            allUser={allUsers}
            allStores={allStores}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            handleStartDateChange={handleStartDateChange}
            handleEndDateChange={handleEndDateChange}
            stores={stores}
            users={users}
            page={page}
            limit={limit}
            tableType={tableType}
            grossSalesChartData={grossSalesChartData}
            showTimeRange={showTimeRange}
            shouldSetDailyTimeRange={shouldSetDailyTimeRange}
            selectedTimeRange={selectedTimeRange}
            setSelectedTimeRange={setSelectedTimeRange}
            timeZone={timeZone}
            displayAsDialog={true}
            timeRange={timeRange}
            onTimeRangeChange={onTimeRangeChange}
            resetFiltersHandler={resetFiltersHandler}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default FiltersDialog;
