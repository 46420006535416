import React, { useState, useEffect, useMemo } from "react";
// import "./analytics.css";
import SideCard from "../../../components/Card/SideCard";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { exportTableToPDF } from "../../../../utils/pdfExportUtil";
import { i18n } from "../../private/languageSelector";
import { InvoicesTable } from "../invoices-table/InvoicesTable";
import { useInvoicesUIContext } from "../InvoicesUIContext";
import { cloneDeep } from "lodash";
import { INVOICE_TABLE_ATTRIBUTES } from "../../../../helpers/constants";
import { getUserByToken } from "../../../modules/Auth/_redux/authCrud";
import * as usersActions from "../../../../_redux/users/usersActions";
import * as productActions from "../../../../_redux/products/productsActions";
import * as invoiceActions from "../../../../_redux/invoices/invoicesActions";
import * as storesActions from "../../../../_redux/stores/storeActions";
import PageNavigator from "../../../components/pagination/PageNavigator";
import FiltersDialog from "../../analytics/filters-dialog/FiltersDialog";
import moment from "moment";
import FilterDialogeNew from "./FilterDialogeNew";
import { InvoiceStore } from "../invoice-create-dialog/InvoiceStore";
import { InvoiceMobileDialog } from "../invoice-pdf/InvoiceMobileDialog";
import { InvoicesDeleteDialog } from "../invoices-delete-dialog/InvoicesDeleteDialog";
import SendPdfInvoiceDialog from "../send-invoice-pdf/sendPDFInvoiceDialog";
import axios from "axios";
const InvoiceFilterPage = () => {
  const dispatch = useDispatch();
  const [allStores, setAllStores] = useState("");
  const [allUsers, setAllUsers] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("All Invoices");
  const [tableType, setTableType] = useState("salesByTime");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [timeRange, setTimeRange] = useState({ start: null, end: null });
  const [timeZone, setTimeZone] = useState("Asia/Riyadh");
  const [edit, setEdit] = useState(false);
  const [openStoreDialog, setOpenStoreDialog] = useState(false);
  const [showInvoiceMobileDialog, setShowInvoiceMobileDialog] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [showShareModal, setShowShareModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [message, setMessage] = useState();
  const [emailMessage, setEmailMessage] = useState();

  const { openFiltersDialog } = useSelector((state) => state.analytics);

  const invoicesUIContext = useInvoicesUIContext();
  const invoicesUIProps = useMemo(() => {
    return {
      ids: invoicesUIContext.ids,
      setIds: invoicesUIContext.setIds,
      queryParams: invoicesUIContext.queryParams,
      newInvoiceButtonClick: invoicesUIContext.newInvoiceButtonClick,
      queryParams: invoicesUIContext.queryParams,
      setQueryParams: invoicesUIContext.setQueryParams,
      newInvoiceButtonClick: invoicesUIContext.newInvoiceButtonClick,
    };
  }, [invoicesUIContext]);

  const [tableFields, setTableFields] = useState([]);

  const { users, filter, stores, products, productFilterState, totalCount, refundCount, salesCount, userAccess, currentState, invoice } = useSelector(
    (state) => ({
      users: state?.users?.entities,
      filter: state?.invoices?.filterOn,
      products: state?.products?.entities,
      stores: state?.stores?.entities,
      productFilterState: state?.products?.productFilterState,
      totalCount: state?.invoices?.allInvoiceCount,
      salesCount: state?.invoices?.salesCount,
      refundCount: state?.invoices?.refundCount,
      userAccess: state?.users?.userAccess,
      currentState: state.invoices,
      invoice: state?.invoices.invoiceForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    let tableData = cloneDeep([...INVOICE_TABLE_ATTRIBUTES]);
    setTableFields(tableData);

    getUserByToken().then((res) => {
      if (res?.data?.invoiceTableContent?.length) {
        setTableFields([...res.data.invoiceTableContent]);
      }
    });
  }, []);

  useEffect(() => {
    setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, []);

  const getInvoiceByFilters = () => {
    dispatch(
      invoiceActions.fetchInvoices(
        {
          type: selectedTitle === "All Invoices" ? undefined : selectedTitle === "Sales" ? "sale" : "refund",
          status: "",
          channel: "",
          store: allStores?.length > 0 && allStores !== "all" ? allStores : "",
          product: "",
          user: allUsers?.length > 0 && allUsers !== "all" ? allUsers : "",
          startDate: startDate,
          endDate: endDate,
          page: page,
          size: limit,
          isPaginated: true,
        },
        invoicesUIProps.queryParams
      )
    );
  };

  useEffect(() => {
    getInvoiceByFilters();
    dispatch(
      invoiceActions.setInvoiceFilterState({
        type: selectedTitle === "All Invoices" ? undefined : selectedTitle === "Sales" ? "sale" : "refund",
        status: "",
        channel: "",
        store: allStores?.length > 0 && allStores !== "all" ? allStores : "",
        product: "",
        user: allUsers?.length > 0 && allUsers !== "all" ? allUsers : "",
        startDate: startDate,
        endDate: endDate,
      })
    );
  }, [startDate, endDate, selectedTitle.length, allUsers, allStores, page, limit]);

  let startTime = timeRange?.start?.split(":")[0];
  let endTime = timeRange?.end?.split(":")[0];

  useEffect(() => {
    if (startTime !== "undefined" && endTime !== "undefined") {
      dispatch(
        invoiceActions.fetchInvoices(
          {
            type: selectedTitle === "All Invoices" ? undefined : selectedTitle === "Sales" ? "sale" : "refund",
            status: "",
            channel: "",
            store: allStores?.length > 0 && allStores !== "all" ? allStores : "",
            product: "",
            user: allUsers?.length > 0 && allUsers !== "all" ? allUsers : "",
            startDate: startDate,
            endDate: endDate,
            startTime: startTime,
            endTime: endTime,
            endDate: endDate,
            page: page,
            size: limit,
            isPaginated: true,
          },
          invoicesUIProps.queryParams
        )
      );
      dispatch(
        invoiceActions.setInvoiceFilterState({
          type: selectedTitle === "All Invoices" ? undefined : selectedTitle === "Sales" ? "sale" : "refund",
          status: "",
          channel: "",
          store: allStores?.length > 0 && allStores !== "all" ? allStores : "",
          product: "",
          user: allUsers?.length > 0 && allUsers !== "all" ? allUsers : "",
          startDate: startDate,
          endDate: endDate,
          startTime: startTime,
          endTime: endTime,
          endDate: endDate,
          page: page,
          size: limit,
          isPaginated: true,
        })
      );
    }
  }, [timeRange]);

  useEffect(() => {
    axios.get("api/whoAmI").then((res) => {
      setMessage(res?.data?.companyId?.defaultSms?.messageEnglish);
      setEmailMessage(res?.data?.companyId?.defaultEmail?.messageEnglish);
    });
  }, []);

  const handleSelectStores = (storeIds) => {
    if (storeIds === "all" || storeIds?.length === stores?.length) {
      setAllStores("");
    } else setAllStores(storeIds);
  };
  const handleSelectUsers = (userIds) => {
    if (userIds === "all") {
      setAllUsers("");
    } else setAllUsers(userIds);
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };

  const handleTimeRangeChange = (start, end) => {
    setTimeRange({ start, end });
  };

  const { language } = useSelector((state) => state.auth);

  let titles = [
    {
      label: "All Invoices",
      value: totalCount,
    },
    {
      label: "Sales",
      value: salesCount,
    },
    {
      label: "Refunds",
      value: refundCount,
    },
  ];

  useEffect(() => {
    dispatch(storesActions.fetchStores()).then(() => {
      dispatch(usersActions.fetchUsers()).then(() => {
        dispatch(productActions.fetchProducts(productFilterState, invoicesUIContext.queryParams));
      });
    });
    // eslint-disable-next-line
  }, [dispatch]);

  const clearFilterhandle = () => {
    setAllStores("all");
    setAllUsers("all");
    const last30DaysStart = moment().subtract(29, "days");
    setStartDate(last30DaysStart);
    setEndDate(moment());
    localStorage.setItem("startDate", last30DaysStart.toISOString());
    localStorage.setItem("endDate", moment()?.toISOString());
    localStorage.setItem("timeRange", "allDay");
  };
  return (
    <>
      {/* create invoice option hidden */}
      {/* <div className="text-right pb-3 invoice-btn-margin" style={{ marginTop: "-1rem" }}>
        {userAccess?.createData ? (
          <button
            type="button"
            className="btn btn-pri btn-sm btn-rounded"
            onClick={() => {
              setEdit(false);
              setOpenStoreDialog(true);
            }}
          >
            <span className="fas fa-plus mr-2" />
            {i18n("INVOICES.TaxInvoice")}
          </button>
        ) : (
          ""
        )}
      </div> */}
      <div className={`d-flex time-card w-100 ${language === "arabic" ? "rtl" : "ltr"}`}>
        <SideCard
          handleSelectStores={handleSelectStores}
          handleSelectUsers={handleSelectUsers}
          allUser={allUsers}
          allStores={allStores}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
          page={page}
          limit={limit}
          tableType={tableType}
          onTimeRangeChange={handleTimeRangeChange}
          timeRange={true}
          showFullHeight
          resetFiltersHandler={clearFilterhandle}
        />

        <div className={`main-content  ${language === "arabic" ? "mr-2 mr-none" : "ml-2 ml-none"}`}>
          <div className="d-flex  invoice-chart">
            {titles.map((title, index) => (
              <div
                key={index}
                className={`card ${selectedTitle === title?.label ? "selected-chart" : ""}`}
                style={{ cursor: "pointer", marginBottom: "1rem" }}
                onClick={() => setSelectedTitle(title?.label)}
              >
                <div className="invoice-card">
                  <h5 className="card-title">{title?.label}</h5>
                  <div>{title?.value}</div>
                </div>
              </div>
            ))}
          </div>

          <div className="card mb-3">
            <div className="card-body p-0 ">
              <h5 className="card-title text-primary" role="button" onClick={() => exportTableToPDF("tableToExport", "invoices.pdf")}>
                {i18n("LandingPage.EXPORT")}
              </h5>
              <div id="tableToExport">
                {/* <Table columns={columns} data={filteredTableData} onSort={handleSort} sortBy={sortBy} sortOrder={sortOrder} /> */}
                <InvoicesTable
                  tableFields={tableFields}
                  page={page}
                  size={limit}
                  getInvoiceByFilters={getInvoiceByFilters}
                  setShowInvoiceMobileDialog={setShowInvoiceMobileDialog}
                  setSelectedId={setSelectedId}
                />
              </div>
            </div>
            <nav>
              <PageNavigator
                page={page}
                limit={limit}
                handlePageChange={handlePageChange}
                handleLimitChange={handleLimitChange}
                data={currentState?.entities}
              />
            </nav>
            {openFiltersDialog && (
              <FiltersDialog
                handleSelectStores={handleSelectStores}
                handleSelectUsers={handleSelectUsers}
                allUsers={allUsers}
                allStores={allStores}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                handleStartDateChange={handleStartDateChange}
                handleEndDateChange={handleEndDateChange}
                page={page}
                limit={limit}
                tableType={tableType}
                onTimeRangeChange={handleTimeRangeChange}
                timeRange={true}
                resetFiltersHandler={clearFilterhandle}
              />
            )}
          </div>
        </div>
      </div>
      <InvoiceStore edit={edit} openStoreDialog={openStoreDialog} setOpenStoreDialog={setOpenStoreDialog} />
      <InvoiceMobileDialog
        id={selectedId}
        show={showInvoiceMobileDialog}
        onHide={() => setShowInvoiceMobileDialog(false)}
        setShowDeleteModal={setShowDeleteModal}
        setShowShareModal={setShowShareModal}
      />
      <SendPdfInvoiceDialog
        id={selectedId}
        secondaryAction={() => {
          setShowShareModal(false);
          setShowInvoiceMobileDialog(false);
        }}
        invoice={invoice}
        smsMessage={message}
        emailMessageFromCompany={emailMessage}
        sendPdfInvoiceDialog={showShareModal}
        setSendPdfInvoiceDialog={setShowShareModal}
      />
      <InvoicesDeleteDialog
        show={showDeleteModal}
        onHide={() => {
          setShowDeleteModal(false);
        }}
      />
    </>
  );
};

export default InvoiceFilterPage;
