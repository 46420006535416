import * as requestFromServer from "./productsCrud";
import TableUtils from "./../utils/table.utils";
import { productsSlice, callTypes } from "./productsSlice";
import toast from "react-hot-toast";
import { getNumber, getSkuNumber } from "../../helpers/helpers";
const { actions } = productsSlice;

export const fetchProducts = (filterState, queryParams) => (dispatch) => {
  dispatch(filterProducts(filterState, queryParams));
  // dispatch(actions.startCall({ callType: callTypes.list }));
  // return requestFromServer
  //   .findProducts(queryParams)
  //   .then((response) => {
  //     const { products } = response.data;
  //     const tableUtils = new TableUtils();

  //     let refactoredProducts = [].concat(
  //       ...products.map((it) => ({
  //         ...it,
  //         sr_no: getNumber(it?.productNumber),
  //         id: it._id,
  //         name: it.englishName
  //           ? it.englishName
  //           : it?.arabicName
  //           ? it?.arabicName
  //           : "",
  //         arabicName: it?.arabicName ? it?.arabicName : "",
  //         description: it.englishDetail ? it.englishDetail : it.arabicDetail,
  //         category: it?.category?.name ? it.category.name : "",
  //         categoryData: it?.category ? it?.category : {},
  //         status: it.active ? "Active" : "Inactive",
  //         createdVia: it?.createdVia ? it?.createdVia : "",
  //       })),
  //     );
  //     const filterdProducts = queryParams
  //       ? tableUtils.baseFilter(refactoredProducts, queryParams)
  //       : {
  //           totalCount: refactoredProducts?.length
  //             ? refactoredProducts?.length
  //             : 0,
  //           entities: refactoredProducts,
  //         };
  //     dispatch(
  //       actions.productsFetched({
  //         totalCount: filterdProducts.totalCount,
  //         entities: filterdProducts.entities,
  //       }),
  //     );
  //   })
  //   .catch((error) => {
  //     toast.error(error?.response?.data?.message);
  //     error.clientMessage = "Can't find products";
  //     dispatch(actions.catchError({ error, callType: callTypes.list }));
  //   });
};

export const fetchProduct = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.productFetched({ productForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getProductById(id)
    .then((response) => {
      const { product } = response.data;
      dispatch(
        actions.productFetched({
          productForEdit: {
            ...product,
            serviceIsActive: product.active,
            serviceIsTaxable: product.taxable,
            categoryInProduct: {
              value: product?.category?._id,
              label: product?.category?.name ? product?.category?.name : "",
            },
          },
        })
      );
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't find product";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteProduct = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteProduct(id)
    .then((response) => {
      dispatch(actions.productDeleted({ id }));
      toast.success("Deleted Successfully");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't delete product";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createProduct = (productFilterState, productForCreation, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createProduct(productForCreation)
    .then((response) => {
      const { product } = response.data;
      dispatch(actions.productCreated({ newProduct: product }));
      dispatch(fetchProducts(productFilterState, queryParams));
      toast.success("Product Created");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't create product";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const duplicateProduct = (productFilterState, productForCreation, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .duplicateProduct(productForCreation)
    .then((response) => {
      const { product } = response.data;
      dispatch(actions.productCreated({ product }));
      dispatch(fetchProducts(productFilterState, queryParams));
      toast.success("Duplicated");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't create product";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateProduct = (productFilterState, product, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateProduct(product)
    .then(() => {
      dispatch(actions.productUpdated({ product }));
      dispatch(fetchProducts(productFilterState, queryParams));
      toast.success("Product Updated");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't update product";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateProductsStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForProducts(ids, status)
    .then(() => {
      dispatch(actions.productsStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't update products status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteProducts = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteProducts(ids)
    .then(() => {
      dispatch(actions.productsDeleted({ ids }));
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't delete products";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const productsAssignToCategory = (productFilterState, ids, categoryId, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .productsAssignToCategory(ids, categoryId)
    .then(() => {
      dispatch(fetchProducts(productFilterState, queryParams));
      toast.success("Category Assigned Successfully");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't delete products";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const filterProducts = (data, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getFilteredProducts(data)
    .then((response) => {
      const { products } = response.data;
      const tableUtils = new TableUtils();
      let refractoredProducts = [].concat(
        ...products.map((it, index) => ({
          ...it,
          sr_no: getNumber(it?.productNumber),
          id: it._id,
          name: it.englishName ? it.englishName : it?.arabicName ? it?.arabicName : "",
          arabicName: it?.arabicName ? it?.arabicName : "",
          category: it?.category?.name ? it.category.name : "",
          categoryData: it?.category ? it?.category : {},
          status: it.active ? "Active" : "Inactive",
          createdVia: it?.createdVia ? it?.createdVia : "Pos",
          description: it.englishDetail ? it.englishDetail : it.arabicDetail,
        }))
      );
      const filterdProducts = queryParams
        ? tableUtils.baseFilter(refractoredProducts, queryParams)
        : {
            totalCount: refractoredProducts?.length ? refractoredProducts?.length : 0,
            entities: refractoredProducts,
          };
      dispatch(
        actions.productsFetched({
          totalCount: filterdProducts.totalCount,
          entities: filterdProducts.entities.reverse(),
          filterOn: false,
        })
      );
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't filter invoices";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const setProductFilterState = (state) => (dispatch) => {
  dispatch(actions.productFiltersSet({ productFilterState: state }));
};
export const clearProductFilterState = () => (dispatch) => {
  dispatch(actions.productFiltersClear());
};
export const setProductFilterOnState = (state) => (dispatch) => {
  dispatch(actions.productFilterOn({ filterOn: state }));
};

export const fetchProductsSku = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllProducts()
    .then((response) => {
      let skuCount = getSkuNumber(response?.data?.products);
      dispatch(
        actions.productSkuFetch({
          skuCount,
        })
      );
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't find any product";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
