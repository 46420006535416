import { createSlice } from "@reduxjs/toolkit";
const filterInvoiceInitState = {
  type: "",
  status: "",
  channel: "",
  store: "",
  product: "",
  user: "",
  startDate: "",
  endDate: "",
  typeName: "",
  statusName: "",
  channelName: "",
  storeName: "",
  productName: "",
  userName: "",
  startDateName: "",
  endDateName: "",
};

const initialInvoicesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  notifications: null,
  invoiceForEdit: undefined,
  invoiceForShow: undefined,
  lastError: null,
  invoicesByDate: null,
  filterOn: false,
  invoiceFilterState: filterInvoiceInitState,
  refundCount: 0,
  salesCount: 0,
  allInvoiceCount: 0,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const invoicesSlice = createSlice({
  name: "invoices",
  initialState: initialInvoicesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getInvoiceById
    invoiceFetched: (state, action) => {
      state.actionsLoading = false;
      state.invoiceForEdit = {
        ...action.payload.invoiceForEdit,
      };
      state.error = null;
    },
    // findInvoices
    invoicesFetchedByDate: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.invoicesByDate = entities;
      state.totalCount = totalCount;
    },
    // Get Invoices By Date
    invoicesFetched: (state, action) => {
      const { totalCount, entities, refundCount, salesCount, allInvoiceCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.refundCount = refundCount;
      state.salesCount = salesCount;
      state.allInvoiceCount = allInvoiceCount;
    },
    // Get Notifications
    notificationsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.notifications = entities;
      state.totalCount = totalCount;
    },
    // createInvoice
    invoiceCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.invoiceForShow = action.payload.invoice;
    },
    // updateInvoice
    invoiceUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.invoiceForShow = action.payload.invoice;
    },
    invoiceFilterOn: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.filterOn = action.payload.filterOn;
    },
    // deleteInvoice
    invoiceDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.filter(
      //   (el) => el.id !== action.payload.id
      // );
    },
    // deleteInvoices
    invoicesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.filter(
      //   (el) => !action.payload.ids.includes(el.id)
      // );
    },
    invoiceFilterSet: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.invoiceFilterState = action.payload.invoiceFilters;
      // state.entities = state.entities.filter(
      //   (el) => !action.payload.ids.includes(el.id)
      // );
    },
    invoiceFilterClear: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.invoiceFilterState = filterInvoiceInitState;
      // state.entities = state.entities.filter(
      //   (el) => !action.payload.ids.includes(el.id)
      // );
    },
    // invoicesUpdateState
    invoicesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
