import React, { useState, useMemo } from "react";
import DeleteIcon from "../../../../assets/img/company/Vector.svg";
import { useProductsUIContext } from "../ProductsUIContext";
import { useDispatch } from "react-redux";
import SubscriptionDialog from "../../company/company-edit-Dialog/SubscriptionDialog";
import { i18n } from "../../private/languageSelector";
import toast from "react-hot-toast";
import * as actions from "../../../../_redux/products/productsActions";
import CustomSearch from "../../../components/searchBar/CustomSearch";

function ProductHeader({ searchValue, setSearchValue }) {
  const [showDeleteProductModal, setShowDeleteProductModal] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const dispatch = useDispatch();
  const productsUIContext = useProductsUIContext();
  const productsUIProps = useMemo(() => {
    return {
      ids: productsUIContext.ids,
      setIds: productsUIContext.setIds,
      queryParams: productsUIContext.queryParams,
      setQueryParams: productsUIContext.setQueryParams,
      openEditProductDialog: productsUIContext.openEditProductDialog,
      openDeleteProductDialog: productsUIContext.openDeleteProductDialog,
      openViewProductDialog: productsUIContext.openViewProductDialog,
      openViewProductActionDialog: productsUIContext.openViewProductActionDialog,
      newProductButtonClick: productsUIContext.newProductButtonClick,
    };
  }, [productsUIContext]);

  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const confirmDelete = () => {
    if (productsUIProps?.ids?.length) {
      dispatch(actions.deleteProducts(productsUIProps?.ids)).then(() => {
        toast.success("Products Deleted Successfully");
        productsUIProps.setIds([]);
      });
    } else {
      toast.error("No Product Selected");
    }
    setShowDeleteProductModal(false);
  };

  return (
    <div className="pl-4 pr-4 py-4 mb-3 d-flex justify-content-between align-items-center">
      <CustomSearch
        handleSearchInputChange={handleSearchInputChange}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setShowSearchInput={setShowSearchInput}
        showSearchInput={showSearchInput}
      />

      <div>
        {productsUIProps.ids?.length > 0 && (
          <span className={` cursor-pointer mr-5`} onClick={() => setShowDeleteProductModal(true)}>
            <img src={DeleteIcon} alt="Delete" />
          </span>
        )}
        <span className="add-text-btn" onClick={productsUIProps.newProductButtonClick}>
          ADD PRODUCT
        </span>
      </div>
      <SubscriptionDialog
        show={showDeleteProductModal}
        onHide={() => setShowDeleteProductModal(false)}
        modalWidth="350px"
        modalHeight="185px"
        message={
          productsUIProps?.ids?.length === 1
            ? "Are you sure you want to delete the selected Product?"
            : "Are you sure you want to delete selected Products?"
        }
        messageWidth="320px"
        messageHeight="44px"
        messageColor="#333333"
        messageFontWeight="400"
        messageFontSize="16px"
        messageLineHeight="21.79px"
        primaryAction={() => setShowDeleteProductModal(false)}
        primaryLabel={i18n("SubscriptionModals.CANCEL")}
        primaryLabelWidth="55px"
        primaryLabelHeight="20px"
        primaryLabelFontWeight="700"
        primaryLabelFontSize="14px"
        primaryLabelLineHeight="20px"
        primaryLabelColor="#828282"
        secondaryAction={confirmDelete}
        secondaryLabel={"DELETE"}
        secondaryLabelWidth="67px"
        secondaryLabelHeight="20px"
        secondaryLabelFontWeight="700"
        secondaryLabelFontSize="14px"
        secondaryLabelLineHeight="20px"
        secondaryLabelColor="#EB5757"
        isCenteredOnMobile
      />
    </div>
  );
}

export default ProductHeader;
