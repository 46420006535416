import axios from "axios";

export const POS_URL = "api/pos";

export function getPosById(posId) {
  return axios.get(`${POS_URL}/${posId}`);
}

export function getAllPos(limit = 10, page = 1, sortOrder = "desc", sortField = "created_at") {
  return axios.get(`${POS_URL}?limit=${limit}&page=${page}&sortOrder=${sortOrder}&sortField=${sortField}`);
}

export function getLatestPosSerial() {
  return axios.get(`${POS_URL}/counts`);
}

export function createPos(pos) {
  return axios.post(`${POS_URL}`, pos);
}

export function updatePos(id, pos) {
  return axios.patch(`${POS_URL}/${id}`, pos);
}

export function deletePos(posId) {
  return axios.delete(`${POS_URL}/${posId}`);
}
