import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PaymentCardIcon from "../../../../assets/img/company/Payment-Card.jpg";
import InvoicePreviewDialog from "./InvoicePreviewDialog";
import { getPaymentCard } from "../../../../_redux/payment/paymentService";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { i18n } from "../../private/languageSelector";

const PricingPlanDialog = ({ showModal, onHide, handleCreateUser }) => {
  const dispatch = useDispatch();
  const { companyForEdit } = useSelector(
    (state) => ({
      companyForEdit: state.company.companyForEdit,
    }),
    shallowEqual
  );

  const { subscriptionData } = useSelector((state) => state.subscription);

  const { language } = useSelector((state) => state.auth);

  useEffect(() => {
    if (subscriptionData?.subscription?.currentPlan?.plan === "Monthly" || subscriptionData?.subscription?.currentPlan.plan === "Annual") {
      setSelectedPlan(subscriptionData.subscription.currentPlan.plan);
    }
  }, [subscriptionData]);

  const { paymentCard, getPaymentCardLoading } = useSelector((state) => state.paymentCard);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showInvoicePreviewModal, setShowInvoicesPreviewModal] = useState(false);

  const handleSelectPlan = (e) => {
    const newSelectedPlan = e.target.value;
    setSelectedPlan(newSelectedPlan);
  };

  useEffect(() => {
    const fetchCard = async () => {
      if (companyForEdit._id) {
        try {
          if (companyForEdit?.paymentCards?.moyasar) {
            await dispatch(getPaymentCard({ companyId: companyForEdit._id }));
          }
        } catch (error) {
          console.error("Error fetching card:", error);
        }
      }
    };
    fetchCard();
  }, [companyForEdit._id, companyForEdit, dispatch]);

  const isPlanChanged = selectedPlan && selectedPlan !== subscriptionData?.subscription?.currentPlan?.plan;

  const handleClosePricingModal = () => {
    onHide();
    setSelectedPlan(subscriptionData?.subscription?.currentPlan?.plan);
  };

  return (
    <>
      <Modal
        dir={language === "arabic" ? "rtl" : "ltr"}
        isOpen={showModal}
        toggle={handleClosePricingModal}
        className="plan-dialog modal-main mx-auto"
        centered
        style={{ width: "300px", height: "300px", borderRadius: "10px" }}
      >
        <ModalHeader
          className="border-0 container-fluid d-flex justify-content-start align-items-start"
          style={{
            backgroundColor: "white",
            borderRadius: "14px",
            color: "black",
            marginLeft: "8px",
            width: "95%",
          }}
        >
          <span
            style={{
              color: "#333333",
              marginRight: "7px",
              marginLeft: "2px",
              fontSize: "16px",
              fontWeight: "700",
            }}
          >
            {i18n("SubscriptionModals.SelectYourPricingPlan")}
          </span>
        </ModalHeader>
        <ModalBody className="d-flex justify-content-start align-items-start flex-column p-4">
          <form className="p-2" style={{ marginRight: "12px" }}>
            <div className="flex flex-col justify-content-start">
              <div className="form-check mb-8">
                <input
                  checked={selectedPlan === "Monthly"}
                  className="form-check-input custom-radio"
                  type="radio"
                  value={"Monthly"}
                  id="Monthly"
                  onChange={handleSelectPlan}
                  style={{
                    width: "17px",
                    height: "17px",
                    cursor: "pointer",
                    marginRight: "-18px",
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor="Monthly"
                  style={{
                    fontSize: "16px",
                    cursor: "pointer",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                >
                  {i18n("SubscriptionModals.19SAR/monthPerUser")}
                </label>
              </div>

              <div className="form-check mb-8">
                <input
                  className="form-check-input"
                  type="radio"
                  value={"Annual"}
                  id="Annual"
                  checked={selectedPlan === "Annual"}
                  onChange={handleSelectPlan}
                  style={{
                    width: "17px",
                    height: "17px",
                    cursor: "pointer",
                    marginRight: "-4px",
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor="Annual"
                  style={{
                    fontSize: "16px",
                    cursor: "pointer",
                    marginRight: "18px",
                    marginLeft: "10px",
                  }}
                >
                  {i18n("SubscriptionModals.90SAR/yearPerUser")}
                </label>
              </div>
            </div>
          </form>
          <div className="d-flex align-items-center mt-3 mr-5" style={{ marginLeft: "10px" }}>
            <img src={PaymentCardIcon} alt="payment card icon" />
            {getPaymentCardLoading ? (
              <span>Loading...</span>
            ) : paymentCard ? (
              <>
                <span className="mr-6 ml-6">
                  {paymentCard?.brand} ****{paymentCard?.last_four}
                </span>
              </>
            ) : (
              <span> {i18n("Company.CreditCardNotYetAdded")}</span>
            )}
          </div>
        </ModalBody>
        <ModalFooter className="cursor-pointer border-0 d-flex mt-2 align-items-center justify-content-around p-3">
          <p
            onClick={handleClosePricingModal}
            style={{
              width: "63px",
              height: "16px",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "16px",
              color: "#828282",
              cursor: "pointer",
            }}
          >
            {i18n("SubscriptionModals.CANCEL")}
          </p>
          <p
            onClick={() => {
              setShowInvoicesPreviewModal(true);
              onHide();
            }}
            style={{
              width: "86px",
              height: "16px",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "16px",
              color: isPlanChanged ? "#2D9CDB" : "#BDBDBD",
              cursor: isPlanChanged ? "pointer" : "not-allowed",
              pointerEvents: !isPlanChanged && "none",
            }}
          >
            {i18n("SubscriptionModals.CONTINUE")}
          </p>
        </ModalFooter>
      </Modal>
      <InvoicePreviewDialog
        selectedPlan={selectedPlan}
        showModal={showInvoicePreviewModal}
        setSelectedPlan={setSelectedPlan}
        onHide={() => setShowInvoicesPreviewModal(false)}
        handleCreateUser={handleCreateUser}
      />
    </>
  );
};

export default PricingPlanDialog;
