import React from "react";

const PrivacyPolicy = () => {
  return (
    <div style={{ backgroundColor: "white", padding: "0 40px" }}>
      <div className="text-center mt-3">
        <h1>Fatoraty </h1>
      </div>
      <div className="ml-3">
        <h1>Privacy Policy</h1>
        <p>
          <strong>Privacy Policy URL:</strong>{" "}
          <a href="https://staging.fatoraty.com/en/privacy-policy" target="_blank">
            https://staging.fatoraty.com/en/privacy-policy
          </a>
        </p>
        <p>
          This Privacy Policy explains how Fatoraty LLC (“Fatoraty”, “us”, “we” or “our”) and its affiliates collect, use, disclose, transfer,
          protect, store, and otherwise process your information when using our Services. Fatoraty LLC is committed to the privacy and secure
          processing of the personal data it maintains for its Customers openly and transparently. It is also committed to the collection and
          processing of personal data in full compliance with the General Regulation on the Protection of Personal Data of the European Union
          (Regulation 2016/679) (hereafter referred to as "the Regulation") and the legislation in force in Cyprus that governs the collection and
          processing of Personal Data of individuals.
        </p>
        <p>This Privacy Policy was updated on 28 November 2023.</p>
        <h2>Definitions</h2>
        <p>
          <strong>‘’Services’’</strong> means Fatoraty point of sale and inventory management products and services, and any features, technologies,
          or functionality provided by those products or services, offered by us from time to time, including the Fatoraty POS, Fatoraty Dashboard
          ("Apps") and fatoraty.com ("Website")
        </p>
        <p>
          <strong>"Customer” or “Merchant’’</strong> means the person or entity who registers to use the Service by creating a Fatoraty account. If
          you are creating an account or using the Services on behalf of a business, you agree that you are accepting these Terms and have the
          authority to enter into these Terms, on behalf of the business, which will be deemed to be the Customer, and will be bound by these Terms.
        </p>
        <p>
          <strong>"You"</strong> means the Customer and (where the context permits) includes any Authorised Users.
        </p>
        <h2>Identifying the Data Controller and Data Processor</h2>
        <p>
          For the purposes of EU Privacy Law, depending on the category of Personal Data described in this Privacy Policy, we are operating as a
          Controller or as a Processor.
        </p>
        <p>
          In general, Fatoraty is the Controller for Customer (Merchant) Information and Processor for Consumer and Employee Information where the
          Controller is the Customer (Merchant).
        </p>
        <p>
          For questions regarding the processing of personal data and the exercise of your rights by the GDPR, you may contact us via the email
          address <a href="mailto:support@fatoraty.com">support@fatoraty.com</a>.
        </p>
        <h2>Types of Personal Data Collected</h2>
        <p>
          We collect and use several types of data for the individuals we cooperate with, including information by which subjects may be identified
          ("Personal Data" means any Data relating to an identified or identifiable natural person ('data subject'); an identifiable natural person
          can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location
          data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social
          identity of that natural person), such as your first and last name, identity number, e-mail address, address and province and telephone
          number.
        </p>
        <p>
          We are committed to protecting the privacy of the individuals on our websites, mobile applications, products, and services. By registering
          for using our Services you acknowledge the collection, transfer, processing, storage, disclosure, and other uses of your information.
        </p>
        <ul>
          <li>
            <strong>Customer information</strong>, being personal information relating to our Customers/Merchants (where applicable). We collect this
            information so that we can provide our Services to those Customers.
          </li>
          <li>
            <strong>Consumer information</strong>, being personal information relating to consumers with whom our Customers interact (such as
            consumers of their products or services) including their email address, phone number, or other information.
          </li>
          <li>
            <strong>Employee information</strong>, being personal information relating to employees of our Customers, including email address, phone
            number, or other information.
          </li>
          <li>
            <strong>Guests</strong>, being personal information relating to the visitors of our webpages and participants at Fatoraty. Town and/or any
            other blogs or interactive platforms we have or may have lunch.
          </li>
        </ul>
        <p>
          Our customers are solely responsible for establishing policies for and ensuring compliance with all applicable laws and regulations, as well
          as all privacy policies, agreements, or other obligations, relating to the collection of personal data in connection with the use of our
          Services by individuals with whom our Customers interact.
        </p>
        <p>
          We collect information under the direction of our Customers and have no direct relationship with individuals whose personal data we process
          in connection with the use of our Services. If you are a Customer providing information (including personal data) about someone else, you
          must have the legal ability to do so and, if necessary, inform them about how their data will be used (as described in this Privacy Policy).
        </p>
        <p>
          If you are an individual who interacts with a Customer using our Services - for instance, if you’re an employee or consumer of a retail
          store – that Customer is the controller of your data and you should contact them directly (e.g. - the owner or manager of the retail store)
          – for assistance with any requests or questions relating to your data.
        </p>
        <p>
          Guests are solely responsible for the personal data they provide on the blogs or other interactive media and that we do not collect for
          providing the blog capability, such as the blog content.
        </p>
        <h2>How and what information do we collect</h2>
        <p>
          We collect information about you when you register for an account, create or modify your profile, set preferences, sign-up for or make
          purchases through the Services (Contact information such as name, email address and country, profile information such as business phone
          number, and preferences information such as notification and marketing preferences).
        </p>
        <p>
          <strong>Operational data.</strong> We store the information you upload to or send through our Services, ( Information about products and
          services the Customer sells (including inventory, pricing, sale, transaction, tax, and other data and Information about the Customer or the
          Customer’s business (employees, consumers, and suppliers).
        </p>
        <p>
          <strong>Information for Support.</strong> When you contact our customer support, where you may choose to submit information regarding a
          problem you are experiencing with a Service. (contact information, problem summary, any documentation, screenshots, or information that
          would help resolve the issue).
        </p>
        <p>
          <strong>Payment Information.</strong> We collect certain payment and billing information when you subscribe to certain paid Services. You
          might also provide payment information, such as payment card details, which we collect via secure payment processing services.
        </p>
        <p>
          <strong>Contribution to a discussion.</strong> You might contribute to an online discussion at Fatoraty . Town or any other interactive
          media or blog about the Service we are providing to you. The content of the discussion might include personal data you input.
        </p>
        <p>
          <strong>Other submissions.</strong> We ask your consent to collect personal information from you when you submit web forms on our websites
          or when you participate in any interactive features of our Services, participate in a survey, promotion, activity, or event, request
          customer support, communicate with us via third party social media sites, or otherwise communicate with us.
        </p>
        <h3>Location Information and Other Services We Collect Automatically When You Use Our Services:</h3>
        <p>
          <strong>Access log:</strong> We gather certain information and store it in log files when you interact with our websites and Services. This
          information includes Internet protocol (IP) addresses as well as browser type, URLs of referring/exit pages, operating system, language and
          location preferences, time/date stamp, search history, device identification numbers, and system configuration information. Occasionally, we
          connect personal information to information gathered in our log files as is necessary to improve our Services. If we do this, we will treat
          the combined information by this Privacy Policy.
        </p>
        <p>
          <strong>Cookies:</strong> Cookies are used by our organization and by our third-party partners (for example, our analytics partners) to
          collect information. A cookie is a small text file that is stored on your hard drive or in device memory for record-keeping purposes. We use
          cookies to give you better and more personalized use of the Services, to save you from logging in every time, and to count the number of
          visits. Please refer to our Cookies Policy for more information.
        </p>
        <h3>Information We Receive from Other Sources</h3>
        <p>
          We collect information from other sources, such as our business partners. We do not control, supervise, or respond to how the third parties
          providing your information process your data, and any information request regarding the disclosure of your personal information to us should
          be directed to such third parties.
        </p>
        <h2>Legal Basis for processing</h2>
        <p>
          We collect and process information about you only where we have legal bases for doing so under applicable EU laws. The legal bases depend on
          the Services you use and how you use them. This means we collect and use your information only where:
        </p>
        <ul>
          <li>
            It is necessary to provide you the Services, including operating the Services, providing customer support and personalized features, and
            to protect the safety and security of the Services.
          </li>
          <li>
            It satisfies a legitimate interest (which is not overridden by your data protection interests), such as for research and development, to
            market and promote the Services, and to protect our legal rights and interests.
          </li>
          <li>We need to process your data to comply with a legal obligation; or</li>
          <li>You give us consent to do so for a specific purpose</li>
        </ul>
        <p>
          If you have consented to our use of information about you for a specific purpose, you have the right to change your mind at any time, but
          this will not affect any processing that has already taken place.
        </p>
        <h2>How we use your personal information</h2>
        <p>We are continually striving to improve Services. We may use information about you for purposes such as:</p>
        <ul>
          <li>Location permission is needed to enable efficient discovery of Bluetooth Printers</li>
          <li>Enabling you to access and use our Services</li>
          <li>Displaying historical sale information</li>
          <li>
            Sending you marketing, advertising, educational content and promotional messages, and other information that may be of interest to you,
            including information about us, our Services, or general promotions for business partner campaigns and services and only if you consented
            to. You can unsubscribe or opt out from receiving these communications at any time
          </li>
          <li>Measuring, customizing, and improving the Services and developing new products</li>
          <li>
            Sending to you service, support, and administrative messages, reminders, technical notices, updates, security alerts, and information
            requested by you
          </li>
          <li>Investigating and preventing fraudulent transactions, unauthorized access to Services, and other illegal activities</li>
          <li>Providing the capability for online discussion with other customers and/or users.</li>
          <li>
            With your consent, we may use information about you for a specific purpose not listed above. For example, we may publish testimonials or
            featured customer stories to promote the Services, with your permission.
          </li>
        </ul>
        <h2>Sharing & disclosure of your information</h2>
        <p>When you use the Services, we may share your information only as described below:</p>
        <h3>Service Providers, Business Partners, and Others</h3>
        <p>
          We use and work with third-party service providers and our trusted Business Partners to provide application development, hosting, website,
          infrastructure, maintenance, backup, payment processing, customer relationship management, marketing, accounting, human resources, business
          intelligence and analytics, data enrichment, customer support and other services for us. These service providers may have access to or
          process your information to provide those services for us. Some of our pages use white-labeling techniques to serve content from our service
          providers while providing the look and feel of our site. Please note that you are providing your information to these third parties acting
          on our behalf. These third parties are located in countries that may be outside of your location.
        </p>
        <p>
          We also share your contact information with select trusted Business Partners, such as our partners who integrate with Services, to enable
          them to contact you about their services (as they relate to your Services).
        </p>
        <h3>Compliance with Laws and Protection of Rights</h3>
        <p>
          In certain situations, we may be required to use and disclose your information (including personal information) to a third party if we
          believe the disclosure is reasonably necessary:
        </p>
        <ul>
          <li>To comply with any applicable law, legal process (for example, subpoenas and warrants), or governmental request</li>
          <li>To enforce and administer our agreements, policies, and terms of use</li>
          <li>To protect the property, rights, and safety of, our Customers or the public from harm or illegal activities</li>
          <li>For fraud prevention, risk assessment, investigation, customer support, product development, or debugging purposes</li>
          <li>To protect the rights, property, or our safety, its users or members of the public</li>
          <li>To establish or exercise our legal rights or defend ourselves against any third-party claims or allegations</li>
        </ul>
        <p>
          <strong>Payment Information.</strong> We collect certain payment and billing information when you subscribe to certain paid Services. You
          might also provide payment information, such as payment card details, which we collect via secure payment processing services.
        </p>
        <p>
          <strong>Contribution to a discussion.</strong> You might contribute to an online discussion at Fatoraty . Town or any other interactive
          media or blog about the Service we are providing to you. The content of the discussion might include personal data you input.
        </p>
        <p>
          <strong>Other submissions.</strong> We ask your consent to collect personal information from you when you submit web forms on our websites
          or when you participate in any interactive features of our Services, participate in a survey, promotion, activity, or event, request
          customer support, communicate with us via third party social media sites, or otherwise communicate with us.
        </p>
        <h3>Location Information and Other Services We Collect Automatically When You Use Our Services:</h3>
        <p>
          <strong>Access log:</strong> We gather certain information and store it in log files when you interact with our websites and Services. This
          information includes Internet protocol (IP) addresses as well as browser type, URLs of referring/exit pages, operating system, language and
          location preferences, time/date stamp, search history, device identification numbers, and system configuration information. Occasionally, we
          connect personal information to information gathered in our log files as is necessary to improve our Services. If we do this, we will treat
          the combined information by this Privacy Policy.
        </p>
        <p>
          <strong>Cookies:</strong> Cookies are used by our organization and by our third-party partners (for example, our analytics partners) to
          collect information. A cookie is a small text file that is stored on your hard drive or in device memory for record-keeping purposes. We use
          cookies to give you better and more personalized use of the Services, to save you from logging in every time, and to count the number of
          visits. Please refer to our Cookies Policy for more information.
        </p>
        <h3>Information We Receive from Other Sources</h3>
        <p>
          We collect information from other sources, such as our business partners. We do not control, supervise, or respond to how the third parties
          providing your information process your data, and any information request regarding the disclosure of your personal information to us should
          be directed to such third parties.
        </p>
        <h2>Legal Basis for processing</h2>
        <p>
          We collect and process information about you only where we have legal bases for doing so under applicable EU laws. The legal bases depend on
          the Services you use and how you use them. This means we collect and use your information only where:
        </p>
        <ul>
          <li>
            It is necessary to provide you the Services, including operating the Services, providing customer support and personalized features, and
            to protect the safety and security of the Services.
          </li>
          <li>
            It satisfies a legitimate interest (which is not overridden by your data protection interests), such as for research and development, to
            market and promote the Services, and to protect our legal rights and interests.
          </li>
          <li>We need to process your data to comply with a legal obligation; or</li>
          <li>You give us consent to do so for a specific purpose</li>
        </ul>
        <p>
          If you have consented to our use of information about you for a specific purpose, you have the right to change your mind at any time, but
          this will not affect any processing that has already taken place.
        </p>
        <h2>How we use your personal information</h2>
        <p>We are continually striving to improve Services. We may use information about you for purposes such as:</p>
        <ul>
          <li>Location permission is needed to enable efficient discovery of Bluetooth Printers</li>
          <li>Enabling you to access and use our Services</li>
          <li>Displaying historical sale information</li>
          <li>
            Sending you marketing, advertising, educational content and promotional messages, and other information that may be of interest to you,
            including information about us, our Services, or general promotions for business partner campaigns and services and only if you consented
            to. You can unsubscribe or opt out from receiving these communications at any time
          </li>
          <li>Measuring, customizing, and improving the Services and developing new products</li>
          <li>
            Sending to you service, support, and administrative messages, reminders, technical notices, updates, security alerts, and information
            requested by you
          </li>
          <li>Investigating and preventing fraudulent transactions, unauthorized access to Services, and other illegal activities</li>
          <li>Providing the capability for online discussion with other customers and/or users.</li>
          <li>
            With your consent, we may use information about you for a specific purpose not listed above. For example, we may publish testimonials or
            featured customer stories to promote the Services, with your permission.
          </li>
        </ul>
        <h2>Sharing & disclosure of your information</h2>
        <p>When you use the Services, we may share your information only as described below:</p>
        <h3>Service Providers, Business Partners, and Others</h3>
        <p>
          We use and work with third-party service providers and our trusted Business Partners to provide application development, hosting, website,
          infrastructure, maintenance, backup, payment processing, customer relationship management, marketing, accounting, human resources, business
          intelligence and analytics, data enrichment, customer support and other services for us. These service providers may have access to or
          process your information to provide those services for us. Some of our pages use white-labeling techniques to serve content from our service
          providers while providing the look and feel of our site. Please note that you are providing your information to these third parties acting
          on our behalf. These third parties are located in countries that may be outside of your location.
        </p>
        <p>
          We also share your contact information with select trusted Business Partners, such as our partners who integrate with Services, to enable
          them to contact you about their services (as they relate to your Services).
        </p>
        <h3>Compliance with Laws and Protection of Rights</h3>
        <p>
          In certain situations, we may be required to use and disclose your information (including personal information) to a third party if we
          believe the disclosure is reasonably necessary:
        </p>
        <ul>
          <li>To comply with any applicable law, legal process (for example, subpoenas and warrants), or governmental request</li>
          <li>To enforce and administer our agreements, policies, and terms of use</li>
          <li>To protect the property, rights, and safety of, our Customers or the public from harm or illegal activities</li>
          <li>For fraud prevention, risk assessment, investigation, customer support, product development, or debugging purposes</li>
          <li>To protect the rights, property, or our safety, its users or members of the public</li>
          <li>To establish or exercise our legal rights or defend ourselves against any third-party claims or allegations</li>
        </ul>
        less Copy code
        <h3>Business Transfers</h3>
        <p>
          If we undertake or are involved in any merger, acquisition, reorganization, sale of assets, or bankruptcy, or insolvency event, then we may
          transfer or share some or all our assets, including your personal information. In this event, we will notify you if your personal
          information is transferred and becomes subject to a different privacy policy.
        </p>
        <h3>Non-Identifying or Aggregated Data</h3>
        <p>
          We may share aggregated or other non-personal information that does not directly identify you with third parties to improve our Services.
        </p>
        <p>
          We require all third parties to respect the security of your data and to treat it in accordance with the law. We do not allow our
          third-party service providers to use your personal data for their own purposes and, unless otherwise notified to you, only permit them to
          process your personal data for specified purposes and in accordance with our instructions.
        </p>
        <h2>Your privacy rights</h2>
        <p>
          Subject to the provisions of the General Data Protection Regulation, you have the following rights in regard to your Personal Data: (Please
          note, these rights are not absolute and, in some cases, they are subjected to conditions as defined by law)
        </p>
        <ul>
          <li>
            <strong>Right of Access:</strong> You have the right to access your own Personal Information through the platform, as well as the right to
            request a copy of your personal information that is maintained and processed by us.
          </li>
          <li>
            <strong>Right to Erasure:</strong> You have the right to request the deletion of Personal Data only if one of the following reasons is
            true:
            <ol>
              <li>Personal Data are no longer necessary in relation to the purposes for which they were collected or processed.</li>
              <li>
                If the processing is based on your consent and you have withdrawn this consent (on which processing is based) in accordance with
                Articles 6.1.a and 9.2.a of the Regulation and if no other legal basis for processing applies.
              </li>
              <li>
                If you object to processing in accordance with Article 21.1 of the Regulation and there are no compelling and legitimate reasons for
                processing.
              </li>
              <li>If Personal Data has been processed illegally.</li>
              <li>If Personal Data should be deleted in compliance with a legal obligation under Union law to which our company is subject to.</li>
              <li>If the Personal Data have been collected in relation to the provision referred to in Article 8.1 of the Regulation.</li>
            </ol>
          </li>
          <li>
            <strong>Right to Object:</strong> Object to the processing of your Personal Data for marketing purposes or on grounds relating to your
            situation.
          </li>
          <li>
            <strong>Right to Restriction of Processing:</strong> Request the restriction of the processing of your Personal Data in specific cases.
          </li>
          <li>
            <strong>Right to Data Portability:</strong> Receive your Personal Data in a machine-readable format and send it to another controller
            (data portability);
          </li>
          <li>
            <strong>Right to Object and Automated Individual Decision-Making (Including Profiling):</strong> Request that decisions based on automated
            processing concerning you or significantly affecting you and based on your Personal Data are made by natural persons, not only by
            computers. You also have the right in this case to express your point of view and to contest the decision.
          </li>
        </ul>
        <p>
          If you are an individual who interacts with a Customer using our Services - for instance if you’re an employee or consumer – that Customer
          is the controller of your information. If this is the case, please direct your data privacy request and questions to that controller,
        </p>
        <p>
          You can request to exercise these rights by emailing <a href="mailto:support@fatoraty.com">support@fatoraty.com</a>.
        </p>
        <h2>Our global operations (including transfers of data from your home country to another)</h2>
        <p>
          To bring you Services, we operate globally. To do so, your personal information may be transferred to, and processed in countries other than
          the country you live in, outside of your home country, including the United States. These countries may have laws different from what you’re
          used to. Rest assured, where we disclose personal data to a third party in another country, we put safeguards in place to ensure your data
          remains protected.
        </p>
        <p>
          Specifically, Fatoraty hosts data with Amazon Web Services in Germany. If you are a non-EU resident, this means that your personal
          information will be transferred to the EU. The servers on which personal information is stored are kept in a controlled environment.
        </p>
        <p>
          European Economic Area (EEA) users: This means that your information may be transferred outside of the EEA. Where your personal information
          is transferred outside the EEA, it will only be transferred to countries that have been deemed to provide adequate protection for EEA
          information (Adequacy Decision Countries), or to a third party where we have approved transfer mechanisms in place to protect your personal
          information – i.e., European Commission’s Standard Contractual Clauses.
        </p>
        <h2>How long we retain your information</h2>
        <p>We generally retain your information only as long as reasonably necessary to provide you the Services or to comply with applicable law.</p>
        <p>
          However, even after you cancel your account, we can retain copies of information about you and any transactions or Services in which you may
          have participated for a period that is consistent with applicable law, the applicable statute of limitations, or as we believe is reasonably
          necessary to comply with applicable law, regulation, legal process, or governmental request, to resolve disputes, to address problems with
          our Services, to assist with investigations, to enforce our Terms of use or other applicable agreements or policies, or to take any other
          actions consistent with applicable law.
        </p>
        <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it.</p>
        <h2>Children’s use of the services</h2>
        <p>
          Services are not directed to individuals under 16. We do not knowingly collect personal information from children under 16. If we become
          aware that a child under 16 has provided us with personal information, we will take steps to delete such information. If you know that a
          child has provided us with personal information, please contact us at <a href="mailto:support@fatoraty.com">support@fatoraty.com</a>.
        </p>
        <h2>Security</h2>
        <p>
          We take appropriate security technical and organizational measures (including physical, electronic, and procedural measures) to safeguard
          your Personal Information from unauthorized access, unlawful use, intervention, modification, or disclosure under the requirements of the
          Regulation. Where data is transferred over the Internet as part of the Services, the data is encrypted using industry-standard TLS (HTTPS).
        </p>
        <h1>Updates to our privacy policy</h1>
        <p>
          We reserve the right to change this Privacy Policy from time to time, and if we do we will post any changes on this webpage. If you disagree
          with these changes, you can cancel your account at any time and/or stop your use of our Services. Your continued use of the Services after
          any changes to the Privacy Policy constitutes your acceptance of such changes.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
