import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import { i18n } from "../../../private/languageSelector";
import { SwitchToggler } from "../../../../../_metronic/_partials/controls";
import { Radio } from "../../../../../_metronic/_partials/controls/forms/Radio";
import IconClose from "../../../../../assets/img/icon-close.svg";
import { EditPaymentTypes } from "./EditPaymentsTypes";
import { PAYMENT_TYPES } from "../../../../../helpers/constants";
import cloneDeep from "lodash/cloneDeep";
import toast from "react-hot-toast";

export const ManagePaymentTypes = ({ userAccess, savePayments, companyForEdit, showManagePayment, setShowManagePayments }) => {
  const [showEditPayment, setShowEditPayments] = useState(false);
  const [paymentTypesToShow, setPaymentTypesToShow] = useState([]);

  useEffect(() => {
    if (companyForEdit?.paymentTypes?.length) {
      setPaymentTypesToShow(cloneDeep([...companyForEdit?.paymentTypes]));
    } else {
      setPaymentTypesToShow(cloneDeep([...PAYMENT_TYPES]));
    }
  }, [companyForEdit]);

  const defaultTypeHandler = (item) => {
    const updatedPaymentTypes = paymentTypesToShow.map((paymentType) => ({
      ...paymentType,
      paymentTypeDefault: paymentType.businessId === item.businessId,
    }));
    setPaymentTypesToShow(updatedPaymentTypes);
  };

  const paymentStatusHandler = (businessId, e) => {
    const updatedPaymentTypes = paymentTypesToShow.map((paymentType) => {
      if (paymentType.businessId === businessId) {
        return { ...paymentType, active: e.target.checked };
      }
      return paymentType;
    });
    setPaymentTypesToShow(updatedPaymentTypes);
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={companyForEdit}
        onSubmit={(values) => {
          savePayments({ _id: values._id, paymentTypes: paymentTypesToShow });
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Modal size="md" className="modal-drawer" show={showManagePayment} onHide={() => {}} aria-labelledby="example-modal-sizes-title-lg">
              <Modal.Header>
                <button type="button" className="btn btn-close" onClick={() => setShowManagePayments(false)}>
                  <img src={IconClose} alt="loading" />
                </button>
                <Modal.Title>{i18n("Company.ManagePaymentChannel")}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="overlay overlay-block cursor-default">
                <Form className="form form-label-right">
                  <div>
                    <div className="payment-types f-font">
                      <div>{i18n("global.Default")}</div>
                      <div>{i18n("INVOICES.PaymentChannel")}</div>
                      <div>{i18n("global.Status")}</div>
                    </div>

                    {paymentTypesToShow?.length
                      ? paymentTypesToShow
                          .filter((item) => !item.isDeleted)
                          .map((item) => (
                            <div key={item.businessId} className="row mt-7">
                              <div className="col-3 ml-2">
                                <Radio
                                  label
                                  id={item.name}
                                  name="default"
                                  isSelected={item.paymentTypeDefault}
                                  onChange={() => (userAccess?.editData ? defaultTypeHandler(item) : toast.error("Access Denied"))}
                                />
                              </div>
                              <h6 className="mt-4 col-7">{item.name}</h6>
                              <div className="col-1" style={{ marginTop: "-7px" }}>
                                <SwitchToggler
                                  className="default-switch"
                                  id={item.businessId}
                                  name={item.name}
                                  value={item.active}
                                  checked={item.active || false}
                                  disabled={item.name === "Cash"}
                                  onChange={(e) => (userAccess?.editData ? paymentStatusHandler(item.businessId, e) : toast.error("Access Denied"))}
                                />
                              </div>
                            </div>
                          ))
                      : ""}
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button type="button" className="btn btn-outline-primary btn-elevate" onClick={() => setShowEditPayments(true)}>
                  {i18n("Customer.Edit")}
                </button>
                <button
                  type="submit"
                  onClick={() => {
                    handleSubmit();
                    setShowManagePayments(false);
                  }}
                  className="btn btn-primary btn-elevate ml-5"
                >
                  {i18n("Company.Save")}
                </button>
              </Modal.Footer>
            </Modal>
            <EditPaymentTypes
              userAccess={userAccess}
              savePayments={savePayments}
              companyForEdit={companyForEdit}
              showEditPayment={showEditPayment}
              setShowEditPayments={setShowEditPayments}
            />
          </>
        )}
      </Formik>
    </>
  );
};
