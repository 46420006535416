import moment from "moment";
import { i18n } from "../app/pages/private/languageSelector";

export const getNumber = (num) => {
  let str = "" + num;
  let pad = "00000";
  let ans = pad.substring(0, pad.length - str.length) + str;
  return ans;
};

export const transformColumnName = (name) => {
  if (name === "grossSales") {
    return i18n("DASHBOARD.GrossSales");
  } else if (name === "refunds") {
    return i18n("INVOICES.Refunds");
  } else if (name === "netSales") {
    return i18n("DASHBOARD.NetSales");
  } else if (name === "discountTotal" || name === "discounts") {
    return i18n("LandingPage.Discounts");
  } else if (name === "tax") {
    return i18n("DASHBOARD.Taxes");
  } else if (name === "costOfGoods") {
    return i18n("DASHBOARD.Cost");
  } else if (name === "grossProfit") {
    return i18n("DASHBOARD.GrossProfit");
  } else if (name === "margin") {
    return i18n("DASHBOARD.Margin");
  } else if (name === "date") {
    return i18n("Form.Date");
  } else if (name === "hour") {
    return i18n("DASHBOARD.Hour");
  } else if (name === "productsSold") {
    return i18n("DASHBOARD.ProductSold");
  } else if (name === "sku") {
    return i18n("DASHBOARD.SKU");
  } else if (name === "productsRefunded") {
    return i18n("DASHBOARD.ProductRefunded");
  } else if (name === "categoryName") {
    return i18n("DASHBOARD.Category");
  } else if (name === "productName") {
    return i18n("DASHBOARD.Product");
  } else if (name === "userName") {
    return i18n("DASHBOARD.UserName");
  } else if (name === "saleInvoices") {
    return i18n("DASHBOARD.SaleInvoices");
  } else if (name === "refundInvoices") {
    return i18n("DASHBOARD.RefundInvoices");
  } else if (name === "netSalesAverage") {
    return i18n("DASHBOARD.NetSalesAverage");
  } else if (name === "customersSignedUp") {
    return i18n("DASHBOARD.CustomersSignedUp");
  } else if (name === "paymentChannel") {
    return i18n("DASHBOARD.PaymentChannel");
  } else if (name === "paymentTransactions") {
    return i18n("DASHBOARD.PaymentTransactions");
  } else if (name === "paymentAmount") {
    return i18n("DASHBOARD.PaymentAmount");
  } else if (name === "refundTransactions") {
    return i18n("DASHBOARD.RefundTransactions");
  } else if (name === "refundedAmount") {
    return i18n("DASHBOARD.RefundedAmount");
  } else if (name === "netAmount") {
    return i18n("DASHBOARD.NetAmount");
  } else if (name === "discountName") {
    return i18n("DASHBOARD.DiscountName");
  } else if (name === "discountRate") {
    return i18n("DASHBOARD.DiscountRate");
  } else if (name === "discountsApplied") {
    return i18n("DASHBOARD.DiscountsApplied");
  } else if (name === "amountDiscounted") {
    return i18n("DASHBOARD.AmountDiscounted");
  } else if (name === "taxName") {
    return i18n("DASHBOARD.TaxName");
  } else if (name === "taxRate") {
    return i18n("DASHBOARD.TaxRate");
  } else if (name === "taxableSales") {
    return i18n("DASHBOARD.TaxableSales");
  } else if (name === "taxAmount") {
    return i18n("DASHBOARD.TaxAmount");
  } else if (name === "time") {
    return i18n("DASHBOARD.Time");
  } else if (name === "grossSalesAvg") {
    return i18n("DASHBOARD.GrossSalesAvg");
  } else if (name === "refundsAvg") {
    return i18n("DASHBOARD.RefundsAvg");
  } else if (name === "discountsAvg") {
    return i18n("DASHBOARD.DiscountsAvg");
  } else if (name === "netSalesAvg") {
    return i18n("DASHBOARD.NetSalesAvg");
  } else return name;
  // return name
  //   .replace(/([A-Z])/g, " $1")
  //   .replace(/^./, (str) => str.toUpperCase())
  //   .trim();
};

export const formatValue = (value) => {
  if (typeof value !== "number") return value; // Return as is if not a number
  return value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const generateHourLabels = () => {
  return Array.from({ length: 24 }, (_, i) => {
    const hour = i.toString().padStart(2, "0");
    return `${hour}:00`;
  });
};

export const renderCellValue = (value) => {
  if (value === undefined || value === null) {
    return ""; // Handle undefined or null values
  }
  if (typeof value === "object") {
    if (value.first && value.last) {
      // Handle objects with 'first' and 'last' properties
      return `${value.first} ${value.last}`;
    }
    // Handle other objects (e.g., convert to string)
    return Object.keys(value)
      .map((key) => `${key}: ${value[key]}`)
      .join(", ");
  }
  return value;
};

export function numberWithCommas(x) {
  if (x === null || x === undefined) {
    return "";
  }
  return x.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatNumber(value) {
  // Check if the value is a number
  if (typeof value === "number" && !isNaN(value)) {
    // Format the number to two decimal places and add commas
    return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else if (value instanceof Date) {
    // Format the date as desired
    return value.toLocaleDateString("en-US"); // Example format, adjust as needed
  } else {
    // Handle other cases (e.g., return a placeholder or the original value)
    return value;
  }
}

export const getSkuNumber = (product) => {
  let value;
  product.sort((a, b) => {
    return a.sku - b.sku;
  });
  if (product?.length == 0) {
    value = (0 + 1).toString().padStart(4, "0");
  } else {
    for (let i = 1; i <= product.length; i++) {
      if (product[i - 1].sku !== i) {
        value = i.toString().padStart(4, "0");
        break;
      } else {
        value = (i + 1).toString().padStart(4, "0");
      }
    }
  }
  return value;
};

export function incrementCharacter(string) {
  function getValue(s) {
    return s?.split("").reduce((r, a) => r * 26 + parseInt(a, 36) - 9, 0) - 1;
  }
  function setValue(n) {
    var result = "";
    do {
      result = ((n % 26) + 10).toString(36) + result;
      n = Math.floor(n / 26) - 1;
    } while (n >= 0);
    return result.toUpperCase();
  }

  return setValue(getValue(string) + 1);
}

export const refactorTimeRangeLabel = (label) => {
  if (label === "hourly") {
    return i18n("LandingPage.Hourly");
  } else if (label === "yearly") {
    return i18n("LandingPage.Yearly");
  } else if (label === "quarterly") {
    return i18n("LandingPage.Quarterly");
  } else if (label === "monthly") {
    return i18n("LandingPage.Monthly");
  } else if (label === "daily") {
    return i18n("LandingPage.Daily");
  } else if (label === "weekly") {
    return i18n("LandingPage.Weekly");
  }
};

export const translateModuleName = (name) => {
  if (name === "Sales by Summary") {
    return i18n("LandingPage.SalesSummary");
  } else if (name === "Sales by Product") {
    return i18n("LandingPage.SalesByProduct");
  } else if (name === "Sales by Category") {
    return i18n("LandingPage.SalesByCategory");
  } else if (name === "Sales by User") {
    return i18n("LandingPage.SalesByUser");
  } else if (name === "Sales by Payment Channel") {
    return i18n("LandingPage.SalesByPaymentChanel");
  } else if (name === "Sales by Time") {
    return i18n("LandingPage.SalesByTime");
  } else if (name === "Taxes") {
    return i18n("LandingPage.Taxes");
  } else if (name === "Discounts") {
    return i18n("LandingPage.Discounts");
  } else if (name === "Categories") {
    return i18n("global.Categories");
  } else if (name === "Products") {
    return i18n("Company.Products");
  } else if (name === "Customers") {
    return i18n("Company.Customers");
  } else if (name === "Payment Channels") {
    return i18n("global.PaymentChannels");
  } else if (name === "Stores") {
    return i18n("Company.Stores");
  } else if (name === "Pos Devices") {
    return i18n("Company.PosDevice");
  } else if (name === "Users") {
    return i18n("Company.Users");
  } else if (name === "Roles") {
    return i18n("Company.Roles");
  } else if (name === "Invoices") {
    return i18n("DASHBOARD.Invoices");
  }
};
export const translateDate = (startDate, language) => {
  let date = moment(startDate).format("MMM/D/YYYY");
  if (moment(date).isValid() && language === "arabic") {
    let firstItem = date?.split("/")[0];
    let month;
    if (firstItem === "Sep") {
      month = i18n("Form.Sep");
    } else if (firstItem === "Oct") {
      month = i18n("Form.Oct");
    } else if (firstItem === "Nov") {
      month = i18n("Form.Nov");
    } else if (firstItem === "Dec") {
      month = i18n("Form.Dec");
    } else if (firstItem === "Jan") {
      month = i18n("Form.Jan");
    } else if (firstItem === "Feb") {
      month = i18n("Form.Feb");
    } else if (firstItem === "Mar") {
      month = i18n("Form.Mar");
    } else if (firstItem === "Apr") {
      month = i18n("Form.Apr");
    } else if (firstItem === "May") {
      month = i18n("Form.May");
    } else if (firstItem === "Jun") {
      month = i18n("Form.Jun");
    } else if (firstItem === "Jul") {
      month = i18n("Form.Jul");
    } else if (firstItem === "Aug") {
      month = i18n("Form.Aug");
    }
    return moment(startDate).format(`${month} D, YYYY`);
  }
  return moment(startDate).format("MMM D, YYYY");
};

export const translateHeaderLabel = (headerLabel) => {
  if (headerLabel === "Today" || headerLabel === "اليوم") {
    headerLabel = i18n("DASHBOARD.Today");
  } else if (headerLabel === "Yesterday" || headerLabel === "أمس") {
    headerLabel = i18n("DASHBOARD.Yesterday");
  } else if (headerLabel === "This Week" || headerLabel === "هذا الأسبوع") {
    headerLabel = i18n("DASHBOARD.ThisWeek");
  } else if (headerLabel === "This Month" || headerLabel === "هذا الشهر") {
    headerLabel = i18n("DASHBOARD.ThisMonth");
  } else if (headerLabel === "Last Month" || headerLabel === "آخر شهر") {
    headerLabel = i18n("DASHBOARD.LastMonth");
  } else if (headerLabel === "Last Week" || headerLabel === "آخر أسبوع") {
    headerLabel = i18n("DASHBOARD.LastWeek");
  } else if (headerLabel === "Last 7 Days" || headerLabel === "اخر 7 ايام") {
    headerLabel = i18n("DASHBOARD.Last7Days");
  } else if (headerLabel === "Last 30 Days" || headerLabel === "آخر 30 يومًا") {
    headerLabel = i18n("DASHBOARD.Last30Days");
  } else if (headerLabel === "Custom Range" || headerLabel === "نطاق مخصص") {
    headerLabel = i18n("DASHBOARD.CustomRange");
  }
  return headerLabel;
};

export const translateMonthsFromCharts = (item) => {
  if (!item?.includes(":00")) {
    let month = item?.slice(item?.length - 3, item?.length);
    let date = item?.slice(0, item?.indexOf(month));

    if (month === "Jan") {
      return i18n("DASHBOARD.Jan") + date;
    } else if (month === "Feb") {
      return i18n("DASHBOARD.Feb") + date;
    } else if (month === "Mar") {
      return i18n("DASHBOARD.Mar") + date;
    } else if (month === "Apr") {
      return i18n("DASHBOARD.Apr") + date;
    } else if (month === "May") {
      return i18n("DASHBOARD.May") + date;
    } else if (month === "Jun") {
      return i18n("DASHBOARD.Jun") + date;
    } else if (month === "Jul") {
      return i18n("DASHBOARD.Jul") + date;
    } else if (month === "Aug") {
      return i18n("DASHBOARD.Aug") + date;
    } else if (month === "Sep") {
      return i18n("DASHBOARD.Sep") + date;
    } else if (month === "Oct") {
      return i18n("DASHBOARD.Oct") + date;
    } else if (month === "Nov") {
      return i18n("DASHBOARD.Nov") + date;
    } else if (month === "Dec") {
      return i18n("DASHBOARD.Dec") + date;
    } else return month + date;
  } else {
    return item;
  }
};

export function getModalWidth() {
  if (window.innerWidth > 450) {
    return "400px"; // Fixed width for larger devices
  } else {
    return "calc(100% - 30px)"; // Dynamic width for smaller devices
  }
}


export const refactorValues = (values) => {
  let refactoredValues = {};
  if (values?.invoiceLanguage?.value === "en") {
    refactoredValues["total"] = "SAR 00.00";
    refactoredValues["totalText"] = "Total";
    refactoredValues["totalTextSec"] = "Total";
    refactoredValues["invoiceNo"] = "invoice No.";
    refactoredValues["date"] = "Date:";
    refactoredValues["cashier"] = "Cashier:";
    refactoredValues["pos"] = "POS:";
    refactoredValues["taxNo"] = "Tax No.";
    refactoredValues["simplifiedInvoice"] = "Simplified tax invoice";
    refactoredValues["cash"] = "Cash";
    refactoredValues["change"] = "Change";
  } else {
    refactoredValues["total"] = "00.00 ر.س ";
    refactoredValues["totalText"] = " الإجمالي ";
    refactoredValues["totalTextSec"] = " المجموع ";
    refactoredValues["invoiceNo"] = "رقم الفاتورة";
    refactoredValues["date"] = "تاريخ";
    refactoredValues["cashier"] = "الكاشير";
    refactoredValues["pos"] = "نقطة البيع";
    refactoredValues["taxNo"] = "الرقم الضريبي ";
    refactoredValues["simplifiedInvoice"] = " فاتورة ضريبية مبسطة";
    refactoredValues["cash"] = "نقداً";
    refactoredValues["change"] = "المتبقي";

  }
  return refactoredValues;
};