import React, { useState, useEffect } from "react";
import "./analytics.css";
import SideCard from "../../../components/Card/SideCard";
import Table from "../../../components/Table/Table";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getTableData } from "../../../../_redux/analytics/analyticsService";
import PageNavigator from "../../../components/pagination/PageNavigator";
import { formatValue } from "../../../../helpers/helpers";
import { exportTableToPDF } from "../../../../utils/pdfExportUtil";
import { i18n } from "../../private/languageSelector";
import FiltersDialog from "../filters-dialog/FiltersDialog";

const SalesByUser = () => {
  const [allStores, setAllStores] = useState("all");
  const [allUsers, setAllUsers] = useState("all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [tableType, setTableType] = useState("salesByUser");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [timeZone, setTimeZone] = useState("Asia/Riyadh");

  const dispatch = useDispatch();
  const { analyticsTableData, openFiltersDialog } = useSelector((state) => state.analytics);

  useEffect(() => {
    setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const formattedStartDate = startDate ? moment(new Date(startDate)).format("M-DD-yy") : "";
      const formattedEndDate = endDate ? moment(new Date(endDate)).format("M-DD-yy") : "";
      await dispatch(
        getTableData({
          tableType,
          page,
          limit,
          allStores,
          allUsers,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          sortBy,
          sortOrder,
          timeZone,
        })
      );
    };
    if (startDate && endDate) {
      fetchData();
    }
  }, [page, limit, allStores, allUsers, startDate, endDate, tableType, sortBy, sortOrder]);

  const formattedData = analyticsTableData.map((row) => ({
    ...row,
    grossSales: formatValue(row.grossSales),
    refunds: formatValue(row.refunds),
    discounts: formatValue(row.discounts),
    netSales: formatValue(parseFloat(row.netSales)),
    netSalesAverage: formatValue(parseFloat(row.netSalesAverage)),
  }));

  const handleSelectStores = (storeIds) => {
    setAllStores(storeIds);
  };

  const handleSelectUsers = (userIds) => {
    setAllUsers(userIds);
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };
  const columns = [
    "userName",
    "grossSales",
    "refunds",
    "discounts",
    "netSales",
    "saleInvoices",
    "refundInvoices",
    "netSalesAverage",
    "customersSignedUp",
  ];

  const handleSort = (column) => {
    const newSortOrder = sortBy === column && sortOrder === "asc" ? "desc" : "asc";
    setSortBy(column);
    setSortOrder(newSortOrder);
  };
  const { language } = useSelector((state) => state.auth);

  return (
    <div className={`d-flex caregory-card ${language === "arabic" ? "rtl" : "ltr"}`}>
      <SideCard
        handleSelectStores={handleSelectStores}
        handleSelectUsers={handleSelectUsers}
        allUser={allUsers}
        allStores={allStores}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        page={page}
        limit={limit}
        tableType={tableType}
      />
      <div className={`main-content w-100 ${language === "arabic" ? "pr-3" : "pl-3"}`}>
        <div className="card mb-3">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <h5 className="card-title text-primary" role="button" onClick={() => exportTableToPDF("tableToExport", "sales_by_user.pdf")}>
                {i18n("LandingPage.EXPORT")}
              </h5>
            </div>
            <div id="tableToExport">
              <Table columns={columns} data={formattedData} onSort={handleSort} sortBy={sortBy} sortOrder={sortOrder} />
            </div>
            <nav>
              <PageNavigator
                page={page}
                limit={limit}
                handlePageChange={handlePageChange}
                handleLimitChange={handleLimitChange}
                data={formattedData}
              />
            </nav>
            {openFiltersDialog && (
              <FiltersDialog
                handleSelectStores={handleSelectStores}
                handleSelectUsers={handleSelectUsers}
                allUsers={allUsers}
                allStores={allStores}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                handleStartDateChange={handleStartDateChange}
                handleEndDateChange={handleEndDateChange}
                page={page}
                limit={limit}
                tableType={tableType}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesByUser;
