import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import fatoratyLogo from "../../../../assets/img/logo-light.svg";
import objectPath from "object-path";
// import SVG from "react-inlinesvg";
// import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { translateModuleName } from "../../../../helpers/helpers";
import { useSelector } from "react-redux";

// import { QuickUserToggler } from "../extras/QuiclUserToggler";

export function HeaderMobile() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      headerLogo: uiService.getStickyLogo(),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      headerMenuSelfDisplay: objectPath.get(uiService.config, "header.menu.self.display") === true,
      headerMobileCssClasses: uiService.getClasses("header_mobile", true),
      headerMobileAttributes: uiService.getAttributes("header_mobile"),
    };
  }, [uiService]);
  const { language } = useSelector((state) => state.auth);
  const location = useLocation();

  const { pathname } = location;
  // Define a mapping of specific paths to display text
  const pathToTextMapping = {
    "analytics/sales-summary": "Sales by Summary",
    "sales-by-product": "Sales by Product",
    "sales-by-category": "Sales by Category",
    "sales-by-user": "Sales by User",
    "sales-by-payment-channel": "Sales by Payment Channel",
    "sales-by-time": "Sales by Time",
    "tax-report": "Taxes",
    "discount-report": "Discounts",
  };
  // Extract the full path after the base URL
  const pathSegment = pathname
    .split("/")
    .filter(Boolean)
    .join("/");
  // Get the display text from the mapping
  const displayText =
    pathToTextMapping[pathSegment] ||
    pathSegment
      .split("/")
      .pop()
      .replace("-", " ")
      .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());

  return (
    <>
      {/*begin::Header Mobile*/}
      <div
        id="kt_header_mobile"
        className={`header-mobile align-items-center ${layoutProps.headerMobileCssClasses}`}
        {...layoutProps.headerMobileAttributes}
      >
        {/*begin::Toolbar*/}
        {layoutProps.asideDisplay && (
          <>
            {/*begin::Aside Mobile Toggle*/}
            <button className="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
              <span />
            </button>

            {/*end::Aside Mobile Toggle*/}
          </>
        )}
        {/*begin::Logo*/}
        <span
          className="ml-5 header-subscription-text"
          style={{
            width: "100%",
            minWidth: "fit-content",
            marginRight: language === "arabic" && "20px",
            whiteSpace: "nowrap",
          }}
        >
          {translateModuleName(displayText)}
        </span>
        {/* <Link to="" className="brand-logo">
          <img alt="logo" src={fatoratyLogo} />
        </Link> */}

        {/*end::Logo*/}
        {layoutProps.headerMenuSelfDisplay && (
          <>
            {/*begin::Header Menu Mobile Toggle*/}
            {/* <button className="btn p-0 burger-icon ml-4" id="kt_header_mobile_toggle">
                    <span/>
                  </button> */}
            {/*end::Header Menu Mobile Toggle*/}
          </>
        )}

        {/*begin::Topbar Mobile Toggle*/}
        {/* <QuickUserToggler /> */}
        {/* <button className="btn btn-hover-text-primary p-0 ml-2" id="kt_header_mobile_topbar_toggle">
          <span className="svg-icon svg-icon-xl">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
          </span>
        </button> */}
        {/*end::Topbar Mobile Toggle*/}
        {/*end::Toolbar*/}
      </div>
      {/*end::Header Mobile*/}
    </>
  );
}
