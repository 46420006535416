// REGISTRATION

import { i18n } from "../app/pages/private/languageSelector";
import cloudIcon from "../assets/img/cloudImg.svg";
import devicesIcon from "../assets/img/devicesIcon.svg";
import noWifi from "../assets/img/noWifi.svg";
import analysis from "../assets/img/analysis.svg";
import fingerPoint from "../assets/img/fingerPointI.svg";
import reverseIcon from "../assets/img/reverseIcon.svg";
import personSpeak from "../assets/img/cloudImg.svg";
import callingIcon from "../assets/img/callingIcon.svg";
import Mall from "../assets/img/Mall.svg";
import industrial from "../assets/img/industrial.svg";
import Bussiness from "../assets/img/Bussiness.svg";
import electronics from "../assets/img/electronics.svg";
import clothes from "../assets/img/clothes.svg";
import Jewelery from "../assets/img/Jewelery.svg";
import cafe from "../assets/img/Cafe.svg";
import restaurant from "../assets/img/Restaurant.svg";
import Grocery from "../assets/img//Grocery.svg";

export const FATORATAY_AUTH = "FATORATY_AUTH";

export const ACCOUNT_TYPES = {
  INDIVIDUAL: "INDIVIDUAL",
  COMPANY: "COMPANY",
};

export const HOMEPAGE_CARDS = [
  {
    item: {
      img: cloudIcon,
      tag: "Secure cloud system",
    },
  },
  {
    item: {
      img: devicesIcon,
      tag: "Supports multiple stores",
    },
  },
  {
    item: {
      img: noWifi,
      tag: "Works without internet",
    },
  },
  {
    item: {
      img: analysis,
      tag: "Real-time reports and analysis",
    },
  },
  {
    item: {
      img: fingerPoint,
      tag: "User-friendly interfaces",
    },
  },
  {
    item: {
      img: reverseIcon,
      tag: "Continuous feature development",
    },
  },
  {
    item: {
      img: personSpeak,
      tag: "Advanced control of roles and permissions",
    },
  },
  {
    item: {
      img: callingIcon,
      tag: "Technical support around the clock",
    },
  },
];
export const BSSINESS_CARDS = [
  {
    item: {
      img: Grocery,
      tag: "Grocery",
    },
  },
  {
    item: {
      img: restaurant,
      tag: "Restaurant",
    },
  },
  {
    item: {
      img: cafe,
      tag: "Cafe",
    },
  },
  {
    item: {
      img: Jewelery,
      tag: "Jewelery",
    },
  },
  {
    item: {
      img: clothes,
      tag: "Clothes & Retail",
    },
  },
  {
    item: {
      img: electronics,
      tag: "Electronics",
    },
  },
  {
    item: {
      img: Bussiness,
      tag: "Entrepreneur",
    },
  },
  {
    item: {
      img: industrial,
      tag: "Industrial tools ",
    },
  },
  {
    item: {
      img: Mall,
      tag: "Other",
    },
  },
];

export const INDUSTRIES = [
  "ACCOUNTING & INSURANCE",
  "AUTO PARTS SERVICES",
  "BUSINESS SERVICES ",
  "BEAUTY CARE",
  "Car maintenance centers",
  "CONSTRUCTION ENGINEERING BUILDING ",
  "DESIGNS CREATIVITY & PHOTOGRAPHY",
  "EDUCATION & TRAINING ",
  "FLIGHT & HOTEL RESERVATIONS ",
  "FURNITURE & FURNISHINGS ",
  "GOVERNMENT ",
  "HEALTH & TREATMENT",
  "LABOR & CLEANING SERVICES",
  "LAWYERS ",
  "Mobiles and electronic devices",
  "Maintenance of devices and equipment",
  "ONLINE STORE ",
  "SHOP & RETAILS",
  "TECHNICAL SERVICES ",
  "TRANSPORT TRAVEL & TOURISM ",
  "OTHER",
];

export const COUNTRIES = ["PAKISTAN", "SAUDI ARABIAN", "QATAR", "DUBAI", "AMERICAN"];

export const PROFESSIONS = ["Freelancer", "IT", "ENGINEER", "MARKETING ", "MANAGER", "DESIGN ", "LEGAL", "SALES", "ACCOUNTING", "OPERATING", "OTHER"];
export const CATAGORY = [""];

export const PAYMENT_TYPES = [{ name: "Card", active: true, default: true }];

export const INVOICE_TABLE_ATTRIBUTES = [
  { name: "DATE", active: true },
  { name: "TYPE", active: true },
  { name: "CUSTOMER", active: true },
  { name: "USER", active: true },
  { name: "STORE", active: true },
  // { name: "POS", active: true },
  // { name: "STATUS", active: true },
  // { name: "CHANNEL", active: true },
  // { name: "PAYMENT", active: true },
  { name: "AMOUNT", active: true },
];

//to remove later
// export const INVOICE_TABLE_ATTRIBUTES = [
//   { name: "DATE", active: true },
//   { name: "CUSTOMER NAME", active: true },
//   { name: "TOTAL SALES", active: true },
//   { name: "ISSUED BY", active: true },
//   { name: "POS", active: true },
//   { name: "STORE", active: true },
//   { name: "STATUS", active: true },
//   { name: "TYPE", active: true },
//   { name: "CHANNEL", active: true },
//   { name: "PAYMENT", active: true },
//   { name: "BALANCE", active: true },
// ];

export const DASHBOARD_ATTRIBUTES = [
  { name: i18n("DASHBOARD.TotalSales"), active: true, color: "#7925C7" },
  { name: i18n("DASHBOARD.Balance"), active: true, color: "#ED553B" },
  { name: i18n("DASHBOARD.Paid"), active: true, color: "#3CAEA3" },
  { name: i18n("DASHBOARD.Invoices"), active: true, color: "#F2994A" },
  { name: i18n("DASHBOARD.Customers"), active: true, color: "#25AFF3" },
];
export const PAYMENT_REFUND = ["Cash", "Card", "Online"];

export const CATEGORY_COLORS = [
  { color: "#E0E0E0", isChecked: false },
  { color: "#1F6198", isChecked: false },
  {
    color: "#AAC30C",
    isChecked: false,
  },
  {
    color: "#F062C0",
    isChecked: false,
  },
  { color: "#209B3B", isChecked: false },
  {
    color: "#62B4F0",
    isChecked: false,
  },
  {
    color: "#FFB822",
    isChecked: false,
  },
  {
    color: "#ED553B",
    isChecked: false,
  },
];

export const PRODUCT_COLORS_AND_SHAPES = [
  { color: "#E0E0E0", clipPath: "inset(0% 0% 0% 0%)" },
  { color: "#1F6198", clipPath: "circle(50% at 50% 50%)" },
  {
    color: "#AAC30C",
    clipPath: "polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%)",
  },
  {
    color: "#F062C0",
    clipPath: "polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)",
  },
  {
    color: "#209B3B",
    clipPath: "polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%) ",
  },
  {
    color: "#62B4F0",
    clipPath: "polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%)",
  },
  {
    color: "#FFB822",
    clipPath: "polygon(50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%)",
  },
  {
    color: "#ED553B",
    clipPath: "polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%)",
  },
];

export const INVOICE_DEFAULT_FILTER_STATE = {
  type: "",
  status: "",
  channel: "",
  store: "",
  product: "",
  user: "",
  startDate: "",
  endDate: "",
  typeName: "",
  statusName: "",
  channelName: "",
  storeName: "",
  productName: "",
  userName: "",
  startDateName: "",
  endDateName: "",
};

export const CURRENCIES = [
  "PKR",
  "SAR",
  "AED",
  "USD",
  "GBP",
  "AUD",
  "INR",
  "AFN",
  "ALL",
  "DZD",
  "AOA",
  "ARS",
  "AMD",
  "AWG",
  "AZN",
  "BSD",
  "BHD",
  "BDT",
  "BBD",
  "BYN",
  "BZD",
  "BMD",
  "BTN",
  "BOB",
  "BAM",
  "BWP",
  "BRL",
  "BND",
  "BGN",
  "BIF",
  "CVE",
  "KHR",
  "CAD",
  "KYD",
  "CLF",
  "CLP",
  "CNY",
  "COP",
  "COU",
  "KMF",
  "CDF",
  "CRC",
  "HRK",
  "CUC",
  "CUP",
  "CZK",
  "DJF",
  "DOP",
  "EGP",
  "SVC",
  "ERN",
  "ETB",
  "FKP",
  "FJD",
  "XAF",
  "GMD",
  "GEL",
  "GHS",
  "GIP",
  "DKK",
  "GTQ",
  "GNF",
  "GYD",
  "HTG",
  "HNL",
  "HKD",
  "HUF",
  "ISK",
  "NR",
  "IDR",
  "XDR",
  "IRR",
  "IQD",
  "ILS",
  "JJM",
  "JPY",
  "JOD",
  "KZT",
  "KES",
  "KPW",
  "KRW",
  "KWD",
  "KGS",
  "LAK",
  "LBP",
  "LSL",
  "LRD",
  "LYD",
  "MOP",
  "MGA",
  "MWK",
  "MYR",
  "MVR",
  "MRU",
  "MUR",
  "XUA",
  "MXN",
  "MXV",
  "MDL",
  "MNT",
  "MZN",
  "MMK",
  "NAD",
  "NPR",
  "NIO",
  "NGN",
  "OMR",
  "PAB",
  "PGK",
  "PYG",
  "PEN",
  "PHP",
  "PLN",
  "QAR",
  "MKD",
  "RON",
  "RUB",
  "RWF",
  "SHP",
  "XCD",
  "WST",
  "STN",
  "RSD",
  "SCR",
  "SLL",
  "SGD",
  "ANG",
  "XSU",
  "SBD",
  "SOS",
  "ZAR",
  "SSP",
  "EUR",
  "LKR",
  "SDG",
  "SRD",
  "NOK",
  "SZL",
  "SEK",
  "CHE",
  "CHF",
  "CHW",
  "SYP",
  "TWD",
  "TJS",
  "TZS",
  "THB",
  "XOF",
  "NZD",
  "TOP",
  "TTD",
  "TND",
  "TRY",
  "TMT",
  "UGX",
  "UAH",
  "USN",
  "UYI",
  "UYU",
  "UZS",
  "VUV",
  "VEF",
  "VND",
  "XPF",
  "MAD",
  "YER",
  "ZMW",
  "ZWL",
];

export const replacingObjectInArray = (orignalArray, arrayYouWantToAddedInOrignalArray) => {
  let result;
  result = arrayYouWantToAddedInOrignalArray.reduce(function(arr1, arr2) {
    let isMatchFound = false;
    arr1.forEach(function(list) {
      if (arr2._id === list._id) {
        list = Object.assign(list, arr2);
        isMatchFound = true;
      }
    });
    if (!isMatchFound) {
      arr1.push(arr2);
    }
    return arr1;
  }, orignalArray);
  if (result && result.length) {
    return result;
  }
};

export function shouldShowPricingPlanModalForTrial(subscriptionData, edit) {
  if (!edit) {
    const { currentPlan, userTrial, subscriptionInfo } = subscriptionData?.subscription || {};
    return (
      currentPlan?.plan === "Free" &&
      currentPlan?.status === "Running" &&
      userTrial?.isTrialStarted &&
      userTrial?.isTrialCancelled &&
      userTrial?.isTrialExpired &&
      !subscriptionInfo?.isSubscribed &&
      !subscriptionInfo?.isSubscribedExpired &&
      !subscriptionInfo?.isUnsubscribed
    );
  }
  return false;
}

export function shouldShowAddPaymentModal(subscriptionData, paymentCard) {
  const { currentPlan, userTrial, subscriptionInfo } = subscriptionData?.subscription || {};

  return (
    currentPlan?.plan === "Free" &&
    currentPlan?.status === "Running" &&
    userTrial?.isTrialStarted &&
    userTrial?.isTrialCancelled &&
    userTrial?.isTrialExpired &&
    !subscriptionInfo?.isSubscribed &&
    !subscriptionInfo?.isSubscribedExpired &&
    !subscriptionInfo?.isUnsubscribed &&
    !paymentCard
  );
}

export function shouldRedirectToAddUser(subscriptionData) {
  const { currentPlan, userTrial, subscriptionInfo } = subscriptionData?.subscription || {};

  return (
    currentPlan?.plan === "Free" &&
    currentPlan?.status === "Running" &&
    userTrial?.isTrialStarted &&
    userTrial?.isTrialCancelled &&
    userTrial?.isTrialExpired &&
    !subscriptionInfo?.isSubscribed &&
    !subscriptionInfo?.isSubscribedExpired &&
    !subscriptionInfo?.isUnsubscribed
  );
}

export function isFreePlanRunningAndTrialExpired(subscriptionData) {
  const currentPlan = subscriptionData?.subscription?.currentPlan;
  const userTrial = subscriptionData?.subscription?.userTrial;

  return (
    currentPlan?.plan === "Free" &&
    currentPlan?.status === "Running" &&
    userTrial?.isTrialStarted &&
    !userTrial?.isTrialCancelled &&
    userTrial?.isTrialExpired
  );
}

export const formatNumber = (number) => {
  return Number(number).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const shouldShowPricingPlanModal = (subscriptionData, edit) => {
  return (
    !edit &&
    subscriptionData?.subscription?.currentPlan?.plan === "Free" &&
    subscriptionData?.subscription?.currentPlan.status === "Running" &&
    subscriptionData?.subscription?.subscriptionInfo?.isUnsubscribed
  );
};

export const shouldShowTrialExpiredModal = (subscriptionData) => {
  return (
    subscriptionData?.subscription?.currentPlan.plan === "Free" &&
    subscriptionData?.subscription?.userTrial.isTrialExpired &&
    !subscriptionData?.subscription?.subscriptionInfo.isSubscribed &&
    !subscriptionData?.subscription?.subscriptionInfo.isUnsubscribed &&
    subscriptionData?.subscription.subscriptionEndedAt === undefined
  );
};

export const shouldShowPricingPlanModalAfterBackToFreePlan = (subscriptionData, edit) => {
  return (
    !edit &&
    subscriptionData?.subscription?.currentPlan?.plan === "Free" &&
    subscriptionData?.subscription?.currentPlan?.status === "Running" &&
    subscriptionData?.subscription?.userTrial?.isTrialStarted &&
    !subscriptionData?.subscription?.userTrial?.isTrialCancelled &&
    subscriptionData?.subscription?.userTrial?.isTrialExpired
  );
};

export const shouldShowPricingPlanModalConfirmAfterBackToFreePlan = (subscriptionData, edit) => {
  return (
    !edit &&
    subscriptionData?.subscription?.currentPlan?.plan === "Free" &&
    subscriptionData?.subscription?.currentPlan?.status === "Running" &&
    subscriptionData?.subscription?.userTrial?.isTrialStarted &&
    subscriptionData?.subscription?.userTrial?.isTrialCancelled &&
    subscriptionData?.subscription?.userTrial?.isTrialExpired
  );
};
