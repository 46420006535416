import axios from "axios";

export const USERS_URL = "api/users";
export const USERS_WITH_ROLE_URL = "api/users";
export const SETUP_ACCOUNT_URL = "api/setup-account";
export const SETUP_ACCOUNT_DETAIL_URL = "api/users";
export const SUBSCRIPTION_URL = "api/subscription";

export function getUserById(userId) {
  return axios.get(`${USERS_URL}/${userId}`);
}

export function getAllUsers(limit = 10, page = 1, sortOrder = "desc", sortField = "created_at") {
  return axios.get(`${USERS_URL}?limit=${limit}&page=${page}&sortOrder=${sortOrder}&sortField=${sortField}`);
}

export function createUser(user) {
  let newUser = {
    profileImage: user.profileImage,
    name: {
      first: user.firstName,
      last: user.lastName,
    },
    email: user.email,
    mobile: user.mobile,
    role: user.role,
    rolePayload: user.rolePayload,
    storesAccess: user.storesAccess,
    isActive: user.isActive,
    posPin: user?.posPin,
  };
  return axios.post(`${USERS_URL}`, newUser);
}

export function updateUser(id, user) {
  let newUser = {
    profileImage: user.profileImage,
    name: {
      first: user.firstName,
      last: user.lastName,
    },
    email: user.email,
    mobile: user.mobile,
    role: user.role,
    rolePayload: user.rolePayload,
    storesAccess: user.storesAccess,
    isActive: user.isActive,
    posPin: user?.posPin,
  };
  return axios.patch(`${USERS_URL}/${id}`, newUser);
}
export function updateUserPosPin(id, user) {
  return axios.patch(`${USERS_URL}/${id}`, user);
}

export function deleteUser(userId) {
  return axios.delete(`${USERS_URL}/${userId}`);
}

export function deleteSubscriptionUsers(userId) {
  return axios.delete(`${SUBSCRIPTION_URL}/deleteSubscriptionUsers/${userId}`);
}

export function setupAccountDetail(token) {
  return axios.get(`${SETUP_ACCOUNT_DETAIL_URL}/${token}/verificationToken`);
}
export function setupAccount(token, data) {
  return axios.post(`${SETUP_ACCOUNT_URL}/${token}`, data);
}

export function updateUsersWithRole(ids, role) {
  let toUpdate = "?ids=";
  ids.forEach((item) => {
    toUpdate = toUpdate + item + ",";
  });
  var tmp = toUpdate.split("");
  tmp.splice(toUpdate.length - 1, 1);
  tmp = tmp.join("");
  return axios.patch(`${USERS_WITH_ROLE_URL}${tmp}`, {
    rolePayload: role,
  });
}
