import * as requestFromServer from "./storeCrud";
import { storesSlice, callTypes } from "./storeSlice";
import toast from "react-hot-toast";

const { actions } = storesSlice;

export const fetchStores = (limit = 10, page = 1, sortOrder = "desc", sortField = "created_at") => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllStores(limit, page, sortOrder, sortField)
    .then((response) => {
      const { stores } = response.data;
      dispatch(
        actions.storesFetched({
          entities: stores,
        })
      );
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't find stores";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const createStore = (storeForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createStore(storeForCreation)
    .then((response) => {
      dispatch(fetchStores());
      toast.success("store Created");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't create store";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchStore = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.storeFetched({ storeForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getStoreById(id)
    .then((response) => {
      const { store } = response.data;
      dispatch(actions.storeFetched({ storeForEdit: store }));
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't find store";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteStore = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer.deleteStore(id).then((response) => {
    toast.success("store deleted");
    dispatch(fetchStores());
  });
};

export const updateStore = (id, store) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStore(id, store)
    .then(() => {
      dispatch(fetchStores());
      toast.success("Updated");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't update store";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
