import axios from "axios";

export const DISCOUNT_URL = "api/discount";

// GET single discount by id
export function getDiscountById(discountId) {
  return axios.get(`${DISCOUNT_URL}/${discountId}`);
}

// GET all categories
export function getAllDiscounts(limit = 10, page = 1, sortOrder = "desc", sortField = "created_at") {
  return axios.get(`${DISCOUNT_URL}?channel=web&page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortField=${sortField}`);
}

// POST discount
export function createDiscount(discount) {
  const newDiscount = {
    name: discount.name,
    value: discount.value ? parseInt(discount.value) : undefined,
    type: discount.type,
    restricted: discount.restricted,
  };
  return axios.post(`${DISCOUNT_URL}`, newDiscount);
}

// UPDATE discount
export function updateDiscount(discount) {
  const newDiscount = {
    name: discount.name,
    value: discount.value ? parseInt(discount.value) : undefined,
    type: discount.type,
    restricted: discount.restricted,
  };
  return axios.patch(`${DISCOUNT_URL}/${discount._id}`, newDiscount);
}

// DELETE  discount
export function deleteDiscount(discountId) {
  return axios.delete(`${DISCOUNT_URL}/${discountId}`);
}
