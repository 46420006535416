import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Field } from "formik";
import { Form } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";
import IconClose from "../../../../assets/img/icon-close.svg";
import { Input, SwitchToggler, Card } from "../../../../_metronic/_partials/controls";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/discount/discountActions";
import toast from "react-hot-toast";
import { i18n } from "../../private/languageSelector";
import { ToggleSwitch } from "../../../../_metronic/_partials/controls/forms/ToggleSwitch";
import DeleteIcon from "../../../../assets/img/company/Vector.svg";
import SubscriptionDialog from "../../company/company-edit-Dialog/SubscriptionDialog";

export const discountSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, i18n("Validation.Minimum3Symbols"))
    .max(50, i18n("Validation.Maximum50Symbols"))
    .required(i18n("Validation.RequiredField")),

  value: Yup.string().nullable(),
  restricted: Yup.boolean(),
});

const initAddState = {
  name: "",
  value: "",
  type: "%",
  restricted: false,
};

export const DiscountEditForm = ({ edit, discountForEdit, openDiscountDialog, setOpenDiscountDialog, setShowDeleteModal }) => {
  const dispatch = useDispatch();

  const [type, setType] = useState(false);
  const [amount, setAmount] = useState("");
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);

  const { currency } = useSelector(
    (state) => ({
      currency: state?.auth?.user?.companyId?.currency,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (discountForEdit) {
      setAmount(discountForEdit?.value);
      setType(discountForEdit?.type === "%" ? false : true);
    }
  }, [discountForEdit]);

  const handlingPercentage = (e) => {
    if (!type) {
      if (e.target.value > 99) {
        setAmount(99);
        toast.error("Max % value should not exceed more than 99%");
      }
    }
  };

  useEffect(() => {
    if (!type) {
      if (amount > 99) {
        setAmount(99);
      }
    }
  }, [amount, type]);

  const handleCloseDiscountDialog = (dirty, resetForm) => {
    if (dirty) {
      setShowUnsavedChangesModal(true);
    } else {
      setOpenDiscountDialog(false);
      resetForm();
      setAmount();
      setType(false);
    }
  };
  const { language } = useSelector((state) => state.auth);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={edit ? discountForEdit : initAddState}
        validationSchema={discountSchema}
        onSubmit={(values, { resetForm }) => {
          let discount;
          if (values.type === "%") {
            discount = { ...values, value: amount };
          } else {
            discount = { ...values, value: amount, type: currency };
          }
          if (edit) {
            dispatch(actions.updateDiscount(discount));
          } else {
            dispatch(actions.createDiscount(discount));
          }

          resetForm();
          setAmount();
          setType(false);
          setOpenDiscountDialog(false);
        }}
      >
        {({ handleSubmit, values, setValues, setFieldValue, resetForm, dirty }) => {
          if (values?.name) {
            setIsFormFilled(true);
          } else setIsFormFilled(false);
          return (
            <>
              <Modal
                className={`${language === "arabic" && "ar"}  modal-drawer my-profile invoice-drawer`}
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"
                show={openDiscountDialog}
                onHide={() => handleCloseDiscountDialog(dirty, resetForm)}
              >
                <Modal.Header>
                  <div className="d-flex justify-content-around align-items-center">
                    <span
                      onClick={() => {
                        // setAmount();
                        // setType(false);
                        // setOpenDiscountDialog(false);
                        handleCloseDiscountDialog(dirty, resetForm);
                      }}
                      className="cursor-pointer"
                    >
                      <img src={IconClose} alt="loading" />
                    </span>
                    <span>
                      {edit === false ? (
                        <span className="add-edit-tax">{i18n("Discount.AddNewDiscounts")}</span>
                      ) : (
                        <span className="add-edit-tax">{i18n("Discount.EditDiscount")}</span>
                      )}
                    </span>
                    <span
                      onClick={() => handleSubmit()}
                      className="add-edit-tax cursor-pointer"
                      style={{
                        color: isFormFilled && "#FFFFFF",
                        opacity: isFormFilled ? 1 : 0.5,
                      }}
                    >
                      {i18n("Category.Save")}
                    </span>
                  </div>
                </Modal.Header>
                <Modal.Body className="overlay overlay-block cursor-default">
                  <Form className="form form-label-right">
                    <div className="invoice-items row">
                      <div className="col-12">
                        <div className="form-group">
                          <Field
                            name="name"
                            component={Input}
                            placeholder={i18n("global.Name")}
                            label={i18n("global.Name")}
                            withFeedbackLabel={true}
                          />
                        </div>
                        <div className="d-flex">
                          <div className="form-group w-100 mb-0 remove-arrows">
                            <Field
                              name="value"
                              component={Input}
                              type="number"
                              placeholder={i18n("global.Value")}
                              label={i18n("global.Value")}
                              value={amount}
                              onChange={(e) => {
                                setFieldValue("value", e.target.value);
                                setAmount(e.target.value);
                                handlingPercentage(e);
                              }}
                              withFeedbackLabel={true}
                            />
                          </div>
                          <ToggleSwitch
                            selected={type}
                            currency={currency}
                            toggleSelected={() => {
                              setType(!type);
                              setFieldValue("type", type ? "%" : "currency");
                            }}
                          />
                        </div>
                        <span className="text-muted" style={{ fontSize: "12px" }}>
                          {i18n("Discount.LeaveBlankToSpecifyPrice")}
                        </span>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between items-center">
                      <div className=" my-6">
                        <div>
                          <strong>{i18n("Discount.RestrictedAccess")}</strong>
                        </div>
                      </div>
                      <div>
                        <div className="mx-5">
                          <SwitchToggler
                            className="default-switch"
                            id="restricted"
                            name="restricted"
                            checked={values?.restricted || false}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                restricted: e.target.checked,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <p>{i18n("Discount.OnlyEmployeesWithAppropriateAccessRightAreAbleToApplyThisAccount")}</p>
                  </Form>
                  {edit ? (
                    <Card>
                      <div className="w-100">
                        <div className="text-center">
                          <img
                            src={DeleteIcon}
                            alt="delete"
                            width={18}
                            height={20}
                            className="cursor-pointer"
                            onClick={() => setShowDeleteModal(true)}
                          />
                        </div>
                      </div>
                    </Card>
                  ) : null}
                  <SubscriptionDialog
                    modalWidth={"350px"}
                    modalHeight={"185px"}
                    modalBackgroundColor={"#FFFFFF"}
                    show={showUnsavedChangesModal}
                    onHide={() => setShowUnsavedChangesModal(false)}
                    title={i18n("SubscriptionModals.UnsavedChanges")}
                    titleWidth={"140px"}
                    titleHeight={"20px"}
                    titleFontWeight={"700"}
                    titleFontSize={"16px"}
                    titleLineHeight={"20px"}
                    titleColor={"#333333"}
                    message={i18n("SubscriptionModals.AreYouSureYouWantToContinueWithoutSavingChanges")}
                    messageWidth={"320px"}
                    messageHeight={"44px"}
                    messageFontSize={"16px"}
                    messageFontWeight={"400"}
                    messageLineHeight={"21.79px"}
                    messageColor={"#333333"}
                    primaryLabel={i18n("SubscriptionModals.CANCEL")}
                    primaryAction={() => setShowUnsavedChangesModal(false)}
                    primaryLabelWidth={"55px"}
                    primaryLabelHeight={"20px"}
                    primaryLabelFontWeight={"700"}
                    primaryLabelFontSize={"14px"}
                    primaryLabelLineHeight={"20px"}
                    primaryLabelColor={"#828282"}
                    secondaryAction={() => {
                      setShowUnsavedChangesModal(false);
                      setOpenDiscountDialog(false);
                      setIsFormFilled(false);
                      resetForm();
                      setAmount();
                      setType(false);
                    }}
                    secondaryLabel={i18n("SubscriptionModals.CONTINUE")}
                    secondaryLabelWidth={"75px"}
                    secondaryLabelHeight={"20px"}
                    secondaryLabelFontWeight={"700"}
                    secondaryLabelFontSize={"14px"}
                    secondaryLabelLineHeight={"20px"}
                    secondaryLabelColor={"#2D9CDB"}
                    isCenteredOnMobile
                  />
                </Modal.Body>
              </Modal>
            </>
          );
        }}
      </Formik>
    </>
  );
};
