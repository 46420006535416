import React, { useMemo, useState, useEffect } from "react";
import cloneDeep from "lodash/cloneDeep";
import { useInvoicesUIContext } from "./InvoicesUIContext";
import { InvoicesTable } from "./invoices-table/InvoicesTable";
import { InvoicesFilter } from "./invoices-filter/InvoicesFilter";
import ClearFilterIcon from "../../../assets/img/clear-filters.png";
import { getUserByToken } from "../../modules/Auth/_redux/authCrud";
import * as actions from "../../../_redux/invoices/invoicesActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { INVOICE_DEFAULT_FILTER_STATE, INVOICE_TABLE_ATTRIBUTES } from "../../../helpers/constants";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import InvoiceFilterPage from "./invoices-filter/InvoiceFilterPage";

export function InvoicesCard() {
  const dispatch = useDispatch();

  const invoicesUIContext = useInvoicesUIContext();
  const invoicesUIProps = useMemo(() => {
    return {
      ids: invoicesUIContext.ids,
      setIds: invoicesUIContext.setIds,
      queryParams: invoicesUIContext.queryParams,
      newInvoiceButtonClick: invoicesUIContext.newInvoiceButtonClick,
    };
  }, [invoicesUIContext]);

  const [tableFields, setTableFields] = useState([]);
  const { invoiceFilterState } = useSelector(
    (state) => ({
      invoiceFilterState: state?.invoices?.invoiceFilterState,
    }),
    shallowEqual
  );
  useEffect(() => {
    let tableData = cloneDeep([...INVOICE_TABLE_ATTRIBUTES]);
    setTableFields(tableData);

    getUserByToken().then((res) => {
      if (res?.data?.invoiceTableContent?.length) {
        setTableFields([...res.data.invoiceTableContent]);
      }
    });
  }, []);

  return (
    <>
      {/* <InvoicesFilter
        tableFields={tableFields}
        setTableFields={setTableFields}
        selectedInvoices={invoicesUIProps.ids}
        setSelectedInvoices={invoicesUIProps.setIds}
      /> */}

      <InvoiceFilterPage />
      {/* <Card>
        <CardBody>
          <InvoicesTable tableFields={tableFields} />
          {invoiceFilterState?.parentInvoice ? (
            <div className="d-flex justify-content-center">
              <img
                onClick={() => {
                  dispatch(actions.clearInvoiceFilterState());
                  dispatch(actions.fetchInvoices(INVOICE_DEFAULT_FILTER_STATE, invoicesUIProps.queryParams));
                }}
                src={ClearFilterIcon}
                alt="loading"
                width="300px"
              />
            </div>
          ) : (
            ""
          )}
        </CardBody>
      </Card> */}
    </>
  );
}
