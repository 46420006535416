import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Input, Card } from "../../../../_metronic/_partials/controls";
import { CATEGORY_COLORS } from "../../../../helpers/constants";
import "react-phone-input-2/lib/style.css";
import IconClose from "../../../../assets/img/icon-close.svg";
import * as actions from "../../../../_redux/category/categoryActions";
import { i18n } from "../../private/languageSelector";
import cloneDeep from "lodash/cloneDeep";
import toast from "react-hot-toast";
import DeleteIcon from "../../../../assets/img/company/Vector.svg";
import SubscriptionDialog from "../../company/company-edit-Dialog/SubscriptionDialog";

// Validation schema
export const AddCategorySchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minimum 3 Alphabets")
    .max(50, "Maximum 50 Alphabets")
    .required("English name is required"),

  description: Yup.string(),
  color: Yup.string(),
});

const initAddState = {
  name: "",
  color: "",
  isActive: true,
  description: "",
};

export function AddCategoryForm({ edit, category, openCategoryAddDialog, setOpenCategoryAddDialog, setShowDeleteModal }) {
  const dispatch = useDispatch();
  const [categoryColors, setCategoryColors] = useState([]);
  const [selectColor, setSelectColor] = useState("");
  const [prevIndex, setPrevIndex] = useState("");
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [isDirty, setisDirty] = useState(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);

  useEffect(() => {
    let data = cloneDeep([...CATEGORY_COLORS]);
    setCategoryColors(data);
    if (edit) {
      setSelectColor(category?.color);
    } else {
      setSelectColor("");
    }
  }, [category, edit]);

  const checkedHandler = (index) => {
    setPrevIndex(index);
    categoryColors[index].isChecked = true;
    if (prevIndex !== "") {
      categoryColors[prevIndex].isChecked = false;
    }
  };
  const { language } = useSelector((state) => state.auth);

  const handleClose = () => {
    if (isDirty) {
      setShowUnsavedChangesModal(true);
    } else {
      setOpenCategoryAddDialog(false);
    }
  };
  return (
    <>
      <Modal
        // className="modal-drawer invoice-drawer"
        className={`${language === "arabic" && "ar"}  modal-drawer my-profile invoice-drawer`}
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={openCategoryAddDialog}
        onHide={handleClose}
      >
        <Formik
          enableReinitialize={true}
          initialValues={edit ? category : initAddState}
          validationSchema={AddCategorySchema}
          onSubmit={(values, { resetForm }) => {
            if ((category?._id && edit === false) || (!category?._id && edit === false)) {
              let newCategory = {
                isActive: values.isActive,
                name: values.name,
                description: values.description,
                color: selectColor ? selectColor : "#2d9cdb",
              };
              dispatch(actions.createCategory(newCategory)).then((res) => {
                if (res) {
                  toast.success("Category Created");
                  dispatch(actions.fetchCategories());
                  setOpenCategoryAddDialog(false);
                  resetForm();
                }
              });
            } else {
              dispatch(
                actions.updateCategory({
                  ...values,
                  color: selectColor ? selectColor : "#2d9cdb",
                })
              ).then(() => {
                setOpenCategoryAddDialog(false);
                resetForm();
              });
            }
            let categoryArray = CATEGORY_COLORS?.map((item) => {
              return { ...item, isChecked: false };
            });
            setCategoryColors([...categoryArray]);
          }}
        >
          {({ handleSubmit, values, setFieldValue, dirty, resetForm }) => {
            if (values.name) {
              setIsFormFilled(true);
            } else setIsFormFilled(false);
            if (dirty) {
              setisDirty(true);
            } else {
              setisDirty(false);
            }
            return (
              <>
                <Modal.Header>
                  <div className="d-flex justify-content-around align-items-center">
                    <span
                      onClick={() => {
                        !isDirty ? setOpenCategoryAddDialog(false) : setShowUnsavedChangesModal(true);
                        let categoryArray = CATEGORY_COLORS?.map((item) => {
                          return { ...item, isChecked: false };
                        });
                        setCategoryColors([...categoryArray]);
                      }}
                      className="cursor-pointer"
                    >
                      <img src={IconClose} alt="loading" />
                    </span>
                    <span>
                      {(!category?._id && edit === false) || (category?._id && edit === false) ? (
                        <span className="add-edit-tax">{i18n("Category.AddANewCategory")}</span>
                      ) : (
                        <span className="add-edit-tax">{i18n("Category.EditCategory")}</span>
                      )}
                    </span>
                    <span
                      onClick={() => handleSubmit()}
                      className="add-edit-tax cursor-pointer"
                      style={{
                        color: isFormFilled && "#FFFFFF",
                        opacity: isFormFilled ? 1 : 0.5,
                      }}
                    >
                      {i18n("Category.Save")}
                    </span>
                  </div>
                </Modal.Header>
                <Modal.Body className="overlay overlay-block cursor-default">
                  <Form className="form form-label-right">
                    <Card>
                      <div className="invoice-title border-bottom-0">
                        <p className="m-0">{i18n("Category.CategoryInformation")}</p>
                      </div>
                      <div className="invoice-items row">
                        <div className="col-12">
                          <div className="form-group">
                            <Field
                              name="name"
                              component={Input}
                              placeholder={i18n("global.Name")}
                              label={i18n("global.Name")}
                              withFeedbackLabel={false}
                            />
                          </div>
                          <div className="form-group">
                            <p>{i18n("Category.CategoryDescription")}</p>
                            <textarea
                              rows="7"
                              style={{ width: "100%" }}
                              name="description"
                              placeholder={i18n("Category.CategoryDescription")}
                              label={i18n("Category.Name")}
                              value={values?.description}
                              onChange={(e) => setFieldValue("description", e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </Card>
                    <Card className="card-listitem select-color">
                      <div className="form-select">
                        <div className="invoice-title border-bottom-0">
                          <p className="m-0">{i18n("Category.RepresentationOnPOS")}</p>
                        </div>
                      </div>
                      <div className="mb-5">
                        <label>{i18n("Category.SelectCategoryColor")}</label>
                      </div>
                      <ul className="color-selection">
                        {categoryColors?.map((item, index) => {
                          return (
                            <li
                              key={index}
                              className={`card-icon category ${item.isChecked ? "selected" : ""}`}
                              onClick={() => {
                                setSelectColor(item.color);
                                checkedHandler(index);
                              }}
                              style={{
                                backgroundColor: item.color,
                              }}
                            />
                          );
                        })}
                      </ul>
                    </Card>
                    {edit ? (
                      <Card>
                        <div className="w-100">
                          <div className="text-center">
                            <img
                              src={DeleteIcon}
                              alt="delete"
                              width={18}
                              height={20}
                              className="cursor-pointer"
                              onClick={() => setShowDeleteModal(true)}
                            />
                          </div>
                        </div>
                      </Card>
                    ) : null}
                    <SubscriptionDialog
                      modalWidth={"350px"}
                      modalHeight={"185px"}
                      modalBackgroundColor={"#FFFFFF"}
                      show={showUnsavedChangesModal}
                      onHide={() => setShowUnsavedChangesModal(false)}
                      title={i18n("SubscriptionModals.UnsavedChanges")}
                      titleWidth={"140px"}
                      titleHeight={"20px"}
                      titleFontWeight={"700"}
                      titleFontSize={"16px"}
                      titleLineHeight={"20px"}
                      titleColor={"#333333"}
                      message={i18n("SubscriptionModals.AreYouSureYouWantToContinueWithoutSavingChanges")}
                      messageWidth={"320px"}
                      messageHeight={"44px"}
                      messageFontSize={"16px"}
                      messageFontWeight={"400"}
                      messageLineHeight={"21.79px"}
                      messageColor={"#333333"}
                      primaryLabel={i18n("SubscriptionModals.CANCEL")}
                      primaryAction={() => setShowUnsavedChangesModal(false)}
                      primaryLabelWidth={"55px"}
                      primaryLabelHeight={"20px"}
                      primaryLabelFontWeight={"700"}
                      primaryLabelFontSize={"14px"}
                      primaryLabelLineHeight={"20px"}
                      primaryLabelColor={"#828282"}
                      secondaryAction={() => {
                        setShowUnsavedChangesModal(false);
                        setOpenCategoryAddDialog(false);
                        setIsFormFilled(false);
                        resetForm();
                      }}
                      secondaryLabel={i18n("SubscriptionModals.CONTINUE")}
                      secondaryLabelWidth={"75px"}
                      secondaryLabelHeight={"20px"}
                      secondaryLabelFontWeight={"700"}
                      secondaryLabelFontSize={"14px"}
                      secondaryLabelLineHeight={"20px"}
                      secondaryLabelColor={"#2D9CDB"}
                      isCenteredOnMobile
                    />
                  </Form>
                </Modal.Body>
              </>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
}
