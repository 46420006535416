import React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import objectPath from "object-path";
import { Topbar } from "./Topbar";
import { HeaderMenuWrapper } from "./header-menu/HeaderMenuWrapper";
import { i18n } from "../../../../app/pages/private/languageSelector";
import { translateDate, translateHeaderLabel } from "../../../../helpers/helpers";
import filterIcon from "../../../../assets/img/filterIcon.svg";
import filterActiveIcon from "../../../../assets/img/filterIconBlue.svg";
import * as analyticsActions from "../../../../_redux/analytics/analyticsSlice";
export function Header() {
  const { language } = useSelector((state) => state.auth);
  const { startDate, endDate, datesRangeLabel, openFiltersDialog, isFiltersApplied } = useSelector((state) => state.analytics);

  const last30DaysStart = moment().subtract(29, "days");
  const today = moment();
  const dispatch = useDispatch();

  const uiService = useHtmlClassService();
  const path = window.location.href.toLowerCase().includes("dashboard")
    ? i18n("LandingPage.DASHBOARD")
    : window.location.href.toLowerCase().includes("customers")
    ? i18n("LandingPage.CUSTOMER")
    : window.location.href.toLowerCase().includes("products")
    ? i18n("LandingPage.MENU")
    : window.location.href.toLowerCase().includes("invoices")
    ? i18n("LandingPage.INVOICES")
    : window.location.href.toLowerCase().includes("company")
    ? i18n("LandingPage.COMPANY")
    : window.location.href.toLowerCase().includes("reports")
    ? i18n("LandingPage.REPORTS")
    : "";

  const currentPath = window.location.pathname.toLowerCase();
  const isAnalyticsSalesSummary = [
    "/analytics/sales-summary",
    "/sales-by-product",
    "/sales-by-category",
    "/sales-by-user",
    "/sales-by-payment-channel",
    "/sales-by-time",
    "/tax-report",
    "/discount-report",
    "/invoices",
  ].includes(currentPath);

  const layoutProps = {
    headerClasses: uiService.getClasses("header", true),
    headerAttributes: uiService.getAttributes("header"),
    headerContainerClasses: uiService.getClasses("header_container", true),
    menuHeaderDisplay: objectPath.get(uiService.config, "header.menu.self.display"),
  };
  let headerLabel = localStorage.getItem("rangeLabel");

  const handleFiltersDialog = () => {
    dispatch(analyticsActions.setOpenFiltersDialog(true));
  };
  return (
    <>
      {/*begin::Header*/}
      <div className={`header  ${layoutProps.headerClasses}`} id="kt_header" {...layoutProps.headerAttributes}>
        {/*begin::Container*/}
        <div className={`header-inner ${layoutProps.headerContainerClasses}`} style={{ marginRight: language === "arabic" && "300px" }}>
          {language === "arabic" ? null : <h3>{path}</h3>}
          {/* <AnimateLoading /> */}
          {/*begin::Header Menu Wrapper*/}
          {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />}
          {!layoutProps.menuHeaderDisplay && language === "english" && <div />}
          {/*end::Header Menu Wrapper*/}

          {/*begin::Topbar*/}
          <Topbar />
          {/*end::Topbar*/}
        </div>
        {/*end::Container*/}
      </div>
      {isAnalyticsSalesSummary && (
        <div className={`date-header ${language === "arabic" ? "rtl" : "ltr"}`}>
          <span>
            {`${headerLabel === null ? i18n("DASHBOARD.Last30Days") : translateHeaderLabel(headerLabel)} (${translateDate(
              startDate,
              language
            )} - ${translateDate(endDate, language)})`}
          </span>
          <span className="filter-icon-display" onClick={handleFiltersDialog}>
            <img src={!isFiltersApplied ? filterIcon : filterActiveIcon} alt="filterIcon" />
          </span>
        </div>
      )}
      {/*end::Header*/}
    </>
  );
}
