import React, { useMemo, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useProductsUIContext } from "../../../../../pages/products/ProductsUIContext";
import { i18n } from "../../../../../pages/private/languageSelector";
import IconClose from "../../../../../../assets/img/icon-close.svg";
import IconCopy from "../../../../../../assets/img/invoice-actions/copy.svg";
import IconDelete from "../../../../../../assets/img/invoice-actions/delete.svg";
import IconEye from "../../../../../../assets/img/invoice-actions/eye.svg";
import IconEdit from "../../../../../../assets/img/carbon_edit.svg";
import IconAssign from "../../../../../../assets/img/assign.svg";
import * as productActions from "../../../../../../_redux/products/productsActions";
import { AssignToCategory } from "../../../../../pages/products/product-assign-to-category/AssignToCategory";

export function ProductActionModal({ show, onHide }) {
  const { id } = useParams();
  const dispatch = useDispatch();

  const productsUIContext = useProductsUIContext();
  const productsUIProps = useMemo(() => {
    return {
      openEditProductDialog: productsUIContext.openEditProductDialog,
      openDeleteProductDialog: productsUIContext.openDeleteProductDialog,
      openViewProductDialog: productsUIContext.openViewProductDialog,
      queryParams: productsUIContext?.queryParams,
    };
  }, [productsUIContext]);

  const [openAssignCategoryDialog, setOpenAssignCategoryDialog] = useState(false);

  useEffect(() => {
    dispatch(productActions.fetchProduct(id));
  }, [dispatch, id]);

  const { productForEdit, userAccess, productFilterState, currentState } = useSelector(
    (state) => ({
      productForEdit: state?.products?.productForEdit,
      userAccess: state?.users?.userAccess,
      productFilterState: state?.products?.productFilterState,
      currentState: state.products,
    }),
    shallowEqual
  );

  const duplicateHandler = () => {
    const { entities } = currentState;

    let allSku = entities?.map((item) => item.sku);
    let sortedSku = allSku?.sort((a, b) => b - a);
    let nextSku = Number(sortedSku[0]) + 1;

    let duplicatedProduct = productForEdit?.category ? productForEdit : { ...productForEdit, category: "" };
    let duplicatedProductWithNewSku = { ...duplicatedProduct, sku: nextSku };

    dispatch(productActions.duplicateProduct(productFilterState, duplicatedProductWithNewSku, productsUIContext.queryParams, entities));
  };

  return (
    <>
      <Modal className="modal-drawer" size="lg" aria-labelledby="example-modal-sizes-title-lg" show={show} onHide={() => {}}>
        <Modal.Header>
          <button type="button" className="btn btn-close" onClick={onHide}>
            <img src={IconClose} alt="loading" />
          </button>

          {<Modal.Title>{i18n("MENU.Actions")}</Modal.Title>}
        </Modal.Header>

        <>
          <Modal.Body className="overlay overlay-block cursor-default">
            {userAccess?.editData ? (
              <div className="product-action" onClick={() => productsUIProps.openEditProductDialog(id)}>
                <img src={IconEdit} alt="loading" />
                <b>{i18n("Customer.Edit")}</b>
              </div>
            ) : (
              ""
            )}
            {userAccess?.createData ? (
              <div className="product-action" onClick={() => duplicateHandler()}>
                <img src={IconCopy} alt="loading" />
                <b>{i18n("MENU.DUPLICATE")}</b>
              </div>
            ) : (
              ""
            )}
            {userAccess?.editData ? (
              <div className="product-action" onClick={() => setOpenAssignCategoryDialog(true)}>
                <img src={IconAssign} alt="loading" />
                <b>{i18n("MENU.AssignToCategory")}</b>
              </div>
            ) : (
              ""
            )}
            {userAccess?.deleteData ? (
              <div className="product-action" onClick={() => productsUIProps.openDeleteProductDialog(id)}>
                <img src={IconDelete} alt="loading" />
                <b>{i18n("MENU.Delete")}</b>
              </div>
            ) : (
              ""
            )}
          </Modal.Body>
        </>
      </Modal>
      <AssignToCategory
        openAssignCategoryDialog={openAssignCategoryDialog}
        setOpenAssignCategoryDialog={setOpenAssignCategoryDialog}
        productId={id}
        onHide={onHide}
      />
    </>
  );
}
