export const ProductStatusCssClasses = ["danger", "success", "info", ""];
export const ProductStatusTitles = ["Suspended", "Active", "Pending", ""];
export const ProductTypeCssClasses = ["success", "primary", ""];
export const ProductTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "_id", order: "asc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "25", value: 25 },
  { text: "50", value: 50 },
];
export const initialFilter = {
  filter: { name: "", category: "" },
  sortOrder: "asc", // asc||desc
  sortField: "_id",
  pageNumber: 1,
  pageSize: 10,
};
