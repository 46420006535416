import axios from "axios";

export const Taxes_URL = "api/tax";

export function getTaxById(taxId) {
  return axios.get(`${Taxes_URL}/${taxId}`);
}

export function getAllTaxes(limit = 10, page = 1, order = "desc", sortField = "created_at") {
  return axios.get(`${Taxes_URL}?page=${page}&limit=${limit}&sortOrder=${order}&sortField=${sortField}`);
}

export function createTax(tax) {
  return axios.post(`${Taxes_URL}`, tax);
}

export function updateTax(id, tax) {
  return axios.patch(`${Taxes_URL}/${id}`, tax);
}

export function deleteTax(taxId) {
  return axios.delete(`${Taxes_URL}/${taxId}`);
}
