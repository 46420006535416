import axios from "axios";

export const Stores_URL = "api/store";

export function getStoreById(storeId) {
  return axios.get(`${Stores_URL}/${storeId}`);
}

export function getAllStores(limit = 10, page = 1, sortOrder = "desc", sortField = "created_at") {
  return axios.get(`${Stores_URL}?limit=${limit}&page=${page}&sortOrder=${sortOrder}&sortField=${sortField}`);
}

export function createStore(store) {
  return axios.post(`${Stores_URL}`, store);
}

export function updateStore(id, store) {
  return axios.patch(`${Stores_URL}/${id}`, store);
}

export function deleteStore(storeId) {
  return axios.delete(`${Stores_URL}/${storeId}`);
}
