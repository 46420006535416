import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import WatchIcon from "../../../../assets/img/watch-icon.svg";
import ArrowDown from "../../../../assets/img/arrow-down.svg";
import { i18n } from "../../../../app/pages/private/languageSelector";
import { useSelector } from "react-redux";

const generateHourLabels = () => {
  return Array.from({ length: 24 }, (_, i) => {
    const hour = i.toString().padStart(2, "0");
    return { value: `${hour}:00`, label: `${hour}:00` };
  });
};

const TimeRangePicker = ({ onTimeRangeChange, showAsDialog = false }) => {
  const [selectedOption, setSelectedOption] = useState("allDay");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const rangeRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (rangeRef.current && !rangeRef.current.contains(event.target)) {
        setIsPickerOpen(false);
      }
    };

    if (isPickerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPickerOpen]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value === "allDay") {
      onTimeRangeChange(null, null);
      setIsPickerOpen(false);
    } else {
      setIsPickerOpen(true);
    }
  };

  const handleStartTimeChange = (selectedOption) => {
    setStartTime(selectedOption);
    localStorage.setItem("timeRange", "");
    onTimeRangeChange(selectedOption?.value, endTime ? endTime?.value : null);
  };

  const handleEndTimeChange = (selectedOption) => {
    setEndTime(selectedOption);
    localStorage.setItem("timeRange", "");
    onTimeRangeChange(startTime ? startTime?.value : null, selectedOption?.value);
    setIsPickerOpen(false);
  };
  let selectedTimeRange = localStorage.getItem("timeRange");
  useEffect(() => {
    if (selectedTimeRange === "allDay") {
      setSelectedOption("allDay");
    }
  }, [selectedTimeRange]);

  const displayValue = () => {
    if (selectedOption === "allDay") {
      return i18n("DASHBOARD.AllDay");
    } else if (startTime && endTime) {
      return `${startTime?.label} - ${endTime?.label}`;
    } else {
      return i18n("DASHBOARD.SelectTimeRange");
    }
  };

  const { language } = useSelector((state) => state.auth);
  return (
    <div ref={rangeRef} className={`custom-time-picker ${language === "arabic" ? "rtl" : "ltr"}`}>
      <div className="selected-range">
        <div
          className={`input-container ${
            showAsDialog || window?.location?.pathname?.includes("invoices" || window?.location?.pathname?.includes("sales-by-time"))
              ? "border-less"
              : ""
          }`}
          onClick={() => setIsPickerOpen(!isPickerOpen)}
        >
          <img src={WatchIcon} alt="" className="icon clock-icon" />
          <input type="text" readOnly value={displayValue()} />
          <img src={ArrowDown} className="icon arrow-icon" />
        </div>
      </div>
      {isPickerOpen && (
        <div
          className={`picker-options ${
            showAsDialog || window?.location?.pathname?.includes("/invoices") || window?.location?.pathname?.includes("sales-by-time")
              ? "custom-options-card"
              : ""
          }`}
        >
          <div className="option">
            <input type="radio" id="allDay" name="timeRange" value="allDay" checked={selectedOption === "allDay"} onChange={handleOptionChange} />
            <label htmlFor="allDay">{i18n("DASHBOARD.AllDay")}</label>
          </div>
          <div className="option">
            <input
              type="radio"
              id="customRange"
              name="timeRange"
              value="customRange"
              checked={selectedOption === "customRange"}
              onChange={handleOptionChange}
            />
            <label htmlFor="customRange">{i18n("DASHBOARD.CustomPeriod")}</label>
          </div>
          {selectedOption === "customRange" && (
            <div className="time-range">
              <div className="time-select">
                <label htmlFor="start" className="ml-4">
                  {i18n("DASHBOARD.Start")}
                </label>
                <Select
                  options={generateHourLabels()}
                  value={startTime}
                  onChange={handleStartTimeChange}
                  placeholder={i18n("DASHBOARD.Start")}
                  className="select"
                />
              </div>
              <div className="time-select">
                <label htmlFor="end" className="ml-4">
                  {i18n("DASHBOARD.End")}
                </label>
                <Select
                  options={generateHourLabels()}
                  value={endTime}
                  onChange={handleEndTimeChange}
                  placeholder={i18n("DASHBOARD.Start")}
                  className="select"
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TimeRangePicker;
