import * as requestFromServer from "./categoryCrud";
import { categoriesSlice, callTypes } from "./categorySlice";
import toast from "react-hot-toast";

const { actions } = categoriesSlice;

export const createCategory = (categoryForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return (
    requestFromServer
      .createCategory(categoryForCreation)
      // .then((response) => {
      //   const { category } = response.data;
      //   dispatch(actions.categoryCreated({ category }));
      //   //   dispatch(fetchCategories(queryParams));
      // })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        error.clientMessage = "Can't create category";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      })
  );
};

export const fetchCategory = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.categoryFetched({ categoryForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCategoryById(id)
    .then((response) => {
      const { category } = response.data;
      dispatch(actions.categoryFetched({ categoryForEdit: category }));
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't find category";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchCategories = (limit = 10, page = 1, sortOrder = "desc", sortField = "created_at") => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllCategories(limit, page, sortOrder, sortField)
    .then((response) => {
      const { categories } = response.data;

      dispatch(
        actions.categoriesFetched({
          categoryForEdit: categories,
        })
      );
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't find categories";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const deleteCategory = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCategory(id)
    .then((response) => {
      toast.success("category deleted");
      dispatch(actions.categoryDeleted({ id }));
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't delete category";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCategory = (category) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCategory(category)
    .then(() => {
      dispatch(actions.categoryUpdated({ category }));
      dispatch(fetchCategories());
      // toast.success("Updated");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't update category";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const assignToCategory = (category, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCategory(category)
    .then(() => {
      dispatch(actions.categoryUpdated({ category }));
      dispatch(fetchCategories(queryParams));
      toast.success("Assigned Successfully");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't update category";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
