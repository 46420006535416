import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { i18n } from "../../../private/languageSelector";
import IconCross from "../../../../../assets/img/cross.svg";
import IconClose from "../../../../../assets/img/icon-close.svg";
import { Input } from "../../../../../_metronic/_partials/controls";
import { PAYMENT_TYPES } from "../../../../../helpers/constants";
import { Card, ReactSelector } from "../../../../../_metronic/_partials/controls";
import DeleteIcon from "../../../../../assets/img/company/Vector.svg";
import SubscriptionDialog from "../SubscriptionDialog";
import { useDispatch } from "react-redux";
import * as actions from "../../../../../_redux/company/companyActions";
export const EditPaymentType = ({
  userAccess,
  savePayments,
  companyForEdit,
  showEditPayment,
  setOpenPaymentTypeDialog,
  edit,
  setShowDeletePaymentTypeModal,
  selectedPaymentType,
  setPage,
  setLimit,
}) => {
  const [newPaymentMethod, setNewPaymentMethod] = useState("");
  const [paymentTypesToShow, setPaymentTypesToShow] = useState([]);
  const [allPaymentTypes, setAllPaymentTypes] = useState([]); // All payment types, including deleted
  const [editedPayment, setEditedPayment] = useState("");
  const [editModeIndex, setEditModeIndex] = useState(-1);
  const [paymentChannelStatus, setpaymentChannelStatus] = useState("Enabled");
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const initialPaymentTypes = companyForEdit?.paymentTypes?.length ? [...companyForEdit.paymentTypes] : [...PAYMENT_TYPES];
    setPaymentTypesToShow(initialPaymentTypes.filter((item) => !item.isDeleted));
    setAllPaymentTypes(initialPaymentTypes);
  }, [companyForEdit]);

  useEffect(() => {
    if (edit && selectedPaymentType?.name) {
      setNewPaymentMethod(selectedPaymentType?.name);
      setpaymentChannelStatus(selectedPaymentType?.active ? "Enabled" : "Disabled");
    } else {
      setNewPaymentMethod("");
      setpaymentChannelStatus("Enabled");
    }
  }, [edit, selectedPaymentType]);

  const addEditedPayment = () => {
    if (edit) {
      if (selectedPaymentType?.name && newPaymentMethod?.toLowerCase()!=="cash") {
        let paymentTypes = allPaymentTypes.map((item) => {
          return {
            ...item,
            isDeleted: item?.businessId === selectedPaymentType?.businessId ? true : item.isDeleted,
          };
        });
        let newPaymentType = {
          name: newPaymentMethod,
          default: false,
          active: paymentChannelStatus === "Disabled" ? false : true,
          isDeleted: false,
        };
        let allPayments = [newPaymentType, ...paymentTypes];
        let paymentData = {
          _id: companyForEdit?._id,
          paymentTypes: allPayments,
        };
        dispatch(actions.updateCompany(paymentData)).then(() => {
          toast.success("Payment channel updated");
          dispatch(actions.CompanyFetch()).then(() => {
            setPaymentTypesToShow(companyForEdit?.paymentTypes?.filter((item) => !item.isDeleted));
            setPage(1);
          });
        });
      }
    } else {
      toast.error("Enter a valid payment method");
    }
  };

  const addPaymentHandler = () => {
    if (newPaymentMethod && companyForEdit && companyForEdit._id && newPaymentMethod?.toLowerCase() !== "cash") {
      const newPayment = {
        name: newPaymentMethod,
        default: false,
        active: paymentChannelStatus === "Disabled" ? false : true,
        isDeleted: false,
      };

      let allPayments = [newPayment, ...allPaymentTypes].map((item, index) => {
        return { ...item, sortIndex: index + 1 };
      });

      const paymentData = {
        _id: companyForEdit._id,
        paymentTypes: allPayments,
      };
      dispatch(actions.updateCompany(paymentData)).then(() => {
        toast.success("Payment channel created");
        dispatch(actions.CompanyFetch());
        setPage(1);
      });
      setNewPaymentMethod("");
      setOpenPaymentTypeDialog(false);
    } else {
      if (newPaymentMethod?.toLocaleLowerCase() === "cash") {
        toast.error("Duplicate payment channel");
      } else {
        toast.error("Enter Payment Channel Name");
      }
    }
  };
  const defaultType = [{ label: "Enabled" }, { label: "Disabled" }];

  const handleClose = (dirty, resetForm) => {
    if (dirty || (edit && newPaymentMethod !== selectedPaymentType.name ? true : false || (!edit && newPaymentMethod?.length > 0))) {
      setShowUnsavedChangesModal(true);
    } else {
      setOpenPaymentTypeDialog(false);
      resetForm();
      setNewPaymentMethod("");
    }
  };
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...companyForEdit,
          status: {
            label: edit ? (selectedPaymentType?.status === "Active" ? "Enabled" : "Disabled") : "Enabled",
            value: edit ? (selectedPaymentType?.status === "Active" ? "Enabled" : "Disabled") : "Enabled",
          },
        }}
        onSubmit={(values) => {
          edit ? addEditedPayment(values) : addPaymentHandler();
        }}
      >
        {({ handleSubmit, setFieldValue, values, dirty, resetForm }) => {
          if (values.status && newPaymentMethod) {
            setIsFormFilled(true);
          } else setIsFormFilled(false);
          return (
            <Modal
              size="md"
              className="modal-drawer invoice-drawer"
              show={showEditPayment}
              onHide={() => handleClose(dirty, resetForm)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header>
                <div className="d-flex justify-content-around align-items-center">
                  <span onClick={() => handleClose(dirty, resetForm)}>
                    {" "}
                    <img src={IconClose} alt="loading" />
                  </span>
                  <span className="add-edit-tax">{edit ? "Edit Payment Channel" : "Add Payment Channel"}</span>
                  <span
                    className="add-edit-tax cursor-pointer"
                    style={{
                      color: isFormFilled && "#FFFFFF",
                      opacity: isFormFilled ? 1 : 0.5,
                    }}
                    onClick={() => {
                      handleSubmit();
                      setOpenPaymentTypeDialog(false);
                    }}
                  >
                    SAVE
                  </span>
                </div>
              </Modal.Header>
              <Modal.Body className="overlay overlay-block cursor-default">
                <Form className="form form-label-right">
                  <div>
                    <Card>
                      <div className="form-group">
                        <Field
                          label="Payment Channel"
                          name="newPaymentType"
                          component={Input}
                          placeholder="Payment Channel"
                          withFeedbackLabel={true}
                          value={newPaymentMethod}
                          onChange={(e) => setNewPaymentMethod(e.target.value)}
                        />
                      </div>
                      <div className="invoice-items row">
                        <div className="col-12 ">
                          <div className="form-group input-select w-100 relative">
                            <div
                              style={{
                                fontSize: "10px",
                                color: "#828282",
                                position: "absolute",
                                top: "0px",
                                zIndex: "1001",
                              }}
                            >
                              Status
                            </div>
                            <Field
                              name="status"
                              placeholder="Status"
                              component={ReactSelector}
                              options={defaultType.map((item) => ({
                                value: item.label,
                                label: item.label,
                              }))}
                              onChange={(option) => {
                                setpaymentChannelStatus(option.label);
                                setFieldValue("status", option);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Card>

                    {edit && (
                      <Card>
                        <div className="cursor-pointer text-center w-100 mx-auto" onClick={() => setShowDeletePaymentTypeModal(true)}>
                          <img src={DeleteIcon} alt="Delete" width={18} height={20} />
                        </div>
                      </Card>
                    )}
                  </div>

                  {/* Unsaved changes modal */}
                  <SubscriptionDialog
                    modalWidth={"350px"}
                    modalHeight={"185px"}
                    modalBackgroundColor={"#FFFFFF"}
                    show={showUnsavedChangesModal}
                    onHide={() => setShowUnsavedChangesModal(false)}
                    title={i18n("SubscriptionModals.UnsavedChanges")}
                    titleWidth={"140px"}
                    titleHeight={"20px"}
                    titleFontWeight={"700"}
                    titleFontSize={"16px"}
                    titleLineHeight={"20px"}
                    titleColor={"#333333"}
                    message={i18n("SubscriptionModals.AreYouSureYouWantToContinueWithoutSavingChanges")}
                    messageWidth={"320px"}
                    messageHeight={"44px"}
                    messageFontSize={"16px"}
                    messageFontWeight={"400"}
                    messageLineHeight={"21.79px"}
                    messageColor={"#333333"}
                    primaryLabel={i18n("SubscriptionModals.CANCEL")}
                    primaryAction={() => setShowUnsavedChangesModal(false)}
                    primaryLabelWidth={"55px"}
                    primaryLabelHeight={"20px"}
                    primaryLabelFontWeight={"700"}
                    primaryLabelFontSize={"14px"}
                    primaryLabelLineHeight={"20px"}
                    primaryLabelColor={"#828282"}
                    secondaryAction={() => {
                      setShowUnsavedChangesModal(false);
                      setOpenPaymentTypeDialog(false);
                      setIsFormFilled(false);
                      resetForm();
                      setNewPaymentMethod("");
                    }}
                    secondaryLabel={i18n("SubscriptionModals.CONTINUE")}
                    secondaryLabelWidth={"75px"}
                    secondaryLabelHeight={"20px"}
                    secondaryLabelFontWeight={"700"}
                    secondaryLabelFontSize={"14px"}
                    secondaryLabelLineHeight={"20px"}
                    secondaryLabelColor={"#2D9CDB"}
                    isCenteredOnMobile
                  />
                </Form>
              </Modal.Body>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
