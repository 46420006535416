import React, { useMemo, useEffect, useState } from "react";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import { CustomersFilter } from "./customers-filter/CustomersFilter";
import { CustomersTable } from "./customers-table/CustomersTable";
import { useCustomersUIContext } from "./CustomersUIContext";

export function CustomersCard() {
  const [groupActions, setGroupActions] = useState(false);

  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      setIds: customersUIContext.setIds,
      newCustomerButtonClick: customersUIContext.newCustomerButtonClick,
    };
  }, [customersUIContext]);

  useEffect(() => {
    if (customersUIProps.ids.length > 0) {
      setGroupActions(true);
    } else {
      setGroupActions(false);
    }
  }, [customersUIProps]);

  return (
    <>
      {/* <CustomersFilter
        groupActions={groupActions}
        setGroupActions={setGroupActions}
        selectedCustomers={customersUIProps.ids}
        setSelectedCustomers={customersUIProps.setIds}
      /> */}
      <Card className="p-0">
        <CardBody className="p-0">
          <CustomersTable />
        </CardBody>
      </Card>
    </>
  );
}
