// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Dropdown } from "react-bootstrap";
export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { openEditProductDialog, openDeleteProductDialog, openViewProductDialog, openViewProductActionDialog }
) {
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        openViewProductActionDialog(row?._id);
      }}
    >
      <i className="fas fa-ellipsis-v" style={{ marginRight: "36px" }}></i>
      {children}
    </a>
  ));
  return (
    <>
      <div>
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} variant="success" id="dropdown-basic"></Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => openViewProductDialog(row._id)}>View </Dropdown.Item>
            <Dropdown.Item onClick={() => openEditProductDialog(row._id)}>Edit </Dropdown.Item>
            <Dropdown.Item onClick={() => openDeleteProductDialog(row._id)}>Delete </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {/* <a
        title="View product"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        onClick={() => openViewProductDialog(row._id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")}
          />
        </span>
      </a>

      <a
        title="Edit product"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        onClick={() => openEditProductDialog(row._id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
          />
        </span>
      </a>

      <a
        title="Delete product"
        className="btn btn-icon btn-light btn-hover-danger btn-sm"
        onClick={() => openDeleteProductDialog(row._id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
        </span>
      </a> */}
    </>
  );
}
