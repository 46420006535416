import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import IconClose from "../../../../../assets/img/icon-close.svg";
import IconTrash from "../../../../../assets/img/icon-trash.svg";
import IconPen from "../../../../../assets/img/icon-pen.svg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as storesActions from "../../../../../_redux/stores/storeActions";
import ConfirmationDialoge from "../../../confirmationDialoge";
import { StoresEditForm } from "./StoresEditForm";

export const ManageStores = ({ stores, userAccess, openStoresManageDialog, setOpenStoresManageDialog }) => {
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);
  const [deleteStoreId, setDeleteStoreId] = useState("");
  const [openStoresAddDialog, setOpenStoresAddDialog] = useState(false);
  const [confirmDialogForClose, setConfirmDialogForClose] = useState(false);
  const [confirmDialogForDelete, setConfirmDialogForDelete] = useState(false);

  const { storeForEdit } = useSelector(
    (state) => ({
      storeForEdit: state.stores?.storeForEdit,
    }),
    shallowEqual
  );

  const deleteStoreHandler = () => {
    dispatch(storesActions.deleteStore(deleteStoreId))
      .then((res) => {
        setConfirmDialogForClose(false);
        dispatch(storesActions.fetchStores());
      })
      .catch((error) => {
        error.clientMessage = "Can't delete store";
        setConfirmDialogForDelete(true);
      });
  };

  return (
    <>
      <Modal
        className="modal-drawer"
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={openStoresManageDialog}
        onHide={() => {}}>
        <Modal.Header>
          <button type="button" className="btn btn-close" onClick={() => setOpenStoresManageDialog(false)}>
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title>Manage Store</Modal.Title>
        </Modal.Header>
        <Formik>
          {({ handleSubmit }) => {
            return (
              <Modal.Body className="overlay overlay-block cursor-default">
                <Form className="form form-label-right">
                  <ul className="card-listitem list-unstyled">
                    <div className="h4">
                      <b>Stores</b>
                    </div>
                    {stores?.length
                      ? stores.map((store, i) => {
                          return (
                            <li key={i}>
                              <div className="card-info">
                                <b className="heading-color truncate-160 h5">
                                  {store.name ? store.name : store.storeId}
                                </b>
                                <p className="sub-heading-color mb-0">{store.address}</p>
                                <p className="sub-heading-color mb-0">{store.mobile}</p>
                              </div>
                              <div className="card-actions ml-auto">
                                {userAccess?.editData ? (
                                  <span className="action action-edit">
                                    <img
                                      title="Edit"
                                      src={IconPen}
                                      alt="loading"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        dispatch(storesActions.fetchStore(store._id));
                                        setOpenStoresAddDialog(true);
                                        setEdit(true);
                                      }}
                                    />
                                  </span>
                                ) : (
                                  ""
                                )}
                                {userAccess?.deleteData ? (
                                  <span className="action action-delete">
                                    <img
                                      title="Delete"
                                      style={{ cursor: "pointer" }}
                                      src={IconTrash}
                                      alt="loading"
                                      onClick={() => {
                                        setDeleteStoreId(store._id);
                                        setConfirmDialogForClose(true);
                                      }}
                                    />
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </li>
                          );
                        })
                      : ""}
                  </ul>
                </Form>
              </Modal.Body>
            );
          }}
        </Formik>
        <>
          <Modal.Footer>
            <div className="row justify-content-center">
              <button
                className="btn btn-outline-primary btn-elevate mr-5"
                onClick={() => {
                  setOpenStoresManageDialog(false);
                }}>
                Back
              </button>
              {userAccess?.createData ? (
                <button
                  className="btn btn-primary btn-elevate f-button"
                  onClick={() => {
                    setOpenStoresAddDialog(true);
                    setEdit(false);
                  }}>
                  Add New
                </button>
              ) : (
                ""
              )}
            </div>
          </Modal.Footer>
        </>
      </Modal>
      {/* <StoresEditForm
        edit={edit}
        store={edit ? storeForEdit : undefined}
        openStoresAddDialog={openStoresAddDialog}
        setOpenStoresAddDialog={setOpenStoresAddDialog}
      /> */}
      <ConfirmationDialoge
        show={confirmDialogForClose}
        title="Delete Store"
        subTitle="Are you sure you want to delete this store"
        primaryAction={() => deleteStoreHandler()}
        secondaryAction={() => setConfirmDialogForClose(false)}
        primaryActionTitle="Delete"
        secondaryActionTitle="Back"
        classes="modal-drawer"
        heading="Delete Store!"
        headingColor="#ED553B"
        viewButon={true}
      />
      <ConfirmationDialoge
        show={confirmDialogForDelete}
        secondaryAction={() => {
          setConfirmDialogForDelete(false);
          setConfirmDialogForClose(false);
        }}
        title="Delete Store"
        subTitle="You cannot delete a store that has one or more POS."
        classes="modal-drawer"
        heading="Can't Delete Store"
        headingColor="#ED553B"
        viewButon={false}
        buttonText={"Back"}
      />
    </>
  );
};
