import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import PageNavigator from "../../../components/pagination/PageNavigator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import NoPosIcon from "../../../../assets/img/company/NoPos.svg";
import DeleteIcon from "../../../../assets/img/company/Vector.svg";
import { i18n } from "../../private/languageSelector";
import SubscriptionDialog from "../company-edit-Dialog/SubscriptionDialog";
import { PosEditForm } from "../company-edit-Dialog/pos/PosEditForm";
import { deletePos, fetchAllPos } from "../../../../_redux/pos/posActions";
import CustomSearch from "../../../components/searchBar/CustomSearch";
import { sortCaret } from "../../../../_metronic/_helpers";

const Pos = () => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.auth);
  const { pos } = useSelector(
    (state) => ({
      pos: state.pos?.entities,
    }),
    shallowEqual
  );

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openPosAddDialog, setOpenPosAddDialog] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedPos, setSelectedPos] = useState(null);
  const [showDeletePosModal, setShowDeletePosModal] = useState(false);
  const [sortBy, setSortBy] = useState("desc");
  const [sortAt, setSortAt] = useState("created_at");

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };

  const handleEditPosClick = (row) => {
    setEdit(true);
    setSelectedPos(row);
    setOpenPosAddDialog(true);
  };

  const highlightMatch = (text, query) => {
    if (!query) return text;
    const parts = text.split(new RegExp(`(${query})`, "gi"));
    return parts.map((part, index) => (part.toLowerCase() === query.toLowerCase() ? <b key={index}>{part}</b> : part));
  };

  useEffect(() => {
    dispatch(fetchAllPos(limit, page, sortBy, sortAt));
  }, [dispatch, limit, page, sortBy, sortAt]);

  const columns = [
    {
      dataField: "name",
      text: "name",
      headerAlign: "start",
      headerStyle: {
        textAlign: "left",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      align: language === "arabic" ? "start" : "left",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
      formatter: (cell) => highlightMatch(cell, searchValue),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "store",
      text: "Store",
      align: language === "arabic" ? "start" : "center",
      headerStyle: {
        textAlign: "center",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
      formatter: (cell) => {
        const storeName = cell?.name || "N/A";
        return highlightMatch(storeName, searchValue);
      },
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "isActive",
      text: "Status",
      align: language === "arabic" ? "left" : "right",
      headerStyle: {
        textAlign: "right",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
      formatter: (cell) => {
        const statusText = cell ? "Active" : "Inactive";
        return highlightMatch(statusText, searchValue);
      },
      sort: true,
      sortCaret: sortCaret,
    },
  ];

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    onSelect: (row, isSelected) => {
      const newSelectedRows = isSelected ? [...selectedRows, row._id] : selectedRows.filter((id) => id !== row._id);
      setSelectedRows(newSelectedRows);
      return true;
    },
    onSelectAll: (isSelected, rows) => {
      const newSelectedRows = isSelected ? rows.map((row) => row._id) : [];
      setSelectedRows(newSelectedRows);
    },
  };

  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  if (!pos) {
    return null;
  }

  const confirmDelete = () => {
    if (selectedRows.length > 0 && !openPosAddDialog) {
      for (let row of selectedRows) {
        dispatch(deletePos(row));
      }
      setSelectedRows([]);
      setShowDeletePosModal(false);
    } else if (selectedPos?._id) {
      dispatch(deletePos(selectedPos._id));
      setSelectedRows([]);
      setShowDeletePosModal(false);
      setOpenPosAddDialog(false);
    }
  };

  let filteredPos = pos?.filter((item) => {
    let searchText = searchValue?.toLowerCase();
    return item?.name?.toLowerCase()?.includes(searchText);
  });

  const handleSorting = (type, sortOrder, sortField, data, page, sizePerPage) => {
    if (type === "sort") {
      setSortBy(sortOrder);
      setSortAt(sortField);
    }
  };
  return (
    <>
      <div className="table-width" style={{ backgroundColor: "#FFFFFF", borderRadius: "0.42rem" }}>
        <div className="pl-4 pr-4 py-4 mb-3 d-flex justify-content-between align-items-center">
          <CustomSearch
            handleSearchInputChange={handleSearchInputChange}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setShowSearchInput={setShowSearchInput}
            showSearchInput={showSearchInput}
          />
          <div>
            {selectedRows.length > 0 && (
              <span className="mr-5 cursor-pointer" onClick={() => setShowDeletePosModal(true)}>
                <img src={DeleteIcon} alt="Delete" />
              </span>
            )}
            <span
              className="add-text-btn"
              onClick={() => {
                setEdit(false);
                setSelectedPos(null);
                setOpenPosAddDialog(true);
              }}
            >
              ADD POS
            </span>
          </div>
        </div>
        <BootstrapTable
          wrapperClasses="table-responsive"
          classes="table table-head-custom table-vertical-center overflow-hidden"
          remote
          bordered={false}
          bootstrap4
          keyField="_id"
          columns={columns}
          data={filteredPos}
          selectRow={selectRow}
          rowClasses="cursor-pointer"
          onTableChange={(type, { page, sizePerPage, sortField, sortOrder, data }) =>
            handleSorting(type, sortOrder, sortField, data, page, sizePerPage)
          }
          rowEvents={{
            onClick: (e, row, rowIndex) => {
              if (e.target.type !== "checkbox") {
                handleEditPosClick(row);
              }
            },
          }}
        />

        {pos && pos?.length < 1 && (
          <div className="d-flex flex-column justify-content-center align-items-center mt-5 mb-5">
            <img src={NoPosIcon} alt="NoTaxIcon" />
            <p className="py-3 you-have-no-taxes">You have no POS devices yet</p>
          </div>
        )}

        <div className="pb-3 d-flex justify-content-end mr-3 ml-3">
          <PageNavigator page={page} limit={limit} handlePageChange={handlePageChange} handleLimitChange={handleLimitChange} data={filteredPos} />
        </div>
      </div>
      <PosEditForm
        openPosAddDialog={openPosAddDialog}
        setOpenPosAddDialog={setOpenPosAddDialog}
        edit={edit}
        pos={selectedPos}
        setShowDeletePosModal={setShowDeletePosModal}
      />

      {/* delete user modal  */}
      <SubscriptionDialog
        show={showDeletePosModal}
        onHide={() => setShowDeletePosModal(false)}
        modalWidth="350px"
        modalHeight="185px"
        message={
          selectedRows.length === 1 ? "Are you sure you want to delete this POS device?" : "Are you sure you want to delete selected POS devices?"
        }
        messageWidth="320px"
        messageHeight="44px"
        messageColor="#333333"
        messageFontWeight="400"
        messageFontSize="16px"
        messageLineHeight="21.79px"
        primaryAction={() => setShowDeletePosModal(false)}
        primaryLabel={i18n("SubscriptionModals.CANCEL")}
        primaryLabelWidth="55px"
        primaryLabelHeight="20px"
        primaryLabelFontWeight="700"
        primaryLabelFontSize="14px"
        primaryLabelLineHeight="20px"
        primaryLabelColor="#828282"
        secondaryAction={confirmDelete}
        secondaryLabel={"DELETE"}
        secondaryLabelWidth="67px"
        secondaryLabelHeight="20px"
        secondaryLabelFontWeight="700"
        secondaryLabelFontSize="14px"
        secondaryLabelLineHeight="20px"
        secondaryLabelColor="#EB5757"
        isCenteredOnMobile
      />
    </>
  );
};

export default Pos;
