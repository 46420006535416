import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "../../../../assets/img/company/SearchIcon.svg";
import MenuIcon from "../../../../assets/img/menu-burger.svg";
import BootstrapTable from "react-bootstrap-table-next";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import PageNavigator from "../../../components/pagination/PageNavigator";
import toast from "react-hot-toast";
// import PaymentTypeDialog from "../company-edit-Dialog/payments/paymentTypes";
import * as actions from "../../../../_redux/company/companyActions";
import DeleteIcon from "../../../../assets/img/company/Vector.svg";
import SubscriptionDialog from "../company-edit-Dialog/SubscriptionDialog";
import { i18n } from "../../private/languageSelector";
// import { PAYMENT_TYPES } from "../../../../helpers/constants";
import { EditPaymentType } from "../company-edit-Dialog/payments/EditPaymentType";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import "bootstrap/dist/css/bootstrap.min.css";
import CustomSearch from "../../../components/searchBar/CustomSearch";
import { sortCaret } from "../../../../_metronic/_helpers";

function PaymentChannels() {
  const [searchValue, setSearchValue] = useState("");
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const searchInputRef = useRef(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [openPaymentTypeDialog, setOpenPaymentTypeDialog] = useState(false);
  const [showDeletePaymentTypeModal, setShowDeletePaymentTypeModal] = useState(false);
  const [paymentTypesToShow, setPaymentTypesToShow] = useState([]);
  const [edit, setEdit] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);
  const { language } = useSelector((state) => state.auth);
  const [paymentBusinessIds, setPaymentBusinessIds] = useState([]);
  const [sortBy, setSortBy] = useState("desc");
  const [sortField, setSortField] = useState("businessId");
  const [data, setData] = useState([]);

  // const [, set] = useState()
  const dispatch = useDispatch();

  const { userAccess, companyForEdit } = useSelector(
    (state) => ({
      userAccess: state?.users?.userAccess,
      companyForEdit: state.company.companyForEdit,
    }),
    shallowEqual
  );

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };

  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchInputRef.current && !searchInputRef.current.contains(event.target) && searchValue === "") {
        setShowSearchInput(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchValue]);

  const highlightMatch = (text, query) => {
    if (!query) return text;
    const parts = text.split(new RegExp(`(${query})`, "gi"));
    return parts.map((part, index) => (part.toLowerCase() === query.toLowerCase() ? <b key={index}>{part}</b> : part));
  };

  const saveCompany = (company) => {
    const toastId = toast.loading("Loading...");
    if (companyForEdit && companyForEdit._id) {
      dispatch(actions.updateCompany(company)).then(() => {
        //   requestUser(user);
        toast.dismiss(toastId);
      });
    }
  };
  useEffect(() => {
    if (companyForEdit?.paymentTypes?.length) {
      let refactoredPaymentTypes = companyForEdit?.paymentTypes
        ?.filter((item) => !item.isDeleted && item?.name !== "Cash")
        ?.map((item, index) => {
          return {
            ...item,
            _id: index + 1,
            status: item.active ? "Active" : "In Active",
            icon: <img src={MenuIcon} width={18} height={12} />,
          };
        })
        ?.sort((a, b) => (sortBy === "asc" ? a[sortField]?.localeCompare(b?.sortField) : b[sortField]?.localeCompare(a?.sortField)));
      setPaymentTypesToShow([...refactoredPaymentTypes]);
    }
  }, [companyForEdit]);

  const removePaymentHandler = () => {
    let updatedPayments;
    if (selectedPaymentType?.businessId && openPaymentTypeDialog) {
      updatedPayments = companyForEdit?.paymentTypes?.map((item) => {
        if (selectedPaymentType?.businessId === item.businessId) {
          return {
            ...item,
            isDeleted: true,
          };
        }
        return item;
      });
    } else if (selectedRows?.length > 0) {
      let toDelete = [];
      let ids = [];
      let selected = paymentBusinessIds?.map((subItem) => {
        let finded = companyForEdit?.paymentTypes?.find((item) => item?.businessId === subItem);
        if (finded) {
          toDelete.push({ ...finded, isDeleted: true });
          ids.push(finded?.businessId);
        }
      });
      let nonDeleted = companyForEdit?.paymentTypes?.filter((item) => !item.isDeleted && !ids?.includes(item?.businessId));
      let existingDeleted = companyForEdit?.paymentTypes?.filter((item) => item.isDeleted && !ids?.includes(item?.businessId));
      updatedPayments = [...nonDeleted, ...toDelete, ...existingDeleted];
    }

    let paymentData = {
      _id: companyForEdit?._id,
      paymentTypes: updatedPayments,
    };

    dispatch(actions.updateCompany(paymentData))
      .then(() => {
        dispatch(actions.CompanyFetch());
      })
      .then(() => {
        setPaymentTypesToShow(updatedPayments?.filter((item) => !item.isDeleted));
        setData(updatedPayments?.filter((item) => !item.isDeleted && item?.name !== "Cash")?.sort((a, b) => a.sortIndex - b.sortIndex));
      });
    setShowDeletePaymentTypeModal(false);
    setOpenPaymentTypeDialog(false);
    setSelectedRows([]);
    setPaymentBusinessIds([]);
    setPage(1);
  };
  const columns = [
    {
      dataField: "name",
      text: "name",
      headerAlign: "start",
      headerStyle: {
        textAlign: "left",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      align: language === "arabic" ? "start" : "left",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
      formatter: (cell) => <div>{highlightMatch(cell, searchValue)}</div>,
    },
    {
      dataField: "status",
      text: "status",
      align: language === "arabic" ? "start" : "center",
      headerStyle: {
        textAlign: "center",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
        paddingRight: "0px",
      },
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
      formatter: (cell) => <div style={{ color: cell === "In Active" && "#EB5757" }}>{cell}</div>,
    },
    {
      dataField: "icon",
      text: "",
      align: language === "arabic" ? "left" : "right",
      headerStyle: {
        textAlign: "right",
        fontWeight: "400",
        fontSize: "14px",
        color: "#828282",
      },
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
      formatter: (cell) => <div>{cell}</div>,

      // formatter: (cell, row, rowIndex, colIndex) => (
      //   <Draggable key={row._id} draggableId={row._id?.toString()} index={rowIndex}>
      //     {(provided, snapshot) => (
      //       <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
      //         <div className={`${snapshot.isDragging ? "dragging-row" : ""}`}>{cell}</div>
      //       </div>
      //     )}
      //   </Draggable>
      // ),
    },
  ];

  let filterPaymentTypes =
    paymentTypesToShow &&
    paymentTypesToShow.map((item, index) => {
      return {
        ...item,
        Users: item?.users?.length,
        Access:
          item.pos === true && item.backOffice === true
            ? "Back office & POS"
            : item.pos === true && item.backOffice === false
            ? "POS"
            : item.pos === false && item.backOffice === true
            ? "Back office"
            : "",
      };
    });

  filterPaymentTypes = filterPaymentTypes?.filter((item) => {
    let searchText = searchValue?.toLowerCase();
    return item?.name?.toLowerCase()?.includes(searchText);
  });

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    onSelect: (row, isSelected) => {
      const newSelectedRows = isSelected ? [...selectedRows, row._id] : selectedRows.filter((id) => id !== row._id);
      setSelectedRows(newSelectedRows);
      let uniqueIds = paymentBusinessIds.filter((item) => item !== row?.businessId);
      let newIds = isSelected ? [...paymentBusinessIds, row?.businessId] : uniqueIds;
      setPaymentBusinessIds(newIds);
      return true;
    },
    onSelectAll: (isSelected, rows) => {
      const newSelectedRows = isSelected ? rows.map((row) => row._id) : [];
      setSelectedRows(newSelectedRows);
      let newIds = isSelected ? rows.map((item) => item?.businessId) : [];
      setPaymentBusinessIds(newIds);
    },
  };
  const handleEditPaymentTypeClick = (row) => {
    setEdit(true);
    setSelectedPaymentType(row);
    setOpenPaymentTypeDialog(true);
  };

  useEffect(() => {
    let skip = (page - 1) * limit;
    let data = filterPaymentTypes?.slice(skip, limit);
    setData(data?.sort((a, b) => a.sortIndex - b.sortIndex));
  }, [companyForEdit, edit, paymentTypesToShow, searchValue]);

  const onDragEnd = async (result) => {
    if (!result.destination) return;
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    // Reorder the rows
    const reorderedData = Array.from(data);
    const [removed] = reorderedData.splice(sourceIndex, 1);
    reorderedData.splice(destinationIndex, 0, removed);

    // Update the sortIndex for each item
    const updatedData = reorderedData.map((item, index) => ({
      ...item,
      sortIndex: index,
    }));

    setData(updatedData?.sort((a, b) => a.sortIndex - b.sortIndex));
    dispatch(
      actions.updateCompanyForPayment({
        paymentTypes: updatedData?.sort((a, b) => a.sortIndex - b.sortIndex),
        _id: companyForEdit?._id,
      })
    );
  };

  const cellFormatter = (cell, row, rowIndex, colIndex) => (
    <Draggable key={row?.sortIndex} draggableId={row.sortIndex?.toString()} index={rowIndex}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          style={{
            ...provided.draggableProps.style,
            cursor: cell?.type === "img" ? "grab" : "default",
            color: cell === "In Active" ? "#EB5757" : undefined,
          }}
        >
          {!snapshot?.isDragging &&
            (cell?.type === "img" ? <img src={cell.props?.src} width={cell.props?.width} height={cell.props?.height} alt="icon" /> : cell)}
        </div>
      )}
    </Draggable>
  );

  // const cellFormatter = (cell, row, rowIndex, colIndex) => (
  //   <Draggable
  //     key={row?.sortIndex}
  //     draggableId={row.sortIndex?.toString()}
  //     index={rowIndex}
  //   >
  //     {(provided, snapshot) => (
  //       <div
  //         {...provided.draggableProps}
  //         {...provided.dragHandleProps}
  //         ref={provided.innerRef}
  //         style={{
  //           ...provided.draggableProps.style,
  //           cursor: cell?.type === "img" ? "grab" : "cursor",
  //           color: cell === "In Active" && "#EB5757",
  //         }}
  //       >
  //         {!snapshot?.isDragging && cell}
  //       </div>
  //     )}
  //   </Draggable>
  // );

  const getPaymentChannelByPageAndLimit = () => {
    const skip = (page - 1) * limit;
    const total = companyForEdit?.paymentTypes?.length;
    let paginatedChannels =
      skip === limit
        ? paymentTypesToShow?.slice(skip, skip + limit)
        : skip < total && page > 1
        ? paymentTypesToShow?.slice(skip, total)
        : paymentTypesToShow?.slice(skip, limit);
    setData(paginatedChannels?.sort((a, b) => a.sortIndex - b.sortIndex));
  };

  useEffect(() => {
    getPaymentChannelByPageAndLimit();
  }, [page, limit]);

  const handleEmptySearch = () => {
    setSearchValue("");
    setPage(1);
  };

  const handleSorting = (type, sortOrder, sortField, data) => {
    if (type === "sort") {
      if (sortField === "name") {
        setSortField("name");
        if (sortOrder === "asc") {
          setSortBy("asc");
          return data?.sort((a, b) => a?.name?.localeCompare(b?.name));
        } else {
          setSortBy("desc");
          return data?.sort((a, b) => b?.name?.localeCompare(a?.name));
        }
      } else if (sortField === "status") {
        setSortField("status");
        if (sortOrder === "asc") {
          setSortBy("asc");
          return data?.sort((a, b) => a?.status?.localeCompare(b?.status));
        } else {
          setSortBy("desc");
          return data?.sort((a, b) => b?.status?.localeCompare(a?.status));
        }
      }
    }
  };

  if (!data) {
    return null;
  }
  return (
    <>
      <div className="table-width" style={{ backgroundColor: "#FFFFFF", borderRadius: "0.42rem" }}>
        <div className="d-flex justify-content-between py-4 ml-2 px-2  mb-3 mr-3 align-items-center">
          {/* <div style={{ cursor: "pointer" }} className="d-flex justify-content-between align-items-center">
            <img onClick={() => setShowSearchInput(!showSearchInput)} src={SearchIcon} alt="SearchImage" />
            {showSearchInput && (
              <div ref={searchInputRef} className="position-relative">
                <input
                  style={{
                    width: "400px",
                    border: "none",
                    borderBottom: "2px solid #2D9CDB",
                    borderRadius: "0",
                  }}
                  className="form-control mr-sm-2 ml-3"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  value={searchValue}
                  onChange={handleSearchInputChange}
                />
              </div>
            )}
          </div> */}
          <CustomSearch
            handleSearchInputChange={handleSearchInputChange}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setShowSearchInput={setShowSearchInput}
            showSearchInput={showSearchInput}
            handleEmpty={handleEmptySearch}
          />

          <div>
            {selectedRows.length > 0 && (
              <span className="mr-5 cursor-pointer" onClick={() => setShowDeletePaymentTypeModal(true)}>
                <img src={DeleteIcon} alt="Delete" />
              </span>
            )}
            <span
              className="add-text-btn"
              onClick={() => {
                setEdit(false);
                setSelectedPaymentType(null);
                setOpenPaymentTypeDialog(true);
              }}
            >
              ADD PAYMENT CHANNEL
            </span>
          </div>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="table-body" direction="vertical">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  classes="table  table-head-custom table-vertical-center overflow-hidden"
                  remote
                  bordered={false}
                  bootstrap4
                  keyField="_id"
                  // columns={columns}
                  data={
                    data === null ? [] : data
                    //?.sort((a, b) => a._id - b._id)?.map((item) => item)
                  }
                  selectRow={selectRow}
                  columns={columns.map((col) => ({
                    ...col,
                    formatter: cellFormatter,
                    sortCaret: sortCaret,
                    sort: col?.dataField === "icon" ? false : true,
                    headerAlign: "start",
                    headerStyle: {
                      textAlign: "left",
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#828282",
                      paddingLeft: ".75rem",
                    },
                    align: language === "arabic" ? "start" : "left",
                    style: {
                      paddingTop: "15px",
                      paddingBottom: "15px",
                      paddingLeft: ".75rem",
                    },
                  }))}
                  hover
                  // onSort={(a, b, order) => handleSorting(a, b, order)}
                  onTableChange={(type, { page, sizePerPage, sortField, sortOrder, data }) =>
                    handleSorting(type, sortOrder, sortField, data, page, sizePerPage)
                  }
                  // condensed
                  rowClasses={snapshot.isDragging && "dragging-row"}
                  rowEvents={{
                    onClick: (e, row, rowIndex) => {
                      if (e.target.type !== "checkbox") {
                        handleEditPaymentTypeClick(row);
                      }
                    },
                  }}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div className="pb-3 d-flex justify-content-end mr-3 ml-3">
          <PageNavigator page={page} limit={limit} handlePageChange={handlePageChange} handleLimitChange={handleLimitChange} data={data} />
        </div>
      </div>
      {/* <RolesEditForm
        openRolesAddDialog={openRolesAddDialog}
        setOpenPaymentTypeDialog={setOpenPaymentTypeDialog}
        edit={edit}
        roleForEdit={selectedRole}
      /> */}
      {/* <PaymentTypeDialog
        userAccess={userAccess}
        companyForEdit={companyForEdit}
        openPaymentTypeDialog={openPaymentTypeDialog}
        secondaryAction={() => {
          setOpenPaymentTypeDialog(false);
        }}
        savePayments={saveCompany}
      /> */}

      <EditPaymentType
        userAccess={userAccess}
        savePayments={saveCompany}
        companyForEdit={companyForEdit}
        showEditPayment={openPaymentTypeDialog}
        setOpenPaymentTypeDialog={setOpenPaymentTypeDialog}
        edit={edit}
        setShowDeletePaymentTypeModal={setShowDeletePaymentTypeModal}
        selectedPaymentType={selectedPaymentType}
        setPage={setPage}
        setLimit={setLimit}
      />

      {/* delete role modal  */}
      <SubscriptionDialog
        show={showDeletePaymentTypeModal}
        onHide={() => {
          setShowDeletePaymentTypeModal(false);
          setSelectedPaymentType(null);
        }}
        modalWidth="350px"
        modalHeight="185px"
        message={
          selectedRows.length === 1
            ? "Are you sure you want to delete the selected Payment Channel?"
            : "Are you sure you want to delete these Payment Channels?"
        }
        messageWidth="320px"
        messageHeight="44px"
        messageColor="#333333"
        messageFontWeight="400"
        messageFontSize="16px"
        messageLineHeight="21.79px"
        primaryAction={() => setShowDeletePaymentTypeModal(false)}
        primaryLabel={i18n("SubscriptionModals.CANCEL")}
        primaryLabelWidth="55px"
        primaryLabelHeight="20px"
        primaryLabelFontWeight="700"
        primaryLabelFontSize="14px"
        primaryLabelLineHeight="20px"
        primaryLabelColor="#828282"
        secondaryAction={removePaymentHandler}
        secondaryLabel={"DELETE"}
        secondaryLabelWidth="67px"
        secondaryLabelHeight="20px"
        secondaryLabelFontWeight="700"
        secondaryLabelFontSize="14px"
        secondaryLabelLineHeight="20px"
        secondaryLabelColor="#EB5757"
        isCenteredOnMobile
      />
    </>
  );
}

export default PaymentChannels;
