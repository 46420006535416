import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    padding: "20",
  },
  body: {
    color: "#4F4F4F",
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    paddingBottom: "20",
  },
  termsRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    paddingBottom: "3",
  },
  textBold: {
    fontSize: "12",
  },
  infoText: {
    color: "#828282",
    fontSize: "10",
    marginTop: "2",
  },
  footerRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    bottom: "0px",
    left: "0px",
    right: "0px",
  },
  image: {
    width: "50%",
  },
  companyLogo: {
    maxWidth: "200",
    width: "100%",
    height: "100px",
    objectFit: "contain",
  },
  qrCode: {
    width: "80",
    // marginLeft: "15",
    borderRadius: "5",
  },
  col_1: {
    width: "10%",
  },
  col_2: {
    width: "20%",
  },
  col_3: {
    width: "30%",
  },
  col_3_5: {
    width: "35%",
  },
  col_4: {
    width: "40%",
  },
  col_5: {
    width: "50%",
  },
  col_6: {
    width: "60%",
  },
  col_7: {
    width: "65%",
  },
  col_8: {
    width: "80%",
  },
  col_9: {
    width: "90%",
  },
  col_10: {
    width: "100%",
  },
  col_45: {
    width: "45%",
  },
  col_50: {
    width: "50%",
  },

  table: {
    display: "table",
    width: "100%",
  },
  tableRow: {
    // margin: "auto",
    flexDirection: "row",
  },
  tableBorderTop: {
    borderTop: "1",
    borderColor: "#E0E0E0",
  },
  tableBorderBot: {
    borderTop: "1",
    borderColor: "#E0E0E0",
  },
  tableColHeader: {
    width: "10%",
  },
  tableColHeaderLarge: {
    // width: "15%",
    width: "20%",
  },
  tableColHeaderSlightLarge: {
    width: "30%",
  },
  tableColHeaderCustom: {
    width: "17.5%",
    textAlign: "right",
  },
  tableColHeaderMedium: {
    width: "12%",
  },
  tableColHeaderSlightSmall: {
    width: "5%",
  },
  tableColHeaderSmall: {
    width: "8%",
  },
  tableColHeaderNumber: {
    width: "3%",
  },
  tableCol: {
    width: "10%",
  },
  tableColLarge: {
    // width: "15%",
    width: "20%",
  },
  tableColSlightLarge: {
    width: "30%",
  },
  tableColMedium: {
    width: "12%",
  },
  tableColSlightSmall: {
    width: "7%",
  },
  tableColCustom: {
    width: "17.5%",
    textAlign: "right",
  },
  tableColSmall: {
    width: "8%",
  },
  tableColNumber: {
    width: "3%",
  },
  tableCellHeader: {
    padding: "5",
    fontSize: 8,
    fontWeight: 500,
  },
  tableCellHeaderWithFixWidth: {
    padding: "5",
    fontSize: 8,
    fontWeight: 500,
    width: "7%",
  },
  tableCell: {
    color: "#828282",
    padding: "5",
    fontSize: 9,
  },
  tableFixedWidthCell: {
    color: "#828282",
    padding: "5",
    fontSize: 9,
    width: "7%",
  },

  blockTitle: {
    backgroundColor: "#F2F2F2",
    color: "#47525C",
    padding: "5",
    fontSize: "11",
  },
  blockInfoRow: {
    display: "flex",
    flexDirection: "row",
    color: "#4F4F4F",
    fontSize: "10",
    padding: "5",
  },
  blockInfoLabel: { minWidth: 75 },
  blockInfoText: { display: "flex" },

  blockPaymentRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "10",
    padding: "5",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#E0E0E0",
    borderRadius: "4px",
  },
  blockPaymentTitleText: { color: "#4F4F4F" },
  blockPaymentText: { color: "#828282" },
  border: {
    border: "1",
    borderColor: "#E0E0E0",
    marginTop: "3",
  },
  shortText: {
    paddingTop: 0,
  },
  boldText: {
    fontWeight: 500,
  },
  footerInfo: { fontSize: "8", color: "#828282" },
  footerImage: {
    marginLeft: "25",
    width: "55%",
  },
  termsAndCond: { marginTop: "10" },
  bottomRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    paddingBottom: "7",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 20,
    fontSize: 8,
    display: "flex",
    justifyContent: "space-between",
    color: "grey",
  },
  policyFixed: {
    position: "absolute",
    bottom: 25,
    left: 20,
    width: "100%",
  },
  tableFlex: {
    display: "flex",
    flexDirection: "row",
  },
  policyBorder: {
    borderLeft: "1",
    borderRight: "1",
    borderBottom: "1",
    borderColor: "#E0E0E0",
    padding: "5px",
    paddingTop: "10px",
    position: "relative",
  },
  fixedText: {
    position: "absolute",
    top: -4.7,
    left: 10,
    backgroundColor: "white",
    paddingHorizontal: 5,
  },
  borderTopLeft: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "2.5%",
    height: 1,
    backgroundColor: "#E0E0E0",
  },
  borderTopRight: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "87%",
    height: 1,
    backgroundColor: "#E0E0E0",
  },
});
